import { SET_LANGUAGES, UPDATE_LANGUAGES, DELETE_LANGUAGES , GET_LANGUAGES_DETAILS_SUCCESS} from "../actions/languageAction";


const initialState = {
  
    languages: [],
     
}

export default function languageReducer(state = initialState, action) {
    switch (action.type) {
        // Add cases for different user actions here
        case SET_LANGUAGES:
            return {
                ...state,
                
                languages: action.payload
            };
        case UPDATE_LANGUAGES: {
            return {
                ...state,
                languages: action.payload
            };
        }
        case DELETE_LANGUAGES:  {
            return {
                ...state,
                
                languages: []
            };
        }
        default:
            return state;
    }
}


