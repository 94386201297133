import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import { updateReview } from '../../services/subjects/unlimitedQuestionReview';


const EditReviewForm = ({ open, onClose, review, onSave, loadQuestionReviews }) => {
  const [teacherName, setTeacherName] = useState('');
  const [reviewText, setReviewText] = useState('');
  const [status,setStatus] = useState(false);

  const onApprove = async(id) => {
    setStatus(true)
    await updateReview(id,{questionReviewStatus:'APPROVED'})
    console.log('Approve', id);
    loadQuestionReviews();
  };

  const onReject =async (id) => {
    setStatus(false)
    await updateReview(id,{questionReviewStatus:'REJECTED'})
    console.log('Reject', id);
    loadQuestionReviews();
  };

  useEffect(() => {
    setTeacherName(`${review.appUser.firstName} ${review.appUser.lastName}`.trim())
    setReviewText(sanitizeHtml(review.questionReview));
  }, [review]);

  const sanitizeHtml = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };
  const handleSaveReview = async () => {
    const [firstName, ...rest] = teacherName.split(' ');
    const lastName = rest.join(' ');
  
    loadQuestionReviews()
    onClose();
  };

  const handleApprove = () => {
    onApprove(review.id);
    onClose();
  };

  const handleReject = () => {
    onReject(review.id);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Review</DialogTitle>
      <DialogContent >
        <TextField
          label="Edit Teacher"
          fullWidth
          multiline
          value={teacherName}
        //  onChange={(e) => setTeacherName(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Edit Review"
          multiline
          fullWidth
          value={reviewText}
         // onChange={(e) => setReviewText(sanitizeHtml(e.target.value))}
          margin="normal"
        />
        {(review.questionReviewStatus === 'PENDING' ) || (review.questionReviewStatus === 'EDIT_REQUESTED' )   ? (
          <>
            <Button onClick={handleApprove}   variant="contained">
              Approve
            </Button>
            <Button onClick={handleReject}   variant="contained">
              Reject
            </Button>
          </>
        ) : review.questionReviewStatus === 'REJECTED' ? (
          <Button onClick={handleApprove}   variant="contained">
            Approve
          </Button>
        ) : (
          <Button onClick={handleReject}   variant="contained">
            Reject
          </Button>
        )}
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleSaveReview} color="primary">
          Save
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default EditReviewForm;
