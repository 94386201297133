import React, { useState, useEffect, useRef, useCallback } from 'react'
import Toolbar from './toolBar'
import { AtomicBlockUtils, CharacterMetadata, Editor, EditorState, KeyBindingUtil, Modifier, RichUtils, SelectionState, getDefaultKeyBinding } from 'draft-js'
import { customStyleMap, getBlockRendererFn, getStateToHtmlOptions, stateFromHtmlOptions } from './utils/renderConfig'
import { cloneDeep, debounce, isNil, unionWith } from 'lodash';
import { stateToHTML } from 'draft-js-export-html';
import { Map, OrderedSet } from 'immutable';
import { Keys, MAX_INDENT_DEPTH, MAX_LIST_DEPTH } from './utils/constans';
import { uploadImage } from '../../services/file/image';
import { stateFromHTML } from 'draft-js-import-html';
import { LinearProgress } from '@mui/material';

const { hasCommandModifier } = KeyBindingUtil;

function RichTextEditor(props) {
    const { font = 'Arial'
        // , onUpload
    } = props

    useEffect(() => {
    
      if(props.html && props.html !== html.current){
        reset(props.html);
      }
      }, [props.html]); // eslint-disable-line react-hooks/exhaustive-deps

    const editor = useRef(null);

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [isImageActive, setIsImageActive] = useState(false);
    const [activeStyles, setActiveStyles] = useState({});

    // console.log({isImageActive})

    const onUpload = async(newImgFile, height, width, caption, description, callback) =>{
        try{
          const {setLoading} = props
          setLoading && setLoading(loading =>  ({...loading, imageUploading : true }))
            const response = await uploadImage(newImgFile.current)
            setLoading && setLoading(loading => {return {...loading, imageUploading : false }})
            if(response.status == 200){
                callback(true, response.data, height, width, caption, description)
            }
            
        }catch(e){
            console.log(e)
        }
       
        
    }

    const onChangeEditorState = useCallback(
        (newEditorState, fromHtml) => {
            // console.log("comesh here")
            // console.log("onChangeEditorState " + props.index)
            const selection = newEditorState.getSelection();
            let content = newEditorState.getCurrentContent();
            if (selection.isCollapsed() && RichUtils.getCurrentBlockType(newEditorState) === 'table') {
                const block = content.getBlockForKey(selection.getAnchorKey());
                if (!block.getLength()) {
                    content = Modifier.insertText(content, selection, ' ');
                    newEditorState = EditorState.push(newEditorState, content, 'insert-characters');
                }
            }
            let blockMap = content.getBlockMap();
            const activeBlock = blockMap.find(block => block.getData().get('isActive'));
            if (activeBlock) {
                if (isImageActive) {
                    let data = activeBlock.getData();
                    data = data.delete('isActive');
                    const inactiveBlock = activeBlock.set('data', data);
                    blockMap = blockMap.set(activeBlock.getKey(), inactiveBlock);
                    const newContent = content.set('blockMap', blockMap);
                    newEditorState = EditorState.push(newEditorState, newContent, 'change-block-data');
                    newEditorState = EditorState.forceSelection(newEditorState, selection);
                }
                setIsImageActive(isImageActive => !isImageActive);
            }

           
            //update html content
            if(!fromHtml){
              updateFormData.current(newEditorState, fromHtml);
            }

            const styleList = newEditorState.getCurrentInlineStyle().toList();
            const activeStyles = {};
            styleList.forEach(s => {
                const [type, value] = s.split('.');
                activeStyles[type] = value;
            });

            setActiveStyles(activeStyles);

            setEditorState(newEditorState)
            // console.log("onChangeEditorState last " + props.index)
        },
        [isImageActive, props.html]
    );

    const exportStateToHTML = (newEditorState, fromHtml) => {
      // alert("html " + props.index + " " + fromHtml + " " + newEditorState)
      // console.log({fromHtml})
        if(fromHtml) return;

        const htmlContent = convertEditorStateToHtml(newEditorState);
        // console.log(htmlContent)
        html.current = htmlContent;
        props.onChange(htmlContent, props.index);
      };

    const updateFormData = useRef(debounce(exportStateToHTML, 500, { leading: true }));

   

    const html = useRef(null);
    const plaintext = useRef(null);

    const convertEditorStateToHtml = (state = editorState) => {
        const currentContent = state.getCurrentContent();
        // const options = getStateToHtmlOptions(currentContent);
        //todo
        const options = getStateToHtmlOptions(currentContent);
        const htmlContent = currentContent.hasText() ? stateToHTML(currentContent, options) : null;
        return htmlContent;
    };

    useEffect(() => {
        toggleInlineStyle(font)
    }, [font])


    const moveSelectionToStart = currentEditorState => {
        let selection = currentEditorState.getSelection();
        const content = currentEditorState.getCurrentContent();
        const firstBlock = content.getFirstBlock();
        const key = firstBlock.getKey();
        selection = SelectionState.createEmpty(key);
        return EditorState.forceSelection(currentEditorState, selection);
    };

    const setSelectionIfNone = currentEditorState => {
        const selection = currentEditorState.getSelection();
        if (!selection.getHasFocus()) {
            // console.log(selection)
            return moveSelectionToStart(currentEditorState);
        }
        return currentEditorState;
    };

    const toggleInlineStyle = inlineStyle => {
        let newEditorState = setSelectionIfNone(editorState);
        // console.log({ inlineStyle })
        // console.log("current style" + newEditorState.getCurrentInlineStyle())
        const existingMatch = newEditorState
            .getCurrentInlineStyle() // getCurrentInlineStyle() returns an Immutable OrderedSet
            .filter(style => style.includes('.') && style.split('.')[0] === inlineStyle.split('.')[0]) // compound styles are dot-delimited e.g. fontFamily.Arial
            .toList()
            .get(0);
        if (existingMatch) {
            console.log("existing match")
            newEditorState = RichUtils.toggleInlineStyle(newEditorState, existingMatch);
        }

        onChangeEditorState(RichUtils.toggleInlineStyle(newEditorState, inlineStyle));
    }

    const toggleListType = listType => {
        if (RichUtils.getCurrentBlockType(editorState) === 'table') {
            return null;
        }
        const blockType = listType === 'BULLETLIST' ? 'unordered-list-item' : 'ordered-list-item';
        onChangeEditorState(RichUtils.toggleBlockType(editorState, blockType));
    };

    const newImgFile = useRef(null);
    const insertImage = (img) => {
        // console.log(img)
        const image = cloneDeep(img)
        const { imgUrl, imgFile, height, width, caption, description } = image
        console.log(imgFile)

        if (RichUtils.getCurrentBlockType(editorState) === 'table') {
            return null;
        }

        if (!imgUrl && imgFile) {
            newImgFile.current = imgFile;
            if (onUpload) {
                onUpload(newImgFile, height, width, caption, description, insertImageHandleResponse);
            } else {
                insertImageHandleResponse(false, null, height, width, caption, description)
            }
            return;
        }

        newImgFile.current = null;
        let contentState = editorState.getCurrentContent();
        contentState = contentState.createEntity('IMAGE', 'IMMUTABLE', { src: imgUrl, height, width, caption, description });
        const entityKey = contentState.getLastCreatedEntityKey();
        const newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
        onChangeEditorState(newEditorState);
    }

    const insertImageHandleResponse = (valid, resp, height, width, caption, description) => {
        if (valid) {
            insertImage({ imgUrl: resp,  height, width, caption, description });
        } else {
            const fileReader = new window.FileReader();
            fileReader.readAsDataURL(newImgFile.current);
            fileReader.onloadend = e => {
                insertImage({ imgUrl: e.target.result, height, width, caption, description });
            };
        }
    };

    const getEditorState = () => {
        return editorState;
    };

    const handleKeypressWhenSelectionNotCollapsed = (newEditorState = editorState, chars = '') => {
        let selection = newEditorState.getSelection();
        let content = newEditorState.getCurrentContent();
        const startKey = selection.getStartKey();
        const startBlock = content.getBlockForKey(startKey);
        const endKey = selection.getEndKey();
        const endBlock = content.getBlockForKey(endKey);
        const prevBlock = content.getBlockBefore(startKey);
        const nextBlock = content.getBlockAfter(endKey);

        return 'not-handled';
        //todo
    }

    const handleDeleteKey = () => {
        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const startBlock = contentState.getBlockForKey(selection.getStartKey());
        const endBlock = contentState.getBlockForKey(selection.getEndKey());
        const nextBlock = contentState.getBlockAfter(endBlock.getKey());
        const endOffset = selection.getEndOffset();
        // prevent deleting entire table cell
        if (selection.isCollapsed()) {
          const length = startBlock.getLength();
          if (endOffset === length && nextBlock.getType() === 'table') {
            return 'handled';
          } else {
            return 'not-handled';
          }
        } else {
          return handleKeypressWhenSelectionNotCollapsed();
        }
      };

    const toggleBlockData = newData => {
        const contentState = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();
        const block = contentState.getBlockForKey(selectionState.getAnchorKey());
        let data = block.getData();
        Object.keys(newData).forEach(key => {
            if (data.get(key) === newData[key]) {
                data = data.remove(key);
            } else {
                data = data.merge(newData);
            }
        });
        const newContentState = Modifier.setBlockData(contentState, selectionState, data);
        onChangeEditorState(EditorState.push(editorState, newContentState, 'change-block-data'));
    };

    const setIndent = (
        direction,
        newEditorState = editorState,
        listMax = MAX_LIST_DEPTH,
        indentMax = MAX_INDENT_DEPTH,
        setDepth
      ) => {
        const selectionState = newEditorState.getSelection();
        const contentState = newEditorState.getCurrentContent();
        const adjustment = direction === 'INDENT' ? 1 : -1;
        const startKey = selectionState.getStartKey();
        const endKey = selectionState.getEndKey();
        let blockMap = contentState.getBlockMap();
        const blocks = blockMap
          .toSeq()
          .skipUntil((_, k) => k === startKey)
          .takeUntil((_, k) => k === endKey)
          .concat([[endKey, blockMap.get(endKey)]])
          .map(block => {
            const depth = block.getDepth();
            const maxDepth = block.getType().includes('list-item') ? listMax : indentMax;
            const newDepth = setDepth !== undefined ? setDepth : Math.max(0, Math.min(depth + adjustment, maxDepth));
            return block.set('depth', newDepth);
          });
    
        blockMap = blockMap.merge(blocks);
        const newContentState = contentState.merge({
          blockMap,
          selectionBefore: selectionState,
          selectionAfter: selectionState,
        });
        newEditorState = EditorState.push(newEditorState, newContentState, 'adjust-depth');
        onChangeEditorState(newEditorState);
      };

    const handleTabInTable = (direction = 'next', collapsed = false) => {
        let newEditorState = editorState;
        let selection = editorState.getSelection();
        let contentState = editorState.getCurrentContent();
        let targetKey = selection.getAnchorKey();
        let targetBlock = contentState.getBlockForKey(targetKey);
        do {
            if (direction === 'next') {
                targetBlock = contentState.getBlockAfter(targetKey);
            } else {
                targetBlock = contentState.getBlockBefore(targetKey);
            }
            targetKey = targetBlock && targetBlock.getKey();
        } while (targetKey && ['atomic', 'horizontal-rule'].includes(targetBlock.getType()));
        if (!targetBlock && direction === 'next') {
            selection = selection.merge({
                anchorOffset: contentState.getBlockForKey(selection.getAnchorKey()).getLength(),
                focusOffset: contentState.getBlockForKey(selection.getAnchorKey()).getLength(),
            });
            contentState = Modifier.splitBlock(contentState, selection);
            targetBlock = contentState.getLastBlock();
            selection = SelectionState.createEmpty(targetBlock.getKey());
            contentState = Modifier.setBlockType(contentState, selection, 'unstyled');
            targetBlock = contentState.getLastBlock();
            newEditorState = EditorState.push(editorState, contentState, 'split-block');
        } else if (!targetBlock) {
            targetBlock = contentState.getBlockForKey(selection.getAnchorKey());
        }
        const isTargetTable = targetBlock.getType() === 'table' && !collapsed;
        const endOffset = targetBlock.getLength();
        selection = SelectionState.createEmpty(targetBlock.getKey());
        selection = selection.merge({
            anchorOffset: isTargetTable || direction === 'next' ? 0 : endOffset,
            focusOffset: isTargetTable || direction === 'previous' ? endOffset : 0,
        });
        onChangeEditorState(EditorState.forceSelection(newEditorState, selection));
    };

    const mapKeyToEditorCommand = e => {
        if (
            !editorState.getCurrentContent().hasText() &&
            ['unstyled', 'paragraph'].includes(editorState.getCurrentContent().getFirstBlock().getType())
        ) {
            (async () => {
                const currentStyle = editorState.getCurrentInlineStyle().toArray();
                // merge user selected styles with defaults, overriding defaults where they conflict
                const styles = unionWith(currentStyle, props.defaultStyles, (v1, v2) => v1.split('.')[0] === v2.split('.')[0]);
                await onChangeEditorState(EditorState.setInlineStyleOverride(editorState, OrderedSet(styles)));
            })();
        }
        if (e.keyCode === Keys.B && e.shiftKey && hasCommandModifier(e)) {
            return 'bullet_list';
        } else if (e.keyCode === Keys.B && hasCommandModifier(e)) {
            return 'BOLD';
        } else if (e.keyCode === Keys.L && e.shiftKey && hasCommandModifier(e)) {
            return 'ordered_list';
        } else if (e.keyCode === Keys.L && hasCommandModifier(e)) {
            return 'float_left';
        } else if (e.keyCode === Keys.R && hasCommandModifier(e)) {
            return 'float_right';
        } else if (e.keyCode === Keys.I && hasCommandModifier(e)) {
            return 'ITALIC';
        } else if (e.keyCode === Keys[']'] && hasCommandModifier(e)) {
            return 'INDENT';
        } else if (e.keyCode === Keys.U && hasCommandModifier(e)) {
            return 'UNDERLINE';
        } else if (e.keyCode === Keys['['] && hasCommandModifier(e)) {
            return 'OUTDENT';
        } else if (e.keyCode === Keys.Backspace && !hasCommandModifier(e) && !e.altKey) {
            return 'backspace';
            // Tab & shift+Tab handled here instead of handleKeyCommand because RichUtils.onTab requires event reference
        } else if (e.keyCode === Keys.Delete) {
            return 'delete';
        } else if (e.keyCode === Keys.Tab && e.shiftKey) {
            const currentBlockType = RichUtils.getCurrentBlockType(editorState);
            if (currentBlockType.includes('list-item')) {
                onChangeEditorState(RichUtils.onTab(e, editorState, MAX_LIST_DEPTH));
            } else if (currentBlockType === 'table') {
                handleTabInTable('previous');
            }
            return 'shiftTab';
        } else if (e.keyCode === Keys.Tab) {
            const currentBlockType = RichUtils.getCurrentBlockType(editorState);
            if (RichUtils.getCurrentBlockType(editorState).includes('list-item')) {
                onChangeEditorState(RichUtils.onTab(e, editorState, MAX_LIST_DEPTH));
            } else if (currentBlockType === 'table') {
                handleTabInTable('next');
            } else {
                const newContentState = Modifier.replaceText(
                    editorState.getCurrentContent(),
                    editorState.getSelection(),
                    '     '
                );
                onChangeEditorState(EditorState.push(editorState, newContentState, 'insert-characters'));
            }
            return 'tab';
        }
        return getDefaultKeyBinding(e);
    };

    const handleKeyCommand = (command, newEditorState) => {
        console.log({command})
        switch (command) {
            case 'backspace':
                // removing images ("atomic" blocktype) with backspace requires special handling or the image tag and dataUrl can be left in the content but not visible.
                let contentState = newEditorState.getCurrentContent();
                let selectionState = newEditorState.getSelection();
                const startKey = selectionState.getStartKey();
                const offset = selectionState.getStartOffset();
                const collapsed = selectionState.isCollapsed();
                const blockBefore = contentState.getBlockBefore(startKey);
                const currentBlockType = RichUtils.getCurrentBlockType(newEditorState);
                if (collapsed && offset === 0 && blockBefore && blockBefore.getType() === 'atomic') {
                    newEditorState = removeSizeDataFromBlock(newEditorState, blockBefore);
                    newEditorState = EditorState.acceptSelection(newEditorState, selectionState);
                    onChangeEditorState(RichUtils.onBackspace(newEditorState));
                    return 'handled';
                } else if (currentBlockType === 'atomic') {
                    const currentBlock = contentState.getBlockForKey(startKey);
                    newEditorState = removeSizeDataFromBlock(newEditorState, currentBlock);
                    newEditorState = EditorState.acceptSelection(newEditorState, selectionState);
                    newEditorState = RichUtils.onBackspace(newEditorState);
                    contentState = newEditorState.getCurrentContent();
                    selectionState = newEditorState.getSelection();
                    const key = selectionState.getAnchorKey();
                    let selection = SelectionState.createEmpty(key);
                    selection = selection.set('focusOffset', 1);
                    contentState = Modifier.removeRange(contentState, selection, 'backward');
                    onChangeEditorState(EditorState.push(newEditorState, contentState, 'backspace-character'));
                    return 'handled';
                } else if (currentBlockType === 'table' && collapsed && offset === 0) {
                    return 'handled';
                } else if (currentBlockType !== 'table' && collapsed && blockBefore?.getType() === 'table' && offset === 0) {
                    // handleTabInTable('previous', true);
                    return 'handled';
                } else if (
                    collapsed &&
                    offset === 0 &&
                    ['pasted-list-item', 'ordered-list-item', 'unordered-list-item'].includes(currentBlockType)
                  ) {
                    contentState = Modifier.setBlockType(contentState, selectionState, 'unstyled');
                    onChangeEditorState(EditorState.push(newEditorState, contentState, 'change-block-type'));
                    return 'handled';
                  }else if (!collapsed) {
                    return handleKeypressWhenSelectionNotCollapsed(newEditorState);
                  } else {
                    return 'not-handled';
                  }

            case 'BOLD':
                toggleInlineStyle('BOLD');
                return 'handled';
            case 'bullet_list':
                toggleListType('BULLETLIST');
                return 'handled';
            case 'delete':
                return handleDeleteKey();
            case 'float_left':
                toggleBlockData({ float: 'left' });
            case 'float_right':
                toggleBlockData({ float: 'right' });
                return 'handled';
            case 'INDENT':
                setIndent('INDENT');
                return 'handled';
            case 'ITALIC':
                toggleInlineStyle('ITALIC');
                return 'handled';
            case 'ordered_list':
                toggleListType('ORDEREDLIST');
                return 'handled';
            case 'OUTDENT':
                setIndent('OUTDENT');
                return 'handled';
            case 'UNDERLINE':
                toggleInlineStyle('UNDERLINE');
                return 'handled';
            case 'shiftTab':
                return 'handled';
            case 'tab':
                return 'handled';
            default:
                return 'not-handled';
        }

    }

    const removeSizeDataFromBlock = (newEditorState, block) => {
        const data = block.getData().delete('height').delete('width').delete('imgStyle'); //todo remove caption and description
        block = block.set('data', data);
        let contentState = newEditorState.getCurrentContent();
        let blockMap = contentState.getBlockMap();
        blockMap = blockMap.set(block.getKey(), block);
        contentState = contentState.set('blockMap', blockMap);
        newEditorState = EditorState.push(newEditorState, contentState, 'change-block-data');
        return newEditorState;
      };

    const reset = (newHtml, fromHtml = true) => {
        // console.log("(newHtml === html.current")
        // console.log((newHtml === html.current))
        // if (newHtml === html.current) return;
        if(newHtml === null || newHtml == {}) return;
        // console.log("teerere")
        // console.log(newHtml)
        // console.log("reset " + props.index + " " + newHtml)


        let newEditorState = convertHtmlToEditorState(newHtml);
        if (newHtml) {
          // console.log("reset " + props.index )
            newEditorState = EditorState.moveFocusToEnd(newEditorState);
        } else if (newHtml === null) {
            newEditorState = moveSelectionToStart(newEditorState);
        }
        // console.log("reset before setting " + props.index)
        onChangeEditorState(newEditorState, fromHtml);
        // setEditorState(newEditorState);
        // Resetting forces the editor to take focus, so here we check if the editorState
        // had focus before the reset() and if not then remove focus again.
        // Also keep the contenteditable state in sync with props.disabled because
        // reset() leaves all contenteditable === true.

        //There is issue with following lines, want to check if it is necessary

        // const hasFocus = editorState.getSelection().getHasFocus();
        // const currentEditor = editor.current;
        // setTimeout(() => {
        //     syncContenteditable(props.disabled);
        //     currentEditor?.blur();
        //     setTimeout(() => {
        //         if (hasFocus) {
        //             currentEditor?.focus();
        //         }
        //     });
        // });
    };

    const convertHtmlToEditorState = newHtml => {
        // html conversion normally ignores <hr> tags, but using this character substitution it can be configured to preserve them.
        const inputHtml = (newHtml ?? props.value ?? props.fl?.getValue(props.name) ?? '').replace(
          /<hr\/?>/g,
          '<div>---hr---</div>'
        );
    
        /**
         * Special parsing for legacy Instascreen data and content pasted in from other sources (google docs, summernote, etc.)
         *
         * This code:
         * 1. removes <style> tags which are sometime present from pasted word content.
         * 2. Some content created in summernote or otherwise has text that's not wrapped in any html
         * tag mixed with other content that is in tags. In the draft-js richEditor all the unwrapped
         * text gets lumped together in one div at the start of the document. This code goes through and wraps
         * those text nodes in <div> tags separately and in order with the rest of the content.
         * 3. Finds tags that contain style white-space: pre-wrap and substitutes non-breaking space characters
         * for spaces and <br> tags for newline characters so they get preserved when converted to draft.js state.
         * 4. Finds block-level tags (div, p) inside <li> list items and <td> table cells and converts them to inline <span> elements,
         * otherwise the div & p tags would take precedence and the list or table structure gets lost in the conversion.
         */
        const blockTags = [
          'div',
          'p',
          'h1',
          'h2',
          'h3',
          'h4',
          'h5',
          'h6',
          'table',
          'ol',
          'ul',
          'hr',
          'pre',
          'section',
          'header',
          'nav',
          'main',
          'blockquote',
        ];
        const domParser = new DOMParser();
        const tempDoc = domParser.parseFromString(inputHtml, 'text/html');
        const parsedHTML = tempDoc.querySelector('body');
        let child = parsedHTML.firstChild;
        if (parsedHTML.children.length === 1 && child.tagName === 'TABLE') {
          child = document.createElement('br');
          parsedHTML.insertBefore(child, parsedHTML.firstChild);
          parsedHTML.appendChild(document.createElement('br'));
        }
        while (child) {
          // remove Style tags
          if (child.tagName === 'STYLE') {
            const nextChild = child.nextSibling;
            parsedHTML.removeChild(child);
            child = nextChild;
            continue;
          }
          // handle text content that is not within block elements
          if (!blockTags.includes(child.tagName?.toLowerCase())) {
            const wrapper = tempDoc.createElement('div');
            let nextChild = child.nextSibling;
            wrapper.appendChild(child);
            while (nextChild && !blockTags.includes(nextChild.tagName?.toLowerCase())) {
              const currentChild = nextChild;
              nextChild = currentChild.nextSibling;
              wrapper.appendChild(currentChild);
            }
            parsedHTML.insertBefore(wrapper, nextChild);
            child = nextChild;
          }
    
          child = child?.nextSibling;
        }
    
        // recursive function to walk the full DOM tree, making modifications as needed
        // to preserve formatting during conversion to internal state for draft.js
        const traverse = (node, isNestedBlock) => {
          if (!node) return;
          // elements formatted with spacing and soft line-breaks
          if (/white-space:\s*(pre|pre-wrap);?/.test(node.getAttribute('style'))) {
            node.innerHTML = node.innerHTML
              .replace(/\n/g, '<br>')
              .replace(/\s{2}/g, '&nbsp;&nbsp;')
              .replace(/&nbsp;\s/g, '&nbsp;&nbsp;');
            let style = node.getAttribute('style');
            style = style.replace(/white-space:\s*(pre|pre-wrap);?/, '');
            node.setAttribute('style', style);
          }
          // replace block elements inside lists with inline <span> elements
          if (isNestedBlock && ['DIV', 'P', 'INPUT'].includes(node.tagName)) {
            const newNode = changeTag(node, 'span');
            node.replaceWith(newNode);
            node = newNode;
          }
          // If a nested table has a single row and cell, switch it to a span as a single cell's contents of the outer table
          if (isNestedBlock && node.tagName === 'TABLE') {
            if (node.firstElementChild.tagName === 'TBODY') {
              const numRows = node.firstElementChild.children.length;
              if (numRows === 1) {
                const numCells = node.firstElementChild.firstElementChild.children.length;
                if (numCells === 1) {
                  let cell = node.firstElementChild.firstElementChild.firstElementChild;
                  if (['DVI', 'P'].includes(cell.firstElementChild.tagName)) {
                    cell = cell.firstElementChild;
                  }
                  const newNode = changeTag(cell, 'span');
                  node.replaceWith(newNode);
                }
              }
            }
          }
          traverse(node.nextElementSibling, isNestedBlock);
          isNestedBlock = isNestedBlock || node.tagName === 'LI' || node.tagName === 'TD' || node.tagName === 'TH';
          traverse(node.firstElementChild, isNestedBlock);
        };
    
        traverse(parsedHTML.firstElementChild);
    
        // function used within traverse() for converting block elements to inline span elements
        function changeTag(element, tag) {
          // prepare the elements
          const newElem = document.createElement(tag);
          const clone = element.cloneNode(true);
          // move the children from the clone to the new element
          while (clone.firstChild) {
            newElem.appendChild(clone.firstChild);
          }
          // copy the attributes
          for (const attr of clone.attributes) {
            if (attr.name === 'value') {
              newElem.textContent = attr.value;
            } else {
              newElem.setAttribute(attr.name, attr.value);
            }
          }
          return newElem;
        }
    
        const s = new XMLSerializer();
        const newContent = s.serializeToString(parsedHTML);
        // end special parsing
    
        let newEditorState = EditorState.createWithContent(stateFromHTML(newContent, stateFromHtmlOptions));
        newEditorState = EditorState.moveSelectionToEnd(newEditorState);
        return supplementalCustomBlockStyleFn(newEditorState);
      };

      const supplementalCustomBlockStyleFn = newEditorState => {
        const contentState = newEditorState.getCurrentContent();
        const selectionState = newEditorState.getSelection();
        let blockMap = contentState.getBlockMap();
        const blocks = blockMap.map(block => {
          let depth = block.getData().get('depth');
          const margin = block.getData().get('margin-left');
          if ((margin && /em$/.test(margin)) || depth) {
            depth = depth !== undefined ? depth : parseInt(margin.replace(/rem|em/, '') / 2.5, 10);
            block = block.set('depth', depth);
          }
    
          block.findEntityRanges(
            v => {
              const key = v.getEntity();
              return key !== null && contentState.getEntity(key).getType() === 'LINK';
            },
            (start, end) => {
              const characterList = block.getCharacterList().map((v, k) => {
                if (start <= k && k < end) {
                  v = CharacterMetadata.applyStyle(v, 'UNDERLINE');
                  v = CharacterMetadata.applyStyle(v, 'color.#0088FF');
                  return v;
                }
                return v;
              });
              block = block.set('characterList', characterList);
            }
          );
          return block;
        });
        blockMap = blockMap.merge(blocks);
        const newContentState = contentState.merge({
          blockMap,
          selectionBefore: selectionState,
          selectionAfter: selectionState,
        });
        newEditorState = EditorState.push(newEditorState, newContentState, 'adjust-depth');
        return newEditorState;
      };

      const syncContenteditable = disabled => {
        if (disabled) {
          const editableDivs = editor.current?.editor.querySelectorAll('[contenteditable="true"]') ?? [];
          editableDivs.forEach(div => {
            div.setAttribute('contenteditable', 'false');
            div.setAttribute('data-editable-disabled', 'true');
          });
        } else if (!disabled) {
          const editableDivs = editor.current?.editor.querySelectorAll('[data-editable-disabled="true"]') ?? [];
          editableDivs.forEach(div => {
            div.setAttribute('contenteditable', 'true');
            div.removeAttribute('data-editable-disabled');
          });
        }
      };

    const selection = editorState.getSelection();
    const block = editorState.getCurrentContent().getBlockForKey(selection.getStartKey());
    const blockRendererFn = getBlockRendererFn(editor.current, getEditorState, onChangeEditorState);

    

    return (
        <div style={{height : 350, borderColor : 'black', borderWidth : 10, backgroundColor : 'grey', padding : 8, margin : 8}}>
            {props.loading && props.loading.imageUploading && <LinearProgress color="secondary" />}
            <Toolbar
                inlineToggle={toggleInlineStyle}
                currentStyle={editorState.getSelection().getHasFocus() && editorState.getCurrentInlineStyle()}
                listToggle={toggleListType}
                blockType={block.getType()}
                insertImage={insertImage}
            />
            <div style={{maxHeight : 300, height : 280 , 
                backgroundColor : 'white', border : 'solid', borderWidth : 1, borderColor : 'black',
                overflowY : 'auto',
                resize : 'vertical'
                }}>
                <Editor
                    ref={editor}
                    editorState={editorState}
                    handleKeyCommand={(command, editorState) => handleKeyCommand(command, editorState)}
                    keyBindingFn={e => mapKeyToEditorCommand(e)}
                    onChange={editorState => onChangeEditorState(editorState)}
                    customStyleMap={customStyleMap}
                    blockRendererFn={blockRendererFn}
                />
            </div>
        </div>
    )
}

export default RichTextEditor