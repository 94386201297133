import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { getLanguages } from '../../services/subjects/languages';
import { createSubject } from '../../services/subjects/subjects';
import { getALLGrades } from '../../services/subjects/grades';
import { FormControl, InputLabel, MenuItem, Select, CircularProgress, FormControlLabel, Checkbox } from '@mui/material';

function AddSubjectForm({ setItems, open, onClose, syllabusId, selectedLanguages ,loadSubjects}) {
  const [subjectName, setSubjectName] = useState('');
  const [addNameTags, setNameTags] = useState({});
  const [languages, setLanguages] = useState([]);
  const [description, setDescription] = useState('');
  const [grades, setGrades] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState('');
  const [order, setOrder] = useState('');
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [isActiveUnlimitedQuestions, setIsActiveUnlimitedQuestions] = useState(false);
  const [isActivePastPapers, setIsActivePastPapers,] = useState(false);
  const [isActiveQuiz, setIsActiveQuiz,] = useState(false);
  
  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const languagesData = await getLanguages();
        setLanguages(languagesData.data);
      } catch (error) {
        console.error('Error fetching languages:', error);
      }
    };

    const fetchGrades = async () => {
      try {
        const gradesData = await getALLGrades(syllabusId);
        console.log("gradesData", gradesData)
        setGrades(gradesData.data);
      } catch (error) {
        console.error('Error fetching grades:', error);
      }
    };

    fetchLanguages();
    fetchGrades();
  }, []);

  const handleNameTagsChange = (languageCode, value) => {
    setNameTags((prevNames) => ({
      ...prevNames,
      [languageCode]: value,
    }));
  };

  const handleGradeChange = (event) => {
    setSelectedGrade(event.target.value);
  };

  const renderGradeName = (grade) => {
    const gradeNameTags = grade.nameTags.map(tag => tag.name).join(' - ');
    return gradeNameTags || '';
  };

  const handleAddSubject = async () => {
    setLoading(true); // Set loading state to true when saving data
    try {
      const selectedGradeObject = grades.find((grade) => grade.id === selectedGrade);
      const nameTags = selectedLanguages.map(language => {
        return { language: language, name: addNameTags[language.code] }
      });
      console.log(nameTags)
      const newSubject = {
        name: subjectName,
        description: description,
        subjectGrade: selectedGradeObject,
        nameTags: nameTags,
        sortingOrder:order,
        isActiveUnlimitedQuestions,
        isActivePastPapers,
        isActiveQuiz
         
      };

      const response = await createSubject(syllabusId, newSubject);
      

      setSubjectName('');
      setNameTags({});
      setDescription('');
      setSelectedGrade('');
      setOrder('')
      
      onClose();
      loadSubjects();
    } catch (error) {
      console.error('Error adding subject:', error);
    } finally {
      setLoading(false); // Set loading state to false after saving data
    }
  };


  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New Subject</DialogTitle>
      <DialogContent>
        <TextField
          label="Subject Name"
          fullWidth
          value={subjectName}
          onChange={(e) => setSubjectName(e.target.value)}
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="grade-label">Grade</InputLabel>
          <Select
            labelId="grade-label"
            value={selectedGrade}
            onChange={handleGradeChange}
            renderValue={(selected) => renderGradeName(grades.find(grade => grade.id === selected))}
          >
            {grades.map((grade) => (
              <MenuItem key={grade.id} value={grade.id}>
                {renderGradeName(grade)}
              </MenuItem>
            ))}
          </Select>

        </FormControl>
        <TextField
          label="Subject Description"
          multiline
          rows={4}
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        {selectedLanguages.map((language) => (
          <TextField
            key={language.code}
            label={`${language.name} Name`}
            value={addNameTags[language.code] || ''}
            onChange={(e) => handleNameTagsChange(language.code, e.target.value)}
            fullWidth
            margin="normal"
          />
        ))}

        <TextField
          label="Order"
          fullWidth
          value={order}
          onChange={(e) => setOrder(e.target.value)}
          margin="normal"
        />

        <FormControlLabel
          control={<Checkbox checked={isActiveUnlimitedQuestions} onChange={(e) => setIsActiveUnlimitedQuestions(e.target.checked)} />}
          label="Active Unlimited Questions"
        />
        <FormControlLabel
          control={<Checkbox checked={isActivePastPapers} onChange={(e) => setIsActivePastPapers(e.target.checked)} />}
          label="Active Past Papers"
        />
        <FormControlLabel
          control={<Checkbox checked={isActiveQuiz} onChange={(e) => setIsActiveQuiz(e.target.checked)} />}
          label="Active Daily Quize"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleAddSubject} color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Add Subject'} {/* Display loading indicator or "Add Subject" text */}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddSubjectForm;
