import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, FormControlLabel, Checkbox } from '@mui/material';
import { createMCQPastPaper, updateMCQPastPaper } from '../../services/subjects/pastPapers';

function AddPastPaper({ open, onClose, subjectId, loadPastpapers, editPaper }) {
    const [paperName, setPaperName] = useState('');
    const [description, setDescription] = useState('');
    const [year, setYear] = useState('');
    const [order, setOrder] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [isFree, setIsFree] = useState(false);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        console.log(editPaper)
        if (editPaper) {
            setEditMode(true);
            setPaperName(editPaper.questionPaper.paperName || '');
            setDescription(editPaper.questionPaper.paperDescription || '');
            setYear(editPaper.year || '');
            setOrder(editPaper.questionPaper.sortingOrder || '');
            setIsActive(editPaper.questionPaper.isActive || false);
            setIsFree(editPaper.questionPaper.isFreeToAccess || false);
        } else {
             
            setEditMode(false);
            setPaperName('');
            setDescription('');
            setYear('');
            setOrder('');
            setIsActive(false);
            setIsFree(false);
        }
    }, [editPaper]);

    const handleAddPaper = async () => {
        const pastPaper = {
            year: year,
            questionPaper: {
                isActive: isActive,
                isFreeToAccess: isFree,
                paperName: paperName,
                paperDescription: description,
                sortingOrder: order,
                subject: {
                    id: subjectId
                }
            }
        };

        if (editMode) {
            await updateMCQPastPaper(editPaper.id, pastPaper);  
        } else {
            await createMCQPastPaper(pastPaper);
        }

        
        setPaperName('');
        setDescription('');
        setYear('');
        setOrder('');
        setIsActive(false);
        setIsFree(false);

         
        loadPastpapers();
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ fontSize: '1.5rem' }}>
                {editMode ? 'Edit Past Paper' : 'Add Past Paper'}
            </DialogTitle>
            <DialogContent>
                <TextField
                    margin="normal"
                    label="Paper Name"
                    fullWidth
                    value={paperName}
                    onChange={(e) => setPaperName(e.target.value)}
                />
                <TextField
                    margin="normal"
                    label="Description"
                    fullWidth
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
                <TextField
                    margin="normal"
                    label="Year"
                    fullWidth
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                />
                <TextField
                    margin="normal"
                    label="Order"
                    fullWidth
                    value={order}
                    onChange={(e) => setOrder(e.target.value)}
                />
                <FormControlLabel
                    control={<Checkbox checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />}
                    label="Active"
                />
                <FormControlLabel
                    control={<Checkbox checked={isFree} onChange={(e) => setIsFree(e.target.checked)} />}
                    label="Free to Access"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleAddPaper} variant="contained" color="primary">
                    {editMode ? 'Save Changes' : 'Add'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddPastPaper;
