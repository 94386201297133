import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { createPaper, getQuestionPaperFiles, updatePaperInServer } from '../../services/questionPapers/questionPaperFilesService';
import { Button, Grid } from '@mui/material';
import PdfQuestionPaper from './PdfQuestionPaper';
import PaperCard from './PaperCard';
import PDFViewDialog from './PDFViewDialog';
import { set } from 'lodash';

function PdfQuestionPapers() {
    const { subjectId } = useParams();
    const [papers, setPapers] = useState([])
    const [openAddEditModal, setOpenAddEditModal] = useState(false)
    const [selectedPaper, setSelectedPaper] = useState(null)
    const [currentFile, setCurrentFile] = useState(null)

    useEffect(() => {
        loadPapers()
    }, [])
    
    const loadPapers = async() => {
        try{
            const {data, status} = await getQuestionPaperFiles(subjectId)
            if(status === 200){
                setPapers(data)
            }
        }catch(error){
            console.log({error})
        }
    }

    const onAddPaper = () => {
        // console.log('Add Paper')
        setSelectedPaper({
            name: "",
            description: "",
            sortingOrder: 0,
            isActive: true,
        })
        setOpenAddEditModal(true)
    }

    const handleCloseModal = () => {
        setOpenAddEditModal(false)
    }

    const handleChange = (e, key) => {
        if(key === 'file'){
            const file = e.target.files[0];
            if(file){
                setSelectedPaper(paper => ({...paper, [key]: file, url: null}))
            }
        }else if (key === 'isActive'){
            setSelectedPaper(paper => ({...paper, [key]: e.target.checked}))
        }else{
            setSelectedPaper(paper => ({...paper, [key]: e.target.value}))
        }
       
    }

    const savePaper = async() => {
        try{
            const {data, status} = await createPaper(subjectId, selectedPaper)
            if(status === 200){
                setPapers([...papers, data])
                setOpenAddEditModal(false)
            }
        }catch(error){
            console.log({error})
        }
    }

    const updatePaper = async() => {
        try{
           const {data, status} = await updatePaperInServer(selectedPaper) 
           if(status === 200){
                const index = papers.findIndex(p => p.id === data.id)
                if(index > -1){
                     papers[index] = data
                     setPapers([...papers])
                }
                setOpenAddEditModal(false)
           }
        }catch(error){
        }
    }

    const onSave = () =>{
        const {id} = selectedPaper ?? {}
        if(id){
            // update
            updatePaper()
        }else{
            savePaper()
        }
    }

    const onViewPdf = (paper) => {
        // console.log({paper})
        setCurrentFile(paper)
    }


    const onDeletePaper = (paper) => {
    }

    const onEditPaper = (paper) => {
        setSelectedPaper(paper)
        setOpenAddEditModal(true)
    }

    const onClose = () => {
        setSelectedPaper(null)
    }
    
    
  return (
    <>
    <div>
        <Button onClick={onAddPaper} style={{ backgroundColor: '#87BCDE', color: 'white', margin: '20px' }}>
                Add Paper
            </Button>
            <Grid container rowGap={2} columnGap={2}>
            {
                papers.map(paper => (
                    <PaperCard key={paper.id} paper={paper} onViewPdf={onViewPdf} onEditPaper = {onEditPaper} onDeletePaper = {onDeletePaper}/>))
            }
            </Grid>
    </div>
    <PdfQuestionPaper open={openAddEditModal} handleClose={handleCloseModal} paper = {selectedPaper} onChange = {handleChange} onSave = {onSave} onClose = {onClose}/>
    {currentFile && <PDFViewDialog open={currentFile} handleClose = {() =>setCurrentFile(null)} url={currentFile}/>}
    </>
  )
}

export default PdfQuestionPapers