export const MAX_INDENT_DEPTH = 20;
export const MAX_LIST_DEPTH = 4;

export const FONTS = [
    'Arial',
    'Arial Black',
    'Comic Sans MS',
    'Courier New',
    'Helvetica Neue',
    'Helvetica',
    'Impact',
    'Lucida Grande',
    'Tahoma',
    'Times New Roman',
    'Verdana',
    'Ultra',
    'fmBindu'
];

export const FONT_SIZES = [8, 9, 10, 11, 12, 14, 18, 24, 36];

export const Keys = {
    B: 66,
    Backspace: 8,
    Delete: 127,
    E: 69,
    Enter: 13,
    I: 73,
    J: 74,
    L: 76,
    R: 82,
    T: 84,
    Tab: 9,
    U: 85,
    '[': 219,
    ']': 221,
  };

export const COLORS = [
    '#000000',
    '#FFFFFF',
    '#888888',
    '#AAAAAA',
    '#EEEEEE',
    '#880000',
    '#CC0000',
    '#FF0000',
    '#FFCCCC',
    '#FF8800',
    '#FFCC00',
    '#FFFF00',
    '#CCFF00',
    '#88FF00',
    '#008800',
    '#00CC00',
    '#00CC88',
    '#00CCCC',
    '#CCEEFF',
    '#00CCFF',
    '#0088FF',
    '#0000FF',
    '#8800FF',
    '#CC00CC',
    '#CC0088',
];

export const defaultPreTagStyling = [
    ['padding', '9.5px'],
    ['margin', '0 0 10px'],
    ['border', '1px solid rgb(204, 204, 204)'],
    ['background', 'rgb(245, 245, 245)'],
  ];