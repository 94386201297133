import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { deleteSubjectTopic, getSubjectTopicsForSubject } from '../../services/subjects/subjectTopics';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddSubjectTopic from './AddSubjectTopic';
import EditSubjectTopic from './EditSubjectTopic';

function SubjectTopicsPage() {
  const location = useLocation();
  const [subjectTopics, setSubjectTopics] = useState([]);
  const [selectedEditTopic, setSelectedEditTopic] = useState(null);
  const [subjectName, setSubjectName] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addSubjectTopicsFormOpen, setAddSubjectTopicsFormOpen] = useState(false);
  const [editSubTopicDialogOpen, setEditSubTopicDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const subjectsId = location.state?.subjectId || null;
  const syllabusId = location.state?.syllabusId || null;
  const currentClickedTopic = useRef(null);
  const [parentTopic, setParentTopic] = useState(null);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleEditSubTopicOpen = () => {
    setEditSubTopicDialogOpen(true);
  };

  const handleEditSubTopicClose = () => {
    setSelectedEditTopic(null);
    setEditSubTopicDialogOpen(false);
  };

  const handleMenuOpen = (event, topic) => {
    setAnchorEl(event.currentTarget);
    currentClickedTopic.current = topic;
  
    
 
    const { hasParent, parentTopic } = findTopicParent(subjectTopics, topic);

 
    setParentTopic(hasParent ? parentTopic : null);
  };
  
 
  const findTopicParent = (tree, targetTopic) => {
    for (const topic of tree) {
      if (topic.subTopics && topic.subTopics.length > 0) {
 
        if (topic.subTopics.includes(targetTopic)) {
          return { hasParent: true, parentTopic: topic };
        }
 
        const result = findTopicParent(topic.subTopics, targetTopic);
        if (result.hasParent) {
          return result;
        }
      }
    }
    return { hasParent: false, parentTopic: null };
  };
  
  
  

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const loadSubjectTopics = async () => {
    try {
      const response = await getSubjectTopicsForSubject(subjectsId);
      setSubjectTopics(response.data);  
      setLoading(false);
    } catch (error) {
      console.error('Error loading subject topics:', error);
      setLoading(false);  
    }
  };
  

  useEffect(() => {
    loadSubjectTopics();
  }, [location.state?.subjectId]);
  

  const handleAddNewTopic = () => {
    setAddSubjectTopicsFormOpen(true);
  };

  const handleDeleteSubjectTopic = async () => {
    try {
      if (currentClickedTopic.current) {
        const topicId = currentClickedTopic.current.id;
        await deleteSubjectTopic(  subjectsId, topicId);

        setSubjectTopics((prevTopics) =>
          prevTopics.filter((topic) => topic.id !== topicId)
        );
      }
    } catch (error) {
      console.error('Error deleting subject topic:', error);
    } finally {
      handleMenuClose();
    }
  };

  const renderTreeItems = (topics) => {
    return topics.map((topic) => (
      <TreeItem
        key={topic.id}
        nodeId={topic.id.toString()}
        label={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '8px', fontSize: '18px', flexGrow: 1 }}>{topic.name}</span>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton style={{ fontSize: '20px' }} onClick={(event) => handleMenuOpen(event, topic)}>
                <MoreHorizIcon />
              </IconButton>
            </div>
          </div>
        }
      >
        {topic.subTopics && renderTreeItems(topic.subTopics)}
      </TreeItem>
    ));
  };
  

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div style={{ marginBottom: '20px' }}>
        <Typography variant="h4">Subject: {subjectName}</Typography>
        <Button onClick={handleAddNewTopic} variant="contained" color="primary" style={{ marginTop: '8px' }}>
          Add New Subject Topic
        </Button>
      </div>
      <TreeView aria-label="subject-topics-tree" defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
        {renderTreeItems(subjectTopics)}
      </TreeView>
      {addSubjectTopicsFormOpen && (
        <AddSubjectTopic
          open={addSubjectTopicsFormOpen}
          onClose={() => setAddSubjectTopicsFormOpen(false)}
          subjectsId={subjectsId}
          setSubjectTopics={setSubjectTopics}
          syllabusId={syllabusId}
          parentTopics={subjectTopics}
          loadSubjectTopics={loadSubjectTopics}
        />
      )}
      {editSubTopicDialogOpen && (
        <EditSubjectTopic
          open={editSubTopicDialogOpen}
          onClose={handleEditSubTopicClose}
          subjectTopic={currentClickedTopic.current}
          parentOfselectedTopic={parentTopic}
          setSubjectTopics={setSubjectTopics}
          syllabusId={syllabusId}
          subjectsId={subjectsId}
          parentTopics={subjectTopics}
          loadSubjectTopics={loadSubjectTopics}
        />
      )}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={handleEditSubTopicOpen}>
          <EditIcon style={{ fontSize: '16px', marginRight: '8px' }} />
          Edit
        </MenuItem>
        <MenuItem onClick={handleDeleteSubjectTopic}>
          <DeleteIcon style={{ fontSize: '16px', marginRight: '8px' }} />
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
}

export default SubjectTopicsPage;
