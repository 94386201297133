import api from ".."
import Env from '../../config/environments';
import { ApiEndpoint, Method } from "../../constants"

const params = {};
const body = {};

export const loginUser = (userName, password) => {
    const body = {
        userName,
        password
    }

    return api(ApiEndpoint.AUTH_SERVICE, Method.POST, params, Env.BASE_URL, body)

}

export const registerUser = (userName, password)=>{
    const body = {
        userName,
        password
    }
    return api(ApiEndpoint.REGISTER_SERVICE, Method.POST, params, Env.BASE_URL, body)

}

export function refreshToken() {
    return api(ApiEndpoint.AUTH_SERVICE + "/refresh-token",
        Method.POST, params,
        Env.BASE_URL, body);
}


// export function verifyOTP(body){
//     return api(`${ApiEndpoint.REGISTER_SERVICE}/otp`, Method.POST, params, Env.BASE_URL, body)
// }
export function verifyOTP(body){
    return api(`${ApiEndpoint.AUTH_SERVICE}/otp`, Method.POST, params, Env.BASE_URL, body)
}
