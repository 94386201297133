import api from "..";
import Env from '../../config/environments';
import { ApiEndpoint, Method } from "../../constants";

const params = {};

export const createSyllabus = (newSyllabus) => {
    return api(ApiEndpoint.READ_SYLLABUS, Method.POST, params, Env.BASE_URL, newSyllabus);
};

export const readSyllabus = (syllabusId) => {
    return api(ApiEndpoint.READ_SYLLABUS + `/${syllabusId}`, Method.GET, params, Env.BASE_URL);
};

export const readAllSyllabues = () => {
    return api(ApiEndpoint.READ_SYLLABUS , Method.GET, params, Env.BASE_URL);
};

export const updateSyllabus = (syllabusId, updatedData) => {

    return api(ApiEndpoint.READ_SYLLABUS + `/${syllabusId}`, Method.PUT, params, Env.BASE_URL, updatedData);
};

export const deleteSyllabus = (syllabusId) => {
    return api(ApiEndpoint.READ_SYLLABUS  + `/${syllabusId}`, Method.DELETE, params, Env.BASE_URL);
};
