import { combineReducers } from 'redux'
import userReducer from './reducers/userReducer'
import SyllabusReducer from './reducers/SyllabusReducer';
import languageReducer from './reducers/languageReducer';
const rootReducer = combineReducers({
    userReducer : userReducer,
    SyllabusReducer : SyllabusReducer,
    languageReducer: languageReducer
})

export default rootReducer