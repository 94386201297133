import React from 'react'
import RichTextEditor from '..'

function RichEditor() {
  return (
    <div>
        <RichTextEditor onChange = {() =>{}}/>
    </div>
  )
}

export default RichEditor