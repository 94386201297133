import React from 'react'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { Viewer, Worker } from '@react-pdf-viewer/core';
// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

function PDFViewDialog({open, handleClose, url}) {
  return (
    <Dialog 
            open={open}
            onClose={handleClose}
        >
             <DialogTitle style={{backgroundColor : '#87BCDE', color : 'white'}}>View Paper</DialogTitle>
             <DialogContent style={{padding : 16}}>
              {url && (
                  <div style={{ height: '500px', minWidth : '500px', backgroundColor: 'red' }}>
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js">
                          <Viewer
                              fileUrl={url}
                          />
                      </Worker>
                  </div>
                )}
                </DialogContent>
    </Dialog>
  )
}

export default PDFViewDialog