import api from ".."
import Env from '../../config/environments';
import { ApiEndpoint, Method } from "../../constants"
 
const params = {};
const body = {};

export const createReviw = (body) => {

    return api(ApiEndpoint.UNLIMITED_QUESTION_REVIEWS, Method.POST, params, Env.BASE_URL, body)

}

export const getQuestionReviews = (offset =0 ,pageSize=20, baseQuestionId,isApproved=false,teacherProfile=false,teacherId) => {
    
    const params = {
        offset,
        pageSize,
        baseQuestionId,
      };
    
     
    if (isApproved) {
      params.isApproved = isApproved;
    }
    if (teacherId) {
        params.teacherId = teacherId;
    }
    if (teacherProfile) {
        params.teacherProfile = teacherProfile;
    }
  
    return api(`${ApiEndpoint.UNLIMITED_QUESTION_REVIEWS}/${baseQuestionId}`, Method.GET, params, Env.BASE_URL, body)

}

export const updateReview = (reviewId,body ) => {

    return api(`${ApiEndpoint.UNLIMITED_QUESTION_REVIEWS}/${reviewId}`, Method.PUT, params, Env.BASE_URL, body)

}

export const deleteReview = (reviewId ) => {

    return api(`${ApiEndpoint.UNLIMITED_QUESTION_REVIEWS}/${reviewId}`, Method.DELETE, params, Env.BASE_URL, body)

}
 