import api from "../.."
import Env from '../../../config/environments';
import { ApiEndpoint, Method } from "../../../constants"
 
const params = {};
const body = {};

export const getMcqQuestions = (subjectId, topicId, params) => {
    
        return api(`${ApiEndpoint.MCQ_QUESTION}/${subjectId}/${topicId}`, Method.GET, params, Env.BASE_URL, body)
    
    }

export const createMcqQuestion = (subjectId, mcqQuestion) => {
    return api(`${ApiEndpoint.MCQ_QUESTION}/${subjectId}`, Method.POST, params, Env.BASE_URL, mcqQuestion)
}

export const updateMcqQuestion = (id, mcqQuestion) => {
    console.log(mcqQuestion)
    return api(`${ApiEndpoint.MCQ_QUESTION}/${id}`, Method.PUT, params, Env.BASE_URL, mcqQuestion)
}