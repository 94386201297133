import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
} from '@mui/material';
import { editSubjectTopic } from '../../services/subjects/subjectTopics';

function EditSubjectTopic({ loadSubjectTopics, open, onClose, subjectTopic, setSubjectTopics, syllabusId, subjectsId, parentTopics ,parentOfselectedTopic}) {
  const [editedTopicName, setEditedTopicName] = useState(subjectTopic.name);
  const [editedTopicDescription, setEditedTopicDescription] = useState(subjectTopic.description);
  const [isLabelChecked, setIsLabelChecked] = useState(true);  
  const [editedOrder, setEditedOrder] = useState('');
  const [selectedParentTopic, setSelectedParentTopic] = useState(null);
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [questionsAtThisLevel, setQuestionsAtThisLevel] = useState(false);

  useEffect(() => {
    console.log('subjectTopic.parentTopic', parentOfselectedTopic);
    setSelectedParentTopic(parentOfselectedTopic);
    setIsLabelChecked(!!parentOfselectedTopic); 
    setQuestionsAtThisLevel(subjectTopic.questionsAtThisLevel); 
    setEditedOrder(subjectTopic.sortingOrder)
  }, [parentTopics, subjectTopic.parentTopic]);
  
  const handleCheckboxChange = () => {
    
    const updatedIsLabelChecked = !isLabelChecked;
    setIsLabelChecked(updatedIsLabelChecked);
  
    
    const newSelectedParentTopic = updatedIsLabelChecked ? selectedParentTopic || { id: -1, name: '' } : { id: -1, name: '' };
    setSelectedParentTopic(newSelectedParentTopic);
     
  };
  const handleSave = async () => {
    setLoading(true); // Set loading state to true when saving data
    try {
      const editedSubjectTopic = {
        name: editedTopicName,
        description: editedTopicDescription,
        isLabelChecked,
        parentTopic: selectedParentTopic ? selectedParentTopic :  { id: -1 },
        sortingOrder:editedOrder,
        questionsAtThisLevel
      };

      const response = await editSubjectTopic(subjectsId, subjectTopic.id, editedSubjectTopic);

      setSubjectTopics((prevTopics) =>
        prevTopics.map((topic) => (topic.id === response.data.id ? response.data : topic))
      );

      onClose();
      loadSubjectTopics();
    } catch (error) {
      console.error('Error editing subjectTopic:', error);
    } finally {
      setLoading(false); // Set loading state to false after saving data
    }
  };
  console.log('selectedParentTopic',selectedParentTopic)
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Subject Topic</DialogTitle>
      <DialogContent>
        <TextField
          label="Topic Name"
          fullWidth
          value={editedTopicName}
          onChange={(e) => setEditedTopicName(e.target.value)}
        />
        <TextField
          label="Topic Description"
          fullWidth
          multiline
          rows={4}
          value={editedTopicDescription}
          onChange={(e) => setEditedTopicDescription(e.target.value)}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isLabelChecked}
              onChange={handleCheckboxChange}
              color="primary"
            />
          }
          label="Select Parent Topic"
        />
        {isLabelChecked && (
          <FormControl fullWidth>
            <InputLabel id="select-label">Select Parent Topic</InputLabel>
            <Select
              labelId="select-label"
              value={selectedParentTopic ? selectedParentTopic.id : ''}
              onChange={(e) => {
                const selectedTopic = parentTopics.find((topic) => topic.id === e.target.value);
                setSelectedParentTopic(selectedTopic || null);
              }}
              renderValue={(selected) =>
                selected ? selectedParentTopic.name : 'No Parent Topic'
              }
            >
              <MenuItem value="">No Parent Topic</MenuItem>
              {parentTopics.map((topic) => (
                <MenuItem key={topic.id} value={topic.id}>
                  {topic.name}
                </MenuItem>
              ))}
            </Select>

          </FormControl>
        )}
        <TextField
          label="Order"
          fullWidth
          value={editedOrder}
          onChange={(e) => setEditedOrder(e.target.value)}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={questionsAtThisLevel}
              onChange={() => setQuestionsAtThisLevel(!questionsAtThisLevel)}
              color="primary"
            />
          }
          label="Questions at this level"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Save'} {/* Display loading indicator or "Save" text */}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditSubjectTopic;
