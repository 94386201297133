import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { updateGroupsOfSyllabus } from '../../services/subjects/subjectGroup';
import { getGroupsForASyllabus } from '../../services/subjects/subjectGroup';

const EditGroupForm = ({ isOpen, onClose, groupData, languages, syllabusId, setParentOfSelectedGroup, parent, loadGroups, setGroups }) => {
  const [editedName, setEditedName] = useState('');
  const [editedDescription, setEditedDescription] = useState('');
  const [editedSubGroupNames, setEditedSubGroupNames] = useState({});
  const [loading, setLoading] = useState(false); // Loading state
  const [editedOrder, setEditedOrder] = useState('');
  const [isLabelChecked, setIsLabelChecked] = useState(false);
  const [parentGroups, setParentGroups] = useState([]);
  const [selectedParentGroup, setSelectedParentGroup] = useState({});
  const [isActive, setIsActive] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({});

  useEffect(() => {
    fetchParentGroups();
  }, [syllabusId]);

  const fetchParentGroups = async () => {
    try {
      const response = await getGroupsForASyllabus(syllabusId);
      setParentGroups(response.data);
    } catch (error) {
      console.error('Error fetching parent groups:', error);
    }
  };

  useEffect(() => {
    if (groupData) {
      setEditedName(groupData.name || '');
      setEditedDescription(groupData.description || '');
      setEditedOrder(groupData.sortingOrder);
      setIsActive(groupData.isActive);

      if (groupData.nameTags) {
        const nameTags = {};
        const initialCheckboxStates = {};
        groupData.nameTags.forEach((tag) => {
          nameTags[tag.language.code] = tag.name;
          initialCheckboxStates[tag.language.code] = tag.isActive || false;
        });
        setEditedSubGroupNames(nameTags);
        setCheckboxStates(initialCheckboxStates);
      }

      setSelectedParentGroup(parent || null);
      setIsLabelChecked(!!parent);
    }
  }, [groupData]);

  const handleNameChange = (e) => {
    setEditedName(e.target.value);
  };

  const handleCheckboxChange = () => {
    const updatedIsLabelChecked = !isLabelChecked;
    setIsLabelChecked(updatedIsLabelChecked);

    const newSelectedParentGroup = updatedIsLabelChecked ? selectedParentGroup || { id: -1, name: '' } : { id: -1, name: '' };
    setSelectedParentGroup(newSelectedParentGroup);
    setParentOfSelectedGroup(newSelectedParentGroup);
  };

  const handleOrderChange = (e) => {
    setEditedOrder(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setEditedDescription(e.target.value);
  };

  const handleSubgroupNameChange = (languageCode, value) => {
    setEditedSubGroupNames((prevSubGroupNames) => ({
      ...prevSubGroupNames,
      [languageCode]: value,
    }));
  };

  const handleLanguageCheckboxChange = (languageCode, event) => {
    const isChecked = event.target.checked;
    setCheckboxStates((prevStates) => ({
      ...prevStates,
      [languageCode]: isChecked,
    }));
  };

  const handleEditGroup = async () => {
    setLoading(true); // Set loading to true while submitting form

    try {
      const editedNameTags = Object.keys(editedSubGroupNames).map((languageCode) => {
        const existingTag = groupData.nameTags.find((tag) => tag.language.code === languageCode);

        return existingTag
          ? { ...existingTag, name: editedSubGroupNames[languageCode], isActive: checkboxStates[languageCode] }
          : { language: { code: languageCode }, name: editedSubGroupNames[languageCode], isActive: checkboxStates[languageCode] };
      });

      const editedGroup = {
        name: editedName,
        description: editedDescription,
        nameTags: editedNameTags,
        sortingOrder: editedOrder,
        isLabelChecked,
        parentGroup: selectedParentGroup || { id: -1 },
        isActive,
      };

      const response = await updateGroupsOfSyllabus(syllabusId, groupData.id, editedGroup);
      console.log('response:', response.data);
      setParentOfSelectedGroup(selectedParentGroup || { id: -1 });

      onClose();
      loadGroups();
    } catch (error) {
      console.error('Error editing group:', error);
    } finally {
      setLoading(false); // Set loading to false after form submission completes
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Edit Group</DialogTitle>
      <DialogContent>
        <TextField label="Name" value={editedName} onChange={handleNameChange} fullWidth margin="normal" />
        <TextField label="Description" value={editedDescription} onChange={handleDescriptionChange} fullWidth margin="normal" />

        {languages.map((language) => (
          <Box key={language.code} display="flex" alignItems="center" style={{ margin: '5px' }}>
            <TextField
              style={{ flexGrow: 1, marginRight: '5px' }}
              label={`${language.name} Name`}
              value={editedSubGroupNames[language.code] || ''}
              onChange={(e) => handleSubgroupNameChange(language.code, e.target.value)}
              fullWidth
              margin="normal"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxStates[language.code] || false}
                  onChange={(e) => handleLanguageCheckboxChange(language.code, e)}
                />
              }
              label="Active"
            />
          </Box>
        ))}

        <FormControlLabel
          control={<Checkbox checked={isLabelChecked} onChange={handleCheckboxChange} color="primary" />}
          label="Select Parent Group"
        />
        {isLabelChecked && (
          <FormControl fullWidth>
            <InputLabel id="select-label">Select Parent Group</InputLabel>
            <Select
              labelId="select-label"
              value={selectedParentGroup ? selectedParentGroup.id : ''}
              onChange={(e) => {
                const selectedGroupId = e.target.value;
                const selectedGroup = parentGroups.find((group) => group.id === selectedGroupId);
                setSelectedParentGroup(selectedGroup || { id: -1, name: '' });
                setParentOfSelectedGroup(selectedGroup);
              }}
              renderValue={(selected) =>
                selected ? selectedParentGroup.name : 'No Parent Group'
              }
            >
              <MenuItem value="">No Parent Group (Subject Group)</MenuItem>
              {parentGroups.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <TextField label="Order" value={editedOrder} onChange={handleOrderChange} fullWidth margin="normal" />
        <FormControlLabel
          control={<Checkbox checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />}
          label="Active"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>Cancel</Button>
        <Button onClick={handleEditGroup} disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Save Changes'} {/* Show loading indicator if loading */}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditGroupForm;
