import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { getLanguages } from '../../services/subjects/languages';
import { useLocation } from 'react-router-dom';
import { getSubjectsForAGroup } from '../../services/subjects/subjects';
import { deleteSubjectGroup, getGroupsForASyllabus } from '../../services/subjects/subjectGroup';
import { Button, IconButton, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddSubjectBox from './AddSubjectBox';
import AddSubgroupsForm from './AddSubgroupsForm';
import EditIcon from '@mui/icons-material/Edit';
import EditGroupForm from './EditGroupForm';
import DeleteIcon from '@mui/icons-material/Delete';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    selectedSyllabusHere: state.SyllabusReducer.syllabus,
  };
};

function GroupPage({ selectedSyllabusHere, selectedLanguages }) {
  const [selectedLanguage, setSelectedLanguage] = useState(selectedLanguages[0] || null);
  const [subjects, setSubjects] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isAddSubgroupFormOpen, setIsAddSubgroupFormOpen] = useState(false);
  const [newSubject, setNewSubject] = useState('');
  const [Grade, setGrade] = useState('');
  const [isEditGroupFormOpen, setIsEditGroupFormOpen] = useState(false);
  const [selectedEditGroup, setSelectedEditGroup] = useState(groups[0] || null);
  const [parentOfSelectedGroup, setParentOfSelectedGroup] = useState({});
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState(null);

  const handleEditGroupFormOpen = async (group, parent) => {
    setParentOfSelectedGroup(parent);
    setSelectedEditGroup(group);
    setSelectedGroup(group);
    setIsEditGroupFormOpen(true);
  };

  const handleEditGroupFormClose = () => {
    setSelectedEditGroup(null);
    setIsEditGroupFormOpen(false);
  };

  const handleAddSubjectFormOpen = () => {
    setIsAddSubgroupFormOpen(true);
  };

  const handleAddSubjectFormClose = () => {
    setIsAddSubgroupFormOpen(false);
  };

  useEffect(() => {
    const fetchSubjectsForSelectedGroup = async () => {
      try {
        if (selectedGroup) {
          const response = await getSubjectsForAGroup(selectedGroup.id);
          const { data: subjectsData } = response;
          setSubjects(subjectsData);
        }
      } catch (error) {
        console.error('Error fetching subjects for the group:', error);
      }
    };

    fetchSubjectsForSelectedGroup();
  }, [selectedGroup]);

  const handleLanguageChange = (event) => {
    const selectedLanguageId = event.target.value;
    const language = selectedLanguages.find((lang) => lang.id === selectedLanguageId);
    setSelectedLanguage(language);
  };

  const handleTreeItemClick = async (clickedGroup, parentGroup = null) => {
    try {
      if (clickedGroup.subGroups && clickedGroup.subGroups.length > 0) {
        setSelectedGroup(clickedGroup);
        setSubjects([]);
      } else {
        const response = await getSubjectsForAGroup(clickedGroup.id);
        const { data: subjectsData } = response;
        setSubjects(subjectsData);

        if (parentGroup) {
          const updatedGroup = { ...clickedGroup, parentGroup };
          setSelectedGroup(updatedGroup);
        } else {
          setSelectedGroup(clickedGroup);
        }
      }
    } catch (error) {
      console.error('Error handling tree item click:', error);
    }
  };

  const loadGroups = async () => {
    if (selectedSyllabusHere.id !== -1) {
      try {
        const response = await getGroupsForASyllabus(selectedSyllabusHere.id);
        const fetchedGroups = response.data || [];
        setGroups(fetchedGroups);
      } catch (error) {
        console.error('Error fetching groups for syllabus:', error);
      }
    }
  };

  useEffect(() => {
    loadGroups();
  }, [selectedSyllabusHere]);

  const getGroupName = (group) => {
    if (selectedLanguage && selectedLanguage.id) {
      const languageTag = group.nameTags.find((tag) => tag.language.id === selectedLanguage.id);
      const uniqueSubgroupNames = [...new Set(group.subGroups.map((subgroup) => subgroup.name))];
      return languageTag ? languageTag.name : uniqueSubgroupNames.join(', ');
    }
    return group.name;
  };

  const handleAddSubject = () => {
    if (newSubject) {
      const newSubjectObj = {
        name: newSubject,
        subjectGrade: { id: 'someGradeId' },
      };

      setSubjects([...subjects, newSubjectObj]);
      setNewSubject('');
    }
  };

  const handleDeleteGroup = async () => {
    try {
      if (groupToDelete) {
        await deleteSubjectGroup(selectedSyllabusHere.id, groupToDelete.id);
        setGroups(groups.filter(group => group.id !== groupToDelete.id));
        loadGroups();
      }
      setIsDeleteDialogOpen(false);
      setGroupToDelete(null);
    } catch (error) {
      console.error('Error deleting group:', error);
    }
  };

  const openDeleteDialog = (groupId) => {
    setGroupToDelete(groupId);
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setGroupToDelete(null);
  };

  const renderTreeItems = (groups, parent) => {
    return groups.map((group) => (
      <TreeItem
        key={group.id}
        nodeId={group.id.toString()}
        label={(
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ marginRight: '8px', fontSize: '16px' }}>{getGroupName(group)}</span>
            <div>
              <IconButton
                style={{ marginLeft: '8px', fontSize: '20px' }}
                onClick={() => handleEditGroupFormOpen(group, parent)}
              >
                <EditIcon style={{ fontSize: '16px' }} />
              </IconButton>
              <IconButton
                style={{ marginLeft: '8px', fontSize: '20px' }}
                onClick={() => openDeleteDialog(group)}
              >
                <DeleteIcon style={{ fontSize: '16px' }} />
              </IconButton>
            </div>
          </div>
        )}
        onClick={() => handleTreeItemClick(group)}
      >
        {group.subGroups && renderTreeItems(group.subGroups, group)}
      </TreeItem>
    ));
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 4, p: 2, width: 'md' }}>
      <Box>
        <Button 
          variant="contained"
          color="primary"
          sx={{ mt: 2, mb: 1 }} 
          onClick={handleAddSubjectFormOpen}
        >
          Add Group
        </Button>
        <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
          <InputLabel>Select Language</InputLabel>
          <Select value={selectedLanguage ? selectedLanguage.id : ''} onChange={handleLanguageChange}>
            {selectedLanguages.map((language) => (
              <MenuItem key={language.id} value={language.id}>
                {language.name}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box sx={{ minHeight: 180, flexGrow: 1, maxWidth: 300 }}>
          {groups.length === 0 ? (
            <Typography>No groups available.</Typography>
          ) : (
            <TreeView
              aria-label="file system navigator"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
            >
              {renderTreeItems(groups, null)}
            </TreeView>
          )}
        </Box>
      </Box>

      <AddSubjectBox
        Grade={Grade}
        setGrade={setGrade}
        newSubject={newSubject}
        setNewSubject={setNewSubject}
        handleAddSubject={handleAddSubject}
        subjects={subjects}
        selectedSyllabus={selectedSyllabusHere}
        selectedGroup={selectedGroup}
        setSubjects={setSubjects}
      />

      {isAddSubgroupFormOpen && (
        <AddSubgroupsForm
          onClose={handleAddSubjectFormClose}
          languages={selectedLanguages}
          handleSubgroupNameChange={getGroupName}
          isOpen={isAddSubgroupFormOpen}
          parentGroup={selectedGroup}  
          syllabusId={selectedSyllabusHere.id}
          loadGroups={loadGroups}
        />
      )}

      {isEditGroupFormOpen && (
        <EditGroupForm
          onClose={handleEditGroupFormClose}
          groupData={selectedEditGroup}
          isOpen={isEditGroupFormOpen}
          languages={selectedLanguages}
          syllabusId={selectedSyllabusHere.id}
          setGroups={setGroups}
          groups={groups}
          loadGroups={loadGroups}
          parent={parentOfSelectedGroup}
          setParentOfSelectedGroup={setParentOfSelectedGroup}
        />
      )}

      <Dialog
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this group?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteGroup} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default connect(mapStateToProps)(GroupPage);
