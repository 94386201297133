import  api  from '../../services'; 
// Action types
export const SET_SYLLABUS = 'SET_SYLLABUS';
export const UPDATE_SYLLABUS = 'UPDATE_SYLLABUS';
export const DELETE_SYLLABUS = 'DELETE_SYLLABUS';
export const GET_SYLLABUS_DETAILS_SUCCESS = 'GET_SYLLABUS_DETAILS_SUCCESS';

// Action creators
export const setSyllabus = (syllabus) => {
    return {
        type: SET_SYLLABUS,
        payload: syllabus
    };
};

export const updateSyllabus = (updatedSyllabus) => {
    return {
        type: UPDATE_SYLLABUS,
        payload: updatedSyllabus
    };
};

export const deleteSyllabus = () => {
    return {
        type: DELETE_SYLLABUS
    };
};

export const getSyllabusDetails = (syllabusDetails) => ({
    type: GET_SYLLABUS_DETAILS_SUCCESS,
    payload: syllabusDetails,
  });

  
   