import * as React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';  
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import Syllabus from '../../assets/syllabus.png'
import AllQuestions from '../../assets/AllQuestions.jpeg'
import Teachers from '../../assets/Group 14.png'
import Students from '../../assets/16-01.png'
import Classroom from '../../assets/9-01 1.png'
import Reports from '../../assets/2-01.png'
import ScanCard from '../../assets/saaya5-01 2.png'
import Attendance from '../../assets/11-01.png'
import ClassPayment from '../../assets/1-01.png'
import SMS from '../../assets/5-01.png'
import Staff from '../../assets/8-01.png'
import StartStopClass from '../../assets/13-01 1.png'
import Settings from '../../assets/6-01 1.png'
import Class from '../../assets/4-01.png'
import { object } from 'prop-types';
import { useNavigate } from 'react-router'
import { getLanguages } from '../../services/subjects/languages';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguages } from '../../store/actions/languageAction';
import { colors } from '@mui/material';

const itemsDefult = [
    { image:  Syllabus, title: 'Syllabus' },
    { image: Teachers, title: 'Teachers' },
    { image: Students, title: 'Students' },
    //{ image: Classroom, title: 'Classroom Resources' },
    { image: Reports, title: 'Reports' },
    //{ image: ScanCard, title: 'Scan Class Card' },
    //{ image: Attendance, title: 'Attendance' },
    //{ image: ClassPayment, title: 'Class Payment' },
    //{ image: SMS, title: 'SMS' },
    //{ image: Staff, title: 'Staff' },
    //{ image: StartStopClass, title: 'Start/Stop Class' },
    //{ image: Settings, title: 'Settings' },
    //{ image: Class, title: 'Class' },
    { image: AllQuestions, title: 'All Questions' },
    //{ image: 'image15.jpg', title: 'Text' },
  ];

  const gridContainerStyle = {
    //width: '1200px', // Set the custom width here
    // height: '800px', // Set the custom height here
    // backgroundColor:'green'
  };
  
  const imageStyle = {
    width: '100%',
    height: 'auto',
  };

  const userHasRole = (user, role) => {
    return user.roles && user.roles.includes(role);
  };

export default function Dashboard() {
      const [items, setItems] = React.useState(itemsDefult);
      const [clickedIndex, setClickedIndex] = React.useState(null);
      //const [isOnMenuItem, setIsOnMenuItem] = React.useState(null);
      const [hoveredIndex, setHoveredIndex] = React.useState(null);

      const dispatch = useDispatch( )
      
      const navigate = useNavigate()
      const user = useSelector(state => state.userReducer.user);
      const handlePaperClick = (index) => {
        setClickedIndex(index);
        if (index == 1) {
          navigate('/dashboard/teacher');
        }
        if (index == 0) {
          navigate('syllabuses');
        }
        if (index == 4) {
          navigate('/dashboard/syllabuses/selectedSyllabus/questionsBank');
        }
      };
    
      // const onMouseEnter = (index) =>{
      //     setIsOnMenuItem({[index]:true})
      // };

      const   loadLanguages = async ()=>{
        const response = await getLanguages();
        console.log("response " + response)
        const {data:languages}=response
        console.log(languages)
        
        dispatch(setLanguages(languages))
      }
      React.useEffect(() => {
         
      loadLanguages ();
           
         
      }, [ ])
      
      const filteredItems = userHasRole(user, 'TYPER')
    ? items.filter(item => item.title === 'Syllabus')
    : items;

     
  return (
    // <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', margin: '10px 100px' }}>
    <Container 
    maxWidth='xl'
			disableGutters
      alignItems='center'
      justifyContent='center'
			sx={{ padding: 4}}
    >
      
        <Grid container
				rowSpacing={4}
        columnSpacing={4}
				// paddingY={4} 
        
        gap={1}
        sx={{color:'black'
        ,justifyContent:'space-between'}}>
          {filteredItems.map((item, index) => (
            <Grid container item spacing={0} key={index} >
              <Paper elevation={index === clickedIndex ? 10 : 3}
              sx={{
                width: '208px',
                height: '230px',
                cursor: 'pointer',
                backgroundColor:  index === hoveredIndex ? '#CADCE8' : 'white',//index === (Object.keys(isOnMenuItem).find(value=>value===index)) ? 'red':'white',
                transform: index === clickedIndex ? 'scale(1.05)' : 'scale(1)',
              }}
              onClick={() => handlePaperClick(index)}
              onMouseEnter = {() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                  <img src={item.image} alt={item.title} align="center" style={imageStyle} />
                  <Typography variant="subtitle1" align="center">
                    {item.title}
                  </Typography>
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
        </Container>
    // </div>
  )
}
