import api from ".."
import Env from '../../config/environments';
import { ApiEndpoint, Method } from "../../constants"
 
const params = {};
const body = {};

export const getLanguages = ( ) => {

    return api(ApiEndpoint.GET_LANGUAGES, Method.GET, params, Env.BASE_URL, body)

}
 