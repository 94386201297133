import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { Autocomplete, Checkbox, DialogActions, DialogContent, TextField } from '@mui/material';
import Dropzone from 'react-dropzone';
import ImagePreview from './ImagePreview';

const emails = ['username@gmail.com', 'user02@gmail.com'];

function ImageForm(props) {
    const { onClose, selectedValue, open } = props;

    const [image, setImage] = useState(null);
    const [hasError, setHasError] = useState(false);
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const [imageWidth, setImageWidth] = useState(0)
    const [imageHeight, setImageHeight] = useState(0)
    const [imageCurrentWidthAndHeight, setImageCurrentWidthAndHeight] = useState({ width: 0, height: 0 })
    const [isMaintainRatio, setIsMaintainRatio] = useState(true)
    const [imageFile, setImageFile] = useState(null)
    const [description, setDescription] = useState('')
    const [caption, setCaption] = useState('')


    const onTogleRatio = () => {
        setIsMaintainRatio(isMaintainRatio => !isMaintainRatio)
    }

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    const resetError = () => {
        if (hasError) {
            setHasError(false);
        }
    }

    const readFile = (file) => {
        const FR = new FileReader();
        FR.addEventListener('load', e => {
            // props.formLinker.setValue(props.name, file);
            setImage(e.target.result);
            // console.log(e.target)
            setImageFile(file)

            var image = new Image();
            image.src = e.target.result;

            image.onload = function () {
                // access image size here 
                console.log(this.width);
                setWidth(this.width)
                setHeight(this.height)
                setImageWidth(this.width)
                setImageHeight(this.height)
                setImageCurrentWidthAndHeight({
                    width: this.width,
                    height: this.height
                })

                // $('#imgresizepreview, #profilepicturepreview').attr('src', this.src);
            };

        });
        FR.readAsDataURL(file);
    }

    const drop = files => {
        setHasError(false);
        resetError();
        readFile(files[0]);
    }

    const dropError = (files) => {
        setHasError(true);
    }

    const clear = () => {
        setHasError(false);
        setImage(null);
        // props.formLinker.setValue(props.name, null);
    }

    const handleSubmit = () => {
        props.handleSubmit({
            imgFile: imageFile,
            imgUrl: '',
            height: imageCurrentWidthAndHeight.height,
            width: imageCurrentWidthAndHeight.width,
            caption,
            description
        });

        onClose()
        // if (isEmpty(fl.getValue('imgUrl')) && isEmpty(fl.getValue('imgFile'))) {
        //     setHasError(true);
        // } else {
        //     props.handleSubmit(cloneDeep(fl.data));
        //     fl.setValue('imgUrl', '', false);
        //     fl.setValue('imgFile', null, false);
        // }
    }

    const handleChangeImageWidth = e => {
        setWidth(Number(e.target.value))
    }

    const handleEndOfChangeImageWidth = () => {
        console.log("on blur")
        if (width > 0) {

            const h = (imageHeight / imageWidth) * width

            setImageCurrentWidthAndHeight({
                width: width,
                height: h
            })
            setHeight(h)
        }
    }

    const renderError = () => {
        if (!hasError) {
            return <noscript />;
        }
        return <div >Some error has happend please try again</div>;
    }

    const renderDropZone = () => {
        return (<div>
            drop
            <Dropzone
                multiple={false}
                maxSize={5000000}
                // accept={'image/*'}
                accept={{
                    'image/*': ['*'],
                    // 'text/html': ['.html', '.htm'],
                }}
                style={{}}
                //   className='dropzone'
                onDropAccepted={drop}
                onDropRejected={dropError}
            >
                {
                    ({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>Drag 'n' drop some files here, or click to select files</p>
                        </div>)
                }

            </Dropzone >
            {renderError()}

        </div >)
    }

    const renderContent = () => {
        if (image) {
            console.log("in image")
            return (<React.Fragment>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center' }}>
                        <ImagePreview
                            src={image}
                            previewWidth={'100%'}
                            previewHeight={height}
                            // width={imageCurrentWidthAndHeight}
                            imageWidthAndHeight={imageCurrentWidthAndHeight}
                            description={description}
                            caption={caption}
                        />
                    </div>

                </div>
                <Button block type='tertiary' onClick={clear}>
                    Clear image
                </Button>
                <div>
                    <TextField id="image-description" label="Description" variant="outlined" value={description} onChange={e => setDescription(e.target.value)} />
                    <TextField id="image-caption" label="Caption" variant="outlined" value={caption} onChange={e => setCaption(e.target.value)} />
                    <div style={{
                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                        padding: 8
                    }}>
                        <TextField id="image-width" label="Width (px)" variant="outlined" value={width}
                            onChange={handleChangeImageWidth}
                            onBlur={handleEndOfChangeImageWidth}
                        />

                        x
                        <TextField id="image-height" label="Height (px)" variant="outlined" value={height} />
                        <Checkbox checked={isMaintainRatio} onChange={onTogleRatio} disabled />
                    </div>
                    <div>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={["Top", "Middle", "bottom"]}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Alignment" />}
                        // freeSolo={true}
                        // autoComplete={false}
                        />
                    </div>
                    <DialogActions >
                        <Button onClick={handleSubmit} autoFocus>
                            Upload
                        </Button>
                    </DialogActions>
                </div>
            </React.Fragment>)
        }

        return renderDropZone()

    }

    // console.log({open})
    if(!open) return null

    // console.log(image)
    return (
        <Dialog onClose={handleClose} open={open} fullWidth>
            <DialogTitle>Image properties</DialogTitle>
            <DialogContent>


                <TextField id="image-url" label="Image url" variant="outlined" />
                <div>
                    {renderContent()}

                </div>
            </DialogContent>
        </Dialog>
    )
}

export default ImageForm