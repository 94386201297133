import api from ".."
import Env from '../../config/environments';
import { ApiEndpoint, Method } from "../../constants"
 
const params = {};
const body = {};

export const createUnlimitedQuestions = (body) => {

    return api(ApiEndpoint.UNLIMITEDQUESTIONS, Method.POST, params, Env.BASE_URL, body)

}

export const getUnlimitedQuestions = (offset = 0, pageSize = 20 ,subjectId=0,activeStatus=true,questionName="",orderBy='') => {
    const params = {
        offset,
        pageSize,
        subjectId
      };

      if (activeStatus) {
        params.activeStatus = activeStatus;
      }
    
      if (questionName) {
        params.questionName = questionName;
      }

      if (orderBy) {
        params.orderBy = orderBy;
      }
    return api(ApiEndpoint.UNLIMITEDQUESTIONS, Method.GET, params, Env.BASE_URL, body)

}

export const updateUnlimitedQuestions = (questionId,body ) => {

    return api(`${ApiEndpoint.UNLIMITEDQUESTIONS}/${questionId}`, Method.PUT, params, Env.BASE_URL, body)

}

export const deleteUnlimitedQuestions = (questionId ) => {

    return api(`${ApiEndpoint.UNLIMITEDQUESTIONS}/${questionId}`, Method.DELETE, params, Env.BASE_URL, body)

}
 