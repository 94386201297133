import { $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot, $insertNodes } from 'lexical';
import React, { memo, useEffect } from 'react'

const textHtmlMimeType = 'text/html';

const memoHtmlToStateConverter = memo (function HtmlToStateConverter({html, jsonEditorState}) {
    // console.log({html})
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        editor.update(() => {
            try {
                // console.log("in html to state converter")
                if(!html) return;

                const parser = new DOMParser();
                const dom = parser.parseFromString(html, textHtmlMimeType);
                const nodes = $generateNodesFromDOM(editor, dom);
                if(nodes.length > 0){
                    $getRoot().select();
                    $insertNodes(nodes);
                }
            } catch (e) {
                console.log(e)
                let error ;
                if(e){
                    const {message = "", stack = ""} = e
                    error = {error: message, stack}
                }else{
                    error = {error: "error in html to state converter"}
                }
                
                window?.ReactNativeWebView?.postMessage(JSON.stringify(error) )

            }
        })
    }, [html])

    useEffect(() => {


        editor.update(() => {
            //  console.log("in json editor state converter")
            try {
                if(!jsonEditorState) return;
                console.log({jsonEditorState})
                if(jsonEditorState == {}) return;
                if(Object.keys(jsonEditorState).length === 0 && jsonEditorState.constructor === Object) return;

                //check editor is empty
                const isEmpty = editor.getEditorState().isEmpty();
                // console.log({isEmpty})
                if(!isEmpty) return;

                const editorStateJson = JSON.parse(jsonEditorState);
                const editorState = editor.parseEditorState(editorStateJson)
                editor.setEditorState(editorState);
            } catch (e) {
                console.log(e)
                let error ;
                if(e){
                    const {message = "", stack = ""} = e
                    error = {error: message, stack}
                }else{
                    error = {error: "error in jsonEditorState"}
                }
                
                window?.ReactNativeWebView?.postMessage(JSON.stringify(error) )
            }
        })
    }, [jsonEditorState])

    useEffect(() => {

        editor.update(() => {
            try {
                const savedEditorState = localStorage.getItem('editorState');
                console.log(savedEditorState)
                if (!savedEditorState) return;

                const isEmpty = editor.getEditorState().isEmpty();
                // console.log({isEmpty})
                if (!isEmpty) return;

                const editorStateJson = JSON.parse(savedEditorState);
                const editorState = editor.parseEditorState(editorStateJson)
                editor.setEditorState(editorState);

                localStorage.setItem('editorState', null);
            } catch (e) {
                console.log(e)
                let error ;
                if(e){
                    const {message = "", stack = ""} = e
                    error = {error: message, stack}
                }else{
                    error = {error: "error in html"}
                }
                
                window?.ReactNativeWebView?.postMessage(JSON.stringify(error) )
            } finally {
                // console.log("finally")
                let status = {status: "finally loaded editor"}
                window?.ReactNativeWebView?.postMessage(JSON.stringify(status))
            }
        })
        

        
        // const handleLoad = (event) => {
            // window.alert("loaded")
            // if (window.ReactNativeWebView.injectedObjectJson) {
            //   document.getElementById('output').innerHTML = JSON.parse(window.ReactNativeWebView.injectedObjectJson()).customValue;
            // window.alert("loaded")

                // editor.update(() => {
                //     //  console.log("in json editor state converter")
                //     try {

                //         //check editor is empty
                //         const isEmpty = editor.getEditorState().isEmpty();
                //         // console.log({isEmpty})
                //         if (!isEmpty) return;

                //         const editorStateJson = JSON.parse(jsonEditorState);
                //         const editorState = editor.parseEditorState(editorStateJson)
                //         editor.setEditorState(editorState);
                //     } catch (e) {
                //         console.log(e)
                //     }
                // })

            // }
        //   };
      
        //   window.addEventListener('load', handleLoad);
      
        //   // Cleanup the event listener on component unmount
        //   return () => {
        //     window.removeEventListener('load', handleLoad);
        //   };
    }, [])
    
    
    
    return null;
}, (prevProps, nextProps) => {
    // console.log("/////////////////////////////")
    //  console.log( prevProps.jsonEditorState === nextProps.jsonEditorState)
    return prevProps.jsonEditorState === nextProps.jsonEditorState && prevProps.html === nextProps.html;
})

export default memoHtmlToStateConverter