import React, { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { useParams } from 'react-router-dom';
import { getQuestions } from '../../services/subjects/questions';
import ReusableTable from '../reusableComponents/ReusableTable';
import { createUnlimitedQuestions, deleteUnlimitedQuestions, getUnlimitedQuestions } from '../../services/subjects/unlimitedQuestions';

function AddUnlimitedQuestion() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [unlimitedQuestions, setUnlimitedQuestions] = useState([]);
  const [checkedRows, setCheckedRows] = useState({});
  const { paperId } = useParams(); 
  const subjectIdRequired = true;
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [questionName, setQuestionName] = useState('');
  const { subjectId } = useParams();
  const columns = [
    { id: 'id', label: 'Question Number' },
    { id: 'name', label: 'Question Name' },
    { id: 'questionText', label: 'Question' },
    { id: 'checkbox', label: 'Add to Unlimited Questions', render: (row) => <Checkbox checked={checkedRows[row.id]} onChange={(event) => handleCheckboxClick(event, row)} /> }
  ];

  useEffect(() => {
    const loadItemsAndQuestions = async () => {
      try {
        const encodedQuestionName = encodeURIComponent(questionName);
        const questionResponse = await getQuestions(page, rowsPerPage, subjectIdRequired, encodedQuestionName, "APPROVED", subjectId);
        const unlimitedQuestionResponse = await getUnlimitedQuestions(0,10,subjectId);
        if (questionResponse.status === 200 && unlimitedQuestionResponse.status === 200) {
          const questionTableData = questionResponse.data;
          const unlimitedQuestionData = unlimitedQuestionResponse.data
          console.log("question",questionTableData)
          console.log("unlimited",unlimitedQuestionData)
          const questionsData = unlimitedQuestionData.map(question => question.mcqQuestion.id);
          const checkedRowsData = {};
          questionTableData.forEach(item => {
            checkedRowsData[item.id] = questionsData.includes(item.id);
          });
          setData(questionTableData)
          setUnlimitedQuestions(unlimitedQuestionData)
          setCheckedRows(checkedRowsData);
        }
        else {
          console.log("Failed to load items or questions. Status:", questionResponse.status, unlimitedQuestionResponse.status);
        }
      } catch (error) {
        console.log("Error:", error);
      }
    };
  
    loadItemsAndQuestions();
  }, [page, rowsPerPage, subjectIdRequired, subjectId, paperId]);
  
  

  const handleCheckboxClick = async (event, row) => {
    const checked = event.target.checked;
    setCheckedRows(prevState => ({
      ...prevState,
      [row.id]: checked
    }));
    try {
      if (checked) {
        const question = {
            subject : {
                id : subjectId
            },
            mcqQuestion : {
                id : row.id
            },
            order : 1,
            active : "true"
           
        };
        await createUnlimitedQuestions(question);
      } else {
       const matchedQuestion = unlimitedQuestions.find(question => question.mcqQuestion.id === row.id);
       if (matchedQuestion) {
        await deleteUnlimitedQuestions(matchedQuestion.id);
      } else {
        console.log("can't delete, no matched question found");
      }
    }
     
    const updatedUnlimitedQuestions = await getUnlimitedQuestions(0, 10, 10);
    setUnlimitedQuestions(updatedUnlimitedQuestions.data);
         
      
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const totalRows = data.length;

  useEffect(() => {
        
    const dataRows = data.map(question => ({
      id: question.id,
      name: question.question.name,
      questionText: question.question.questionText
    }));
    
    setTableData(dataRows);
  }, [data]);
  
  return (
    <>
      <div>
        <div>Add Unlimited Questions</div>
        <ReusableTable
          columns={columns}
          data={tableData}
          checkedRows={checkedRows}
          handleCheckboxClick={handleCheckboxClick}
          page={page}
          rowsPerPage={rowsPerPage}
          count={totalRows}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
}

export default AddUnlimitedQuestion;