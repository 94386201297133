import React, { useState } from 'react';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { loginUser, verifyOTP } from '../../services/auth';
import LoginForm from '../Layout/LoginForm';
import { setUser } from '../../store/actions/userActions';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => dispatch(setUser(user)),
  }
}

function Login({ setUser, onClose }) {
  const navigate = useNavigate();
  const [showOTPDialog, setShowOTPDialog] = useState(false);
  const [otp, setOTP] = useState('');

  const login = async (username, password) => {
    try {
      const { data: user } = await loginUser(username, password);
      console.log('Login successful');
      console.log(user);
      const roles = user.roles;
      const isAdmin = roles.includes('ADMIN');
      const isSuperAdmin = roles.includes('SUPER_ADMIN');
      const isTyper = roles.includes('TYPER');
      if (isAdmin || isSuperAdmin || isTyper) {
        console.log("this is an admin or super admin or typer");
        setShowOTPDialog(true);
      } else {
        console.log("this is not an admin");
        setUser(user);
        navigate('/dashboard');
      }
    } catch (e) {
      console.error('Login error', e);
      console.log('Error response:', e.response);
    }
  };

  const handleOTPVerification = async () => {
    try {
      const verifedOTP ={
        otp:otp
      }
      const otpResponse = await verifyOTP(verifedOTP);
      if (otpResponse.status === 200) {
        console.log('OTP verification successful');
        const user = otpResponse.data;
        const roles = user.roles;
        const isAdmin = roles.includes('ADMIN');
        const isSuperAdmin = roles.includes('SUPER_ADMIN');
        const isTyper = roles.includes('TYPER');
        if (isAdmin || isSuperAdmin) {
          navigate('/dashboard');
        } else {
          navigate('/typerDashboard');
        }
      } else {
        console.error('Invalid OTP');
      }
    } catch (error) {
      console.error('OTP verification error:', error);
    }
  };

  const handleCloseOTPDialog = () => {
    setShowOTPDialog(false);
    setOTP(''); 
  };

  return (
    <div>
      <LoginForm onClose={onClose} onLogin={login} />
      <Dialog open={showOTPDialog} onClose={handleCloseOTPDialog}>
        <DialogTitle>Enter OTP</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="OTP"
            fullWidth
            value={otp}
            onChange={(e) => setOTP(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOTPDialog}>Cancel</Button>
          <Button onClick={handleOTPVerification} color="primary">Verify</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default connect(null, mapDispatchToProps)(Login);
