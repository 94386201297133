import React, { useRef, useState } from 'react'
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $insertNodeToNearestRoot } from "@lexical/utils"
import { COMMAND_PRIORITY_EDITOR, createCommand } from "lexical"
import { useEffect } from "react"
import { CircularProgress } from "@mui/material"
// import { fetchFile, toBlobURL } from '@ffmpeg/util';
// import { FFmpeg } from '@ffmpeg/ffmpeg';

import { $createVideoNode, VideoNode } from '../../nodes/VideoNode'
import { DialogButtonsList, DialogActions } from '../../ui/Dialog'
import Button from "../../ui/Button"

import landscapeImage from "../../images/landscape.jpg"
import yellowFlowerImage from "../../images/yellow-flower.jpg"

import FileInput from "../../ui/FileInput"
import TextInput from "../../ui/TextInput"
import { uploadVideo } from '../../../../../../services/file/video'

export const INSERT_VIDEO_COMMAND = createCommand("INSERT_VIDEO_COMMAND")

function VideoPlugin() {
    const [editor] = useLexicalComposerContext()

    useEffect(() => {

        if (!editor.hasNodes([VideoNode])) {
            throw new Error("VideoPlugin: VideoNode not registered on editor")
          }
    
      return editor.registerCommand(
        INSERT_VIDEO_COMMAND,
        payload => {
          const videoNode = $createVideoNode(payload)
          $insertNodeToNearestRoot(videoNode)
  
          return true
        },
        COMMAND_PRIORITY_EDITOR
      )
    }, [editor])
    

  return null;
}

export default VideoPlugin

export function InsertImageUriDialogBody({ onClick }) {
    const [src, setSrc] = useState("")
    const [altText, setAltText] = useState("")
  
    const isDisabled = src === ""
  
    return (
      <>
        <TextInput
          label="Image URL"
          placeholder="i.e. https://source.unsplash.com/random"
          onChange={setSrc}
          value={src}
          data-test-id="image-modal-url-input"
        />
        <TextInput
          label="Alt Text"
          placeholder="Random unsplash image"
          onChange={setAltText}
          value={altText}
          data-test-id="image-modal-alt-text-input"
        />
        <DialogActions>
          <Button
            data-test-id="image-modal-confirm-btn"
            disabled={isDisabled}
            onClick={() => onClick({ altText, src })}
          >
            Confirm
          </Button>
        </DialogActions>
      </>
    )
  }
  
  export function InsertImageUploadedDialogBody({ onClick , setLoading, loading, progress}) {
    const [src, setSrc] = useState("")
    const [altText, setAltText] = useState("")
    const [selectedFile, setSelectedFile] = useState(null)
    const [videoInfo, setVideoInfo] = useState({})
  
    const isDisabled = src === ""

    // useEffect(() => {
    //   const videoElement = document.getElementById('videoElement');
    //       videoElement.src = "";
    // }, [])
    
  
    // const loadImage = files => {
    //   console.log("dddddddd")
    //   console.log(files)
    //   const reader = new FileReader()
    //   reader.onerror  = function(e) {
    //     console.log("errrrrrrrrrr")
    //     console.log(reader.error)
    //   }
    //   reader.onload = function(e) {
    //     console.log("eeeeeeeeeeeeee")
    //     if (typeof reader.result === "string") {
    //       console.log(reader)
    //       setSrc(reader.result)

    //       const videoElement = document.getElementById('videoElement');
    //       videoElement.src = e.target.result;
    //       console.log("dddddddddddddddddddddddddddddddddddddddddddd")
    //       console.log(videoElement.src)
    //       videoElement.addEventListener('loadedmetadata', function () {
    //         const width = videoElement.videoWidth;
    //         const height = videoElement.videoHeight;
    //         const duration = videoElement.duration;
    //         // console.log('Video width:', width);
    //         // console.log('Video height:', height);
    //         // console.log('Video duration:', duration);
    //         setVideoInfo({width, height, duration})
    //       });
    //       setLoading(false)
    //     }else{
    //       console.log("dddddddddddddddddd")
    //     }

    //     return ""
    //   }

    //   if (files !== null) {
    //     //if not a video file return
    //     if (!files[0].type.includes('video')) {
    //       return
    //     }
    //     setLoading(true)
    //     reader.readAsDataURL(files[0])
    //     setSelectedFile(files[0])
    //     // console.log(files[0])
    //   }
    // }

    const loadImage = files => {
      if (files !== null) {
        // If not a video file, return
        if (!files[0].type.includes('video')) {
          return;
        }
        setLoading(true);
    
        const file = files[0];
        const videoElement = document.getElementById('videoElement');
        // console.log(file)
        
        // Use URL.createObjectURL instead of FileReader
        const videoURL = URL.createObjectURL(file);
        videoElement.src = videoURL;
        // console.log({videoURL})
        setSrc(videoURL)
    
        videoElement.addEventListener('loadedmetadata', function() {
          // console.log("bbbbbbbbbbbbbbbbbbbbbbbbbbbbbb")
          const width = videoElement.videoWidth;
          const height = videoElement.videoHeight;
          const duration = videoElement.duration;
          // console.log({ width, height, duration })
    
          setVideoInfo({ width, height, duration });
          setLoading(false);
        });
    
        setSelectedFile(file);
      }
    }
  
    return (
      <>
        {loading && <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', alignContent: 'center', marginBottom: 8 }}>
          <CircularProgress />
        </div>}

        {progress > 0 ? (
          <div style={{ width: '100%', height: '30px', backgroundColor: '#e0e0df' }}>
            <div
              style={{
                width: `${progress * 100}%`,
                height: '100%',
                backgroundColor: '#76c7c0',
                transition: 'width 0.1s linear',
              }}
            ></div>
          </div>) : null
        }
  
        <FileInput
          label="Image Upload"
          onChange={loadImage}
          accept="video/*"
          data-test-id="image-modal-file-upload"
        />
        <TextInput
          label="Alt Text"
          placeholder="Descriptive alternative text"
          onChange={setAltText}
          value={altText}
          data-test-id="image-modal-alt-text-input"
        />
        <video id="videoElement" style={{
          maxWidth : '80%',
          margin: 'auto',
           display : src ? 'block' : 'none'}} controls = {true}></video>
        <DialogActions>
          <Button
            data-test-id="image-modal-file-upload-btn"
            disabled={isDisabled}
            onClick={() => onClick({ altText, src, selectedFile, videoInfo })}
          >
            Confirm
          </Button>
        </DialogActions>
      </>
    )
  }

  // const ffmpeg = new FFmpeg({ log: true });

export function InsertVideoDialog({ activeEditor, onClose }) {
    const [mode, setMode] = useState(null)
    const hasModifier = useRef(false)
    const [loading, setLoading] = useState(false)

  // const [videoFile, setVideoFile] = useState(null);
  // const [compressedVideo, setCompressedVideo] = useState(null);
  const [progress, setProgress] = useState(0)

  
  
    useEffect(() => {
      hasModifier.current = false
      const handler = e => {
        hasModifier.current = e.altKey
      }
      document.addEventListener("keydown", handler)
      return () => {
        document.removeEventListener("keydown", handler)
      }
    }, [activeEditor])
  

    // const loadFFmpeg = async () => {
    //   const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd'
    //   if (!ffmpeg.loaded) {
    //     await ffmpeg.load({
    //       coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
    //       wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
    //       workerURL: await toBlobURL(`${baseURL}/ffmpeg-core.worker.js`, 'text/javascript'),
    //   });
    //   }
    // };

    // const readFileAsArrayBuffer = (file) => {
    //   return new Promise((resolve, reject) => {
    //     const reader = new FileReader();
    //     reader.onload = () => resolve(reader.result);
    //     reader.onerror = reject;
    //     reader.readAsArrayBuffer(file);
    //   });
    // };
    
    // const compressVideo = async (selectedFile) => {
    //   await loadFFmpeg();

    //   console.log(`Original file size: ${(selectedFile.size / 1024 / 1024).toFixed(2)} MB`);
  
    //   const fileData = await readFileAsArrayBuffer(selectedFile);
    //   // ffmpeg.writeFile('writeFile', 'input.mp4', new Uint8Array(fileData));
    //   await ffmpeg.writeFile("inputVideoPath", new Uint8Array(fileData));
  
    //   await ffmpeg.exec(['-i', "inputVideoPath", '-vcodec', 'libx264', '-crf', '28', "outputVideoPath"]);

  
    //   // const data = ffmpeg.writeFile('readFile', 'output.mp4');
    //   const data = await ffmpeg.readFile("outputVideoPath");
    //   const videoBlob = new Blob([data.buffer], { type: 'video/mp4' });
    //   console.log(`Compressed file size: ${(videoBlob.size / 1024 / 1024).toFixed(2)} MB`);

    //   return URL.createObjectURL(videoBlob);
  
    // };

  //   const transcode = async (selectedFile) => {
  //     await loadFFmpeg();
  //     // const fechedFile = await fetchFile('https://raw.githubusercontent.com/ffmpegwasm/testdata/master/Big_Buck_Bunny_180_10s.webm')
  //     // console.log(fechedFile)
  //     const fechedFile1 = await fetchFile(selectedFile)
  //     // console.log(fechedFile1)
  //     // const fileData = await readFileAsArrayBuffer(selectedFile);
  //     // const fechedFile3 = new Uint8Array(fileData)
  //     // console.log(fechedFile3)
  //     await ffmpeg.writeFile('input.webm', fechedFile1);
  //     // await ffmpeg.exec(['-i', 'input.webm', 'output.mp4']);
  //     await ffmpeg.exec(['-i', 'input.webm','-vcodec', 'libx264', '-crf', '28', 'output.mp4']);
  //     const data = await ffmpeg.readFile('output.mp4');
  //     const videoBlob =  new Blob([data.buffer], {type: 'video/mp4'});
  //     console.log(`Compressed file size: ${(videoBlob.size / 1024 / 1024).toFixed(2)} MB`);
  //     return videoBlob;
  // }


  const onUploadProgress = (progressEvent) => {
    const {progress, total} = progressEvent;
    setProgress(progress);
  }

    const onClick = async payload => {
      
      const { src, selectedFile } = payload
      if (selectedFile) {
        setLoading(true)
        // if(window?.ReactNativeWebView){
        //   const stringifyFile = JSON.stringify(selectedFile)
        //   payload.videoInfo.type1 = stringifyFile
        //   activeEditor.dispatchCommand(INSERT_VIDEO_COMMAND, payload)
        //   setLoading(false)
        //   onClose()
        //   return
        // }
      

        try {
          // const compressedVideo = await transcode(selectedFile);
          const response = await uploadVideo(selectedFile, onUploadProgress)
          const { data, status } = response
          if (status === 200) {
            payload.src = data
            // window.alert(data);
            // console.log("oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooyyyyyyyyyyyyyyyyyyyyy")
            // console.log(data)
            payload.videoInfo.type1 = selectedFile.type

            activeEditor.dispatchCommand(INSERT_VIDEO_COMMAND, payload)
          }else{
            // window.alert("ddddddddddddd " + status);
            // window.alert("ddddddddddddd " + data);
          }
          
        } catch (e) {
          setLoading(false)
          // window.alert(e);
          console.log(e)
          return;
        } finally {
          // window.alert("yyyyyyyyyyy");
          setLoading(false)
          onClose()
        }
      }else{
        //show error message to select video file


      }
    }
  
    return (
      <>
        {!mode && (
          <DialogButtonsList>
            {/* <Button
              data-test-id="image-modal-option-sample"
              onClick={() =>
                onClick(
                  hasModifier.current
                    ? {
                        altText:
                          "Daylight fir trees forest glacier green high ice landscape",
                        src: landscapeImage
                      }
                    : {
                        altText: "Yellow flower in tilt shift lens",
                        src: yellowFlowerImage
                      }
                )
              }
            >
              Sample
            </Button>
            <Button
              data-test-id="image-modal-option-url"
              onClick={() => setMode("url")}
            >
              URL
            </Button> */}
            <Button
              data-test-id="image-modal-option-file"
              onClick={() => setMode("file")}
            >
              File
            </Button>
          </DialogButtonsList>
        )}
        {mode === "url" && <InsertImageUriDialogBody onClick={onClick} />}
        {mode === "file" && <InsertImageUploadedDialogBody onClick={onClick} loading ={loading} setLoading={setLoading} 
        // onUploadProgress = {onUploadProgress}
        progress = {progress}
        />}
      </>
    )
  }