import React, { useEffect, useState } from 'react';
import { Container, Grid, Card, CardContent, Button, Typography, Menu, MenuItem, IconButton } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
 
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import AddQuiz from './AddQuiz';
  
function QuizPage() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedQuiz, setSelectedQuiz] = useState(null);
    const [quizs, setQuizs] = useState([]);  
    const { subjectId } = useParams();
    const navigate = useNavigate();
    const [isOpenAddQuizForm,setIsOpenAddQuizForm]=useState()

    useEffect(() => {
        loadQuizPapers();
        const quizArray = [{date:"2023/07/01",
                        startTime:'1pm',
                        endTime:"1.20pm"}]
        setQuizs(quizArray)
    }, [subjectId]);

    const openQuiz = (quiz) => {
        setSelectedQuiz(quiz);
        console.log(`Opening paper - ${quiz.date}`);
        console.log(`Paper id  - ${quiz.id}`);
        navigate(`/dashboard/syllabuses/selectedSyllabus/quizPaper/${subjectId}/${quiz.id}`, {
            state: {
                subjectId: subjectId
            },
        });
        
    };

    const loadQuizPapers = async () => {
        // try {
        //     // const response = await getAllQuizPapers(subjectId);
        //     setQuizs(response.data);
        // } catch (error) {
        //     console.log(error);
        // }
    };

    const handleMenuOpen = (event, paper) => {
        setAnchorEl(event.currentTarget);
        setSelectedQuiz(paper);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedQuiz(null);
    };

    const handleDeleteQuiz = async (paperId) => {
        // try {
        //     // await deleteQuizPaper(paperId);
        //     console.log("Deleted paper:", paperId);
        // } catch (error) {
        //     console.log(error);
        // }
        loadQuizPapers();
        handleMenuClose();
    };

    const handleEditQuiz = () => {
        handleMenuClose();  
        setIsOpenAddQuizForm(true);  
    };
    
    const addQuizButtonClick = () => {
        setIsOpenAddQuizForm(true);
    };
    

    const handleCloseAddQuizForm = () => {
        setIsOpenAddQuizForm(false); 
    };
    return (
        <Container>
            <Typography variant="h4" gutterBottom style={{ fontSize: "30px" }}>
                Quiz Papers
            </Typography>
            <Button onClick={addQuizButtonClick} style={{ backgroundColor: '#87BCDE', color: 'white', margin: '20px' }}>
                Add New Quiz
            </Button>
           
            <Grid container spacing={2}>
                {quizs.map((quiz, index) => (
                    <Grid item xs={3} key={quiz.id}>
                        <Card>
                            <CardContent style={{ position: 'relative' }}>
                                <Typography variant="h6" component="h2" gutterBottom>
                                    
                                    {`Quiz - ${quiz.date}`}
                                </Typography>
                                <Typography variant="h6" component="h2" gutterBottom>
                                    
                                    {`Start: ${quiz.startTime}`}
                                </Typography>
                                <Typography variant="h6" component="h2" gutterBottom>
                                    
                                    {`End: ${quiz.endTime}`}
                                </Typography>
                                <IconButton onClick={(event) => handleMenuOpen(event, quiz)} style={{ position: 'absolute', top: '8px', right: '8px' }}>
                                    <MoreHorizIcon />
                                </IconButton>
                                <Button variant="contained" onClick={() => openQuiz(quiz)} style={{ marginBottom: '40px' }}>
                                    Open Quiz
                                </Button>
                            </CardContent>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            >
                                <MenuItem onClick={() => handleEditQuiz()}>
                                    Edit
                                </MenuItem> 
                                <MenuItem onClick={() => handleDeleteQuiz(quiz.id)}>
                                    Delete
                                </MenuItem>

                            </Menu>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <AddQuiz open={isOpenAddQuizForm} onClose={handleCloseAddQuizForm} subjectId={subjectId} editQuiz={selectedQuiz} />
        </Container>
    );
}

export default QuizPage;
