import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, CircularProgress } from '@mui/material';
import { createGrade } from '../../../services/subjects/grades';

function AddGradeForm({ open, onClose, languages, loadGrades, syllabusId }) {
  const initialGradeState = {
    gradeNames: {},
    description: '',
    sortingOrder : '',  
  };

  const [formState, setFormState] = useState({ ...initialGradeState });
  const [loading, setLoading] = useState(false); // Loading state

  const handleGradeNameChange = (languageCode, value) => {
    setFormState((prevState) => ({
      ...prevState,
      gradeNames: {
        ...prevState.gradeNames,
        [languageCode]: value,
      },
    }));
  };

  const handleDescriptionChange = (value) => {
    setFormState((prevState) => ({
      ...prevState,
      description: value,
    }));
  };

  const handleOrderChange = (value) => {
    setFormState((prevState) => ({
      ...prevState,
      order: value,
    }));
  };

  const resetForm = () => {
    setFormState({ ...initialGradeState });
  };

  const addGrade = async () => {
    setLoading(true); // Set loading to true while submitting form

    const nameTags = languages.map((language) => ({
      language: language,
      name: formState.gradeNames[language.code],
    }));

    try {
      const newGrade = {
        description: formState.description,
        sortingOrder: formState.order,  
        nameTags: nameTags,
      };

      console.log('New Grade:', newGrade);

      const response = await createGrade(syllabusId, newGrade);
      console.log('API Response:', response);

      // Reset form after successfully adding a grade
      resetForm();

      onClose();
      loadGrades();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Set loading to false after form submission completes
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New Grade</DialogTitle>
      <DialogContent>
        {languages.map((language) => (
          <TextField
            key={language.code}
            label={`${language.name} Name`}
            value={formState.gradeNames[language.code] || ''}
            onChange={(e) => handleGradeNameChange(language.code, e.target.value)}
            fullWidth
            margin="normal"
          />
        ))}
        <TextField
          label="Description"
          value={formState.description}
          onChange={(e) => handleDescriptionChange(e.target.value)}
          fullWidth
          margin="normal"
        />
        {/* Add Order TextField */}
        <TextField
          label="Order"
          fullWidth
          value={formState.order}
          onChange={(e) => handleOrderChange(e.target.value)}
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={addGrade} disabled={loading || Object.keys(formState.gradeNames).length !== languages.length}>
          {loading ? <CircularProgress size={24} /> : 'Add Grade'} {/* Show loading indicator if loading */}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddGradeForm;
