import api from "..";
import Env from '../../config/environments';
import { ApiEndpoint, Method } from "../../constants";

const params = {};
const body = {};
export const getUsers = (offset = 0, pageSize = 10, role = "", isApproved = false, profileChangeUpdate = false, searchTerm = "") => {
    const params = {
        offset,
        pageSize,
   
      };
     
    if (isApproved) {
      params.isApproved = isApproved;
    }

    if (role) {
      params.role = role;
    }
 
    if (profileChangeUpdate) {
      params.profileChangeUpdate = profileChangeUpdate;
    }

    if (/^\d+$/.test(searchTerm)) {
      params.mobileNo = searchTerm;
    } else {
      params.name = searchTerm;
    }


    return api(`${ApiEndpoint.USER}/users`, Method.GET, params, Env.BASE_URL, body);
};

export const editUser = (userId,body) => {
 
  return api(`${ApiEndpoint.USER}/${userId}`, Method.PUT, params, Env.BASE_URL, body);
};


 
