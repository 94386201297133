import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Stack,
  TablePagination,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddTeacherForm from './AddTeacherForm';
import TeacherDetails from '../../screens/TeacherDialog/TeacherDetails';
import { editUser, getUsers } from '../../services/user/user';
import TeacherCard from './TeacherCard';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { South } from '@mui/icons-material';
const containerStyle = {
  display: 'flex',
  height: '80px',
  width: '1300px',
};

const inputStyle = {
  backgroundColor: '#fff',
  textAlign: 'left',
  color: '#8888',
  border: 'none',
  width: '600px',
  padding: '10px 20px',
  height: '40px',
  marginLeft: '10px',
};

const buttonStyle = {
  backgroundColor: '#203C7E',
  color: '#fff',
  padding: '10px 20px',
  borderRadius: '4px',
  border: 'none',
  cursor: 'pointer',
  height: '64px',
  fontSize: '26px',
  marginRight: '10px',
};

const gridStyle = {
  padding: '0px 0px'
};
function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        // disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

function Teacher() {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [isAddTeacherFormOpen, setAddTeacherFormOpen] = useState(false);
  const [isTeacherDetailsOpen, setTeacherDetailsOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [teachers, setTeachers] = useState([]);
  const [teacherId, setTeacherId] = useState();
  const [status,setStatus]= useState();
  const [isgetNewProfileURLs,setNewProfileURLs]=useState(false)
  const [nameOrMobileNo,  setNameOrMobileNo] = useState(); 
  

  const handleOpenMenu = (event, teacher) => {
    setSelectedTeacher(teacher);
    setAnchorElUser(event.currentTarget);
    if (teacher.myTeachers && teacher.myTeachers.length > 0) {
      setTeacherId(teacher.myTeachers[0].id);
    } else {
      setTeacherId(null);
    }
  };

  const handleCloseMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenAddTeacherForm = () => {
    setAddTeacherFormOpen(true);
  };

  const handleCloseAddTeacherForm = () => {
    setAddTeacherFormOpen(false);
  };

  const handleOpenTeacherDetails = () => {
    setTeacherDetailsOpen(true);
  };

  const handleCloseTeacherDetails = () => {
    setTeacherDetailsOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  
  const handleMenuClick = (option) => {
    if (option === 'Details') {
      handleOpenTeacherDetails();
    }
    handleCloseMenu();
  };

  const loadUsers = async () => {
    try {
      const users = await getUsers(page, rowsPerPage, "TEACHER", status, isgetNewProfileURLs, nameOrMobileNo);
      setTeachers(users.data);
    }
    catch (error) {
      console.log(error)
    }

  };

  const handleSave = (updatedTeacher) => {
    setTeachers((prevTeachers) =>
      prevTeachers.map((teacher) =>
        teacher.id === updatedTeacher.id ? updatedTeacher : teacher
      )
    );
  };

  useEffect(() => {
    loadUsers();
  }, [page,rowsPerPage]);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handaleSerchTermChange = (event) => {
    const trimmedValue = event.target.value.trim();
    setNameOrMobileNo(trimmedValue);
  };

  const handleSearchButtonClick = () => {
    loadUsers();
    setPage(0);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearchButtonClick();
    }
  };
    
  return (
    <Container>
      <div>Teachers</div>
      <Box mb={4} >
        <Box mt={4}  mb={2} sx={containerStyle}>
          <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={nameOrMobileNo}
              onChange={handaleSerchTermChange}
              onKeyPress={handleKeyPress} 
              style={{ marginBottom: '10px', marginRight: '10px' }}
            />
            <FormControl   size="small" style={{ marginRight: '10px', minWidth: 120 }}>
            <InputLabel id="sort-by-label">Sort By</InputLabel> 
                <Select
                  labelId="sort-by-label"
                  id="sort-by"
                  value={status}
                  label="Sort By"
                  onChange={handleStatusChange}
                >
                  <MenuItem value="true">Approved</MenuItem>
                  <MenuItem value="false">Rejected</MenuItem>
                </Select>
          </FormControl> 
          <FormControlLabel
            control={
            <Checkbox
              checked={isgetNewProfileURLs}
              onChange={(e) => setNewProfileURLs(e.target.checked)}
            />
            }
            label="Update Profile Pictures"
            style={{ marginRight: '10px', marginBottom: '0px' }}
          />
          <Button onClick={handleSearchButtonClick}  variant="contained">
            <Typography>Search</Typography>
            <IconButton>
              <SearchIcon />
            </IconButton>
          </Button>
        </Box>
         
      </Box>  
        <Grid container spacing={2} sx={gridStyle}>
          {teachers.map((teacher,index) => (
            <Grid item xs={12} sm={6} md={4} key={teacher.id}>
              <TeacherCard
                teacher={teacher} 
                key={index}
                handleCloseMenu={handleCloseMenu}
                handleOpenMenu={handleOpenMenu}
                handleOpenAddTeacherForm={handleOpenAddTeacherForm}
                handleMenuClick={handleMenuClick}
                anchorElUser={anchorElUser}
              />
               
            </Grid>
          ))}
        </Grid>
        <TablePagination
          rowsPerPageOptions={[6, 12, 24, { label: 'All', value: -1 }]}
          colSpan={3}
          count={-1}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
       
      <AddTeacherForm open={isAddTeacherFormOpen} onClose={handleCloseAddTeacherForm} />
      {selectedTeacher && (
        <TeacherDetails
          open={isTeacherDetailsOpen}
          onClose={handleCloseTeacherDetails}
          teacher={selectedTeacher}
          onSave={handleSave}
          teacherId={teacherId}
          loadTeachers={loadUsers}
           
        />
      )}
    </Container>
  );
}

export default Teacher;
