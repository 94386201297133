import React, { useEffect, useState } from 'react';
import {
  Box, 
  Grid,
  IconButton, 
  Menu,
  MenuItem,
  Paper, 
  Tooltip,
  Typography
} from '@mui/material';
 
import MoreVertIcon from '@mui/icons-material/MoreVert';
 
export default function TeacherCard({teacher,handleMenuClick,handleCloseMenu,handleOpenMenu,anchorElUser}) {
    
      
      const imageStyle = {
        borderRadius: '50%',
        width: '60px',
        height: '60px',
        margin: '10px 20px',
        padding: '0 10px'
      };
      
      const nameStyle = {
        fontSize: '18px',
      };
      
      const phoneStyle = {
        fontSize: '12px',
      };
      
      const subjectsStyle = {
        fontSize: '12px',
        backgroundColor: '#A2AEBB66',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none',
        color: 'black',
        padding: '2px 10px',
        textAlign: 'center',
        textDecoration: 'none',
        display: 'inline-block',
        margin: '4px 2px',
        borderRadius: '200px',
      };
      
      const MoreVertIconStyle = {
        marginLeft: '90px',
      };
      
       
      const menuStyle = {
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      };
   
  return (
    
      <Grid item sm={12} md={6} lg={4}  >
            <Paper
              style={{
                width: '390px',
                height: '100px',
                cursor: 'pointer',
                padding: '0 0px',
              }}
            >
              <div style={{ display: 'flex' }}>
                <img src={teacher.image} alt={teacher.title} align="center" style={imageStyle} />
                <div margin='10px 30px' padding='0 10px'>
                  <Typography variant="subtitle1" align="left" style={nameStyle}>
                    {teacher.firstName} {teacher.lastName}
                  </Typography>
                  <Typography align="left" style={phoneStyle}>
                    {teacher.mobileNo}
                  </Typography>
                  <Typography align="left" style={phoneStyle}>
                    {teacher.email}
                  </Typography>
                </div>
                <Box sx={{ flexGrow: 0 }}>
                  <Tooltip title="more">
                    <IconButton onClick={(event) => handleOpenMenu(event, teacher)} sx={{ p: 0 }}>
                      <MoreVertIcon style={MoreVertIconStyle} />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    boxShadow='2'
                    sx={{ mt: '45px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseMenu}
                  >
                    <MenuItem onClick={() => handleMenuClick('Details')}>
                      <Typography textAlign="center">Details</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuClick('Remove')}>
                      <Typography textAlign="center">Remove</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuClick('Classes')}>
                      <Typography textAlign="center">Classes</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuClick('Payments')}>
                      <Typography textAlign="center">Payments</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuClick('Edit')}>
                      <Typography textAlign="center">Edit</Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              </div>
            </Paper>
          </Grid>
    
  )
}
