import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
} from '@mui/material';
import { createSubjectTopic } from '../../services/subjects/subjectTopics';

function AddSubjectTopic({ open, onClose, subjectsId, setSubjectTopics, syllabusId, parentTopics, loadSubjectTopics }) {
  const [newTopicName, setNewTopicName] = useState('');
  const [newTopicDescription, setNewTopicDescription] = useState('');
  const [order, setOrder] = useState('');
  const [isLabelChecked, setIsLabelChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedParentTopic, setSelectedParentTopic] = useState(null);
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [questionsAtThisLevel, setQuestionsAtThisLevel] = useState(false);

  useEffect(() => {
    console.log('parentTopics:', parentTopics);
  }, [parentTopics]);

  const handleSave = async () => {
    try {
      setLoading(true); // Set loading state to true when saving data

      if (newTopicName !== '' && newTopicDescription !== '') {
        const newSubjectTopic = {
          name: newTopicName,
          description: newTopicDescription,
          isLabelChecked,
          selectedOption,
          parentTopic: selectedParentTopic, 
          sortingOrder:order ,
          questionsAtThisLevel
        };

        console.log("Sending request with payload:", newSubjectTopic);

        const response = await createSubjectTopic(syllabusId, subjectsId, newSubjectTopic);
        setSubjectTopics((prevItems) => [...prevItems, response.data]);
        onClose();
        loadSubjectTopics();
      } else {
        console.error("Invalid data for name or description");
      }
    } catch (error) {
      console.error('Error adding subjectTopic:', error);
    } finally {
      setLoading(false); // Set loading state to false after saving data
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New Subject Topic</DialogTitle>
      <DialogContent>
        {loading && <CircularProgress />} {/* Display loading indicator */}
        <TextField
          label="Topic Name"
          fullWidth
          value={newTopicName}
          onChange={(e) => setNewTopicName(e.target.value)}
          style={{ marginBottom: '0.5rem' }} // Adjust margin-bottom for main value
        />
        <TextField
          label="Topic Description"
          fullWidth
          multiline
          rows={4}
          value={newTopicDescription}
          onChange={(e) => setNewTopicDescription(e.target.value)}
          style={{ marginBottom: '0.5rem' }} // Adjust margin-bottom for main value
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isLabelChecked}
              onChange={() => setIsLabelChecked(!isLabelChecked)}
              color="primary"
            />
          }
          label="Add Parent topic"
          style={{ marginBottom: '0.5rem' }} // Adjust margin-bottom for main value
        />
        {isLabelChecked && (
          <FormControl fullWidth style={{ marginBottom: '0.5rem' }}> {/* Adjust margin-bottom for main value */}
            <InputLabel id="select-label">Select Parent Topic</InputLabel>
            <Select
              labelId="select-label"
              value={selectedParentTopic ? selectedParentTopic.id : ''}
              onChange={(e) => {
                const selectedTopic = parentTopics.find(topic => topic.id === e.target.value);
                setSelectedParentTopic(selectedTopic);
              }}
            >
              <MenuItem value="">No Parent Topic</MenuItem>
              {parentTopics.map((topic) => (
                <MenuItem key={topic.id} value={topic.id}>
                  {topic.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <TextField
          label="Order"
          fullWidth
          value={order}
          onChange={(e) => setOrder(e.target.value)}
        />
         <FormControlLabel
          control={
            <Checkbox
              checked={questionsAtThisLevel}
              onChange={() => setQuestionsAtThisLevel(!questionsAtThisLevel)}
              color="primary"
            />
          }
          label="Questions at this level"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Save'} {/* Display loading indicator or "Save" text */}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddSubjectTopic;
