import api from ".."
import Env from '../../config/environments';
import { ApiEndpoint, Method } from "../../constants"

const params = {};
const body = {};

export const getALLGrades = (syllabusId) => {

    return api(`${ApiEndpoint.GRADES}/syllabus/${syllabusId}`, Method.GET, params, Env.BASE_URL, body)

}

export const createGrade = (syllabusId, body) => {

    return api(`${ApiEndpoint.GRADES}/syllabus/${syllabusId} `, Method.POST, params, Env.BASE_URL, body)

}

export const updateGrade = (syllabusId,subjectId,body) => {

    return api(`${ApiEndpoint.GRADES}/syllabus/${syllabusId}/${subjectId}`, Method.PUT, params, Env.BASE_URL, body)

}

export const deleteGrade = (syllabusId,gradeId) => {

    return api(`${ApiEndpoint.GRADES}/syllabus/${syllabusId}/${gradeId}`, Method.DELETE, params, Env.BASE_URL, body)

}