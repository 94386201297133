// Action types
export const SET_USER = 'SET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';

// Action creators
export const setUser = (user) => {
    return {
        type: SET_USER,
        payload: user
    };
};

export const updateUser = (updatedUser) => {
    return {
        type: UPDATE_USER,
        payload: updatedUser
    };
};

export const deleteUser = () => {
    return {
        type: DELETE_USER
    };
};
