import api from ".."
import Env from '../../config/environments';
import { ApiEndpoint, Method } from "../../constants"

const params = {};
const body = {};

export const createSubjectTopic = (SyllabusId,subjectId,body) => {

    return api(`${ApiEndpoint.SUBJECT_TOPIC}/${subjectId}`, Method.POST, params, Env.BASE_URL, body)

}

export const getSubjectTopics = (SyllabusId, subjectId, params) => {

    return api(`${ApiEndpoint.SUBJECT_TOPIC}/${subjectId}`, Method.GET, params, Env.BASE_URL, body)

}

export const getSubjectTopicsForSubject = (subjectId) => {
    return api(`${ApiEndpoint.SUBJECT_TOPIC}/${subjectId}`, Method.GET, params, Env.BASE_URL, body)

}
  
export const editSubjectTopic = (subjectId,id,body) => {
    return api(`${ApiEndpoint.SUBJECT_TOPIC}/${subjectId}/${id}`, Method.PUT, params, Env.BASE_URL, body)

}
export const deleteSubjectTopic = ( subjectId,id) => {
    return api(`${ApiEndpoint.SUBJECT_TOPIC}/${subjectId}/${id}`, Method.DELETE, params, Env.BASE_URL, body)

} 