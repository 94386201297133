import React from 'react'
import { Button, Grid } from '@mui/material'

function SubmitButton({onClick, editMode}) {
    return (
        <Grid container item xs={12} justifyContent="center" alignItems="center">
            <Button variant='contained' onClick={onClick}>
                {editMode ? "Update question" : "Add question"}
            </Button>
        </Grid>
    )
}

export default SubmitButton