
import { IconButton } from '@mui/material'
import React from 'react'

function ControlButton(props) {
    const { icon, controlName, inlineToggle, blockType } = props

    let active = props.currentStyle ? props.currentStyle.has(controlName.toUpperCase()) : null;
    let style = controlName.toUpperCase();
    if (controlName === 'bulletList' || controlName === 'numberList') {
        const blockTypeHere =
            controlName === 'bulletList' ? 'unordered-list-item' : ['ordered-list-item', 'pasted-list-item'];
        active = blockTypeHere === blockType || (controlName === 'numberList' && blockTypeHere.includes(blockType));
    }

    const onMouseDown = (e) => {
        e.preventDefault();
        inlineToggle(controlName.toUpperCase())
    }

    // console.log(controlName + " :" + active)

    return (
        <IconButton onMouseDown={onMouseDown} color={active ? 'primary' : 'none'}>
            {icon}
        </IconButton>
    )
}

export default ControlButton