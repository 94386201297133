import React, { useState } from 'react';
import { Button, Modal, Box, Typography, Snackbar } from '@mui/material';
import { createUnlimitedQuestions } from '../../services/subjects/unlimitedQuestions';
import MuiAlert from '@mui/material/Alert';

function MoveToUnlimitedQuestionModal({ open, handleClose, MovedQuestion }) {
    const [alertOpen, setAlertOpen] = useState(false);
    

    const handleMove = async () => {
        const newUnlimitedQuestion = {
            mcqQuestion: {
                id: MovedQuestion.id,
            },
            subject: {
                id: MovedQuestion.question.subjectId,
            },
            order: 1,
            active: true
        };
        console.log("Moving question:", newUnlimitedQuestion);
        const response = await createUnlimitedQuestions(newUnlimitedQuestion);
        console.log("Response:", response);
        setAlertOpen(true);
        handleClose();
    };

    const handleAlertClose = () => {
        setAlertOpen(false);
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Move to Unlimited Question Pool
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Are you sure you want to move this question to the unlimited question pool?
                    </Typography>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleClose} color="primary" sx={{ mr: 1 }}>
                            Cancel
                        </Button>
                        <Button onClick={handleMove} color="primary">
                            Move
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
                <MuiAlert elevation={6} variant="filled" onClose={handleAlertClose} severity="success">
                    Question moved to unlimited pool successfully!
                </MuiAlert>
            </Snackbar>
        </>
    );
}

export default MoveToUnlimitedQuestionModal;
