import React, { useEffect, useState } from 'react';
import { Container, Grid, Card, CardContent, Button, Typography, Menu, MenuItem, IconButton } from '@mui/material';
import AddPastPaper from './AddPastPaper';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { deleteMCQPastPaper, getAllMCQPastPapers } from '../../services/subjects/pastPapers';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ReadMoreIcon from '@mui/icons-material/ReadMore';

function PastPapers() {
    const [openAddPaperForm, setOpenAddPaperForm] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [papers, setPapers] = useState([]);  
    const { subjectId } = useParams();
    const [selectedPaper,setSelectedPaper] = useState(null)
    const navigate = useNavigate();

    useEffect(() => {
        loadPastpapers();
    }, [subjectId]);

    const openPaper = (paper) => {
        setSelectedPaper(paper);
        console.log(`Opening paper - ${paper.year}`);
        console.log(` paper id  - ${paper.id}`);
        navigate(`/dashboard/syllabuses/selectedSyllabus/questionsForPaper/${subjectId}/${paper.id}`, {
            state: {
                subjectId: subjectId,
                paperId: paper.id,  
            },
        });
    };
    

    const loadPastpapers = async () => {
        try {
            const response = await getAllMCQPastPapers(subjectId);
            setPapers(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const addPastpaerButtonClick = () => {
        console.log("add new subject form open");
        setOpenAddPaperForm(true);
    };

    const handleCloseAddPaperForm = () => {
        setOpenAddPaperForm(false);  
    };

    const handleMenuOpen = (event, paper) => {
        setAnchorEl(event.currentTarget);
        setSelectedYear(paper.year);
        setSelectedPaper(paper)
         
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedYear(null);
    };

    const handleDeletePaper = async (paperId) => {
        try {
            await deleteMCQPastPaper(paperId);
            console.log("deleted paper :", paperId);
        } catch (error) {
            console.log(error);
        }
        loadPastpapers();
        handleMenuClose();
    };

    const handleEditPaper = () => {
        console.log(selectedPaper); 
        setOpenAddPaperForm(true);  
        handleMenuClose();
    };
    

    return (
        <Container>
            <Typography variant="h4" gutterBottom style={{ fontSize: "30px" }}>
                Past Papers
            </Typography>
            <Button onClick={addPastpaerButtonClick} style={{ backgroundColor: '#87BCDE', color: 'white', margin: '20px' }}>
                Add Past Paper
            </Button>
            <Grid container spacing={2}>
                {papers.map((paper, index) => (
                    <Grid item xs={3} key={paper.id}>
                        <Card>
                            <CardContent style={{ position: 'relative' }}>
                                <Typography variant="h6" component="h2" gutterBottom>
                                    {`Year ${paper.year}`}
                                </Typography>
                                <IconButton onClick={(event) => handleMenuOpen(event, paper)} style={{ position: 'absolute', top: '8px', right: '8px' }}>
                                    <MoreHorizIcon />
                                </IconButton>
                                <Button variant="contained" onClick={() => openPaper(paper)} style={{ marginBottom: '40px' }}>
                                    Open Paper
                                </Button>
                            </CardContent>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            >
                                <MenuItem onClick={() => handleEditPaper()}>
                                    Edit
                                </MenuItem>
                                <MenuItem onClick={() => handleDeletePaper(paper.id)}>
                                    Delete
                                </MenuItem>
                            </Menu>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <AddPastPaper
                open={openAddPaperForm}
                onClose={handleCloseAddPaperForm}
                subjectId={subjectId}
                loadPastpapers={loadPastpapers}
                editPaper={selectedPaper }  
            />
        </Container>
    );
}

export default PastPapers;
