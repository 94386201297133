import React, { memo } from 'react'
import { Grid, Typography } from '@mui/material'
import RichTextEditor from '../../../components/richTextEditor2'
import PlaygroundApp from '../../../components/lexicalEditor/lexical-playground/src/App'

const TextEditorWithTitle = memo(function ({title, onChange, html, setLoading, loading, index,
    editorState, onChangeEditorState, onChangeHtml, currentHtml
 }) {
  return (
    <Grid container>
            <Grid xs={2} >
                <Typography variant='subtitle2'>
                    {title}
                </Typography>
            </Grid>
            {/* <Grid  xs={10} sx={{display:'flex'}}> */}
                <Grid xs={6} md = {7}  sx={{marginRight:'20px'}}>
                {/* <RichTextEditor onChange={onChange} html={html} setLoading={setLoading}
                    index={index}
                    loading={loading}
                /> */}
                <PlaygroundApp html={html} editorState={editorState} onChangeEditorState={onChangeEditorState} onChangeHtml ={onChangeHtml} i = {index}/>
                </Grid> 
                <Grid xs={6} md = {2}sx={{
                    border: '1px solid black',
                    padding: '10px',
                    margin:'15px'
                
                
                }}>
                    <div dangerouslySetInnerHTML={{__html: currentHtml}}>

                    </div>
                </Grid> 
            {/* </Grid> */}
        </Grid>
  )
})

export default TextEditorWithTitle