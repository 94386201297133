import axios from 'axios';
import moment from 'moment';
//import { Auth } from 'aws-amplify';
// import i18n from 'i18n-js';
import { addParamsToURL } from '../util';
import Env from '../config/environments';
import { ApiEndpoint, EnvConstants, Method } from '../constants';

export default async function api(
    URL,
    method,
    params,
    customBaseURL = Env.BASE_URL,
    body,
    headers = {},
    cancelToken = '',
    isToken = false,
    customToken = '',
    timeoutInterval = 300000,
    onUploadProgress = null
) {
    try {
        const response = await api1(URL, method, params, customBaseURL, body, headers, cancelToken, false, customToken, timeoutInterval, onUploadProgress);
        return response;
    } catch (err) {
        try {
            const response1 = await api1(URL, method, params, customBaseURL, body, headers, cancelToken, true, customToken, timeoutInterval, onUploadProgress);
            return response1;
        } catch (err1) {
            return err1;
        }
    }
}

async function api1(
    URL,
    method,
    params,
    customBaseURL = Env.BASE_URL,
    body,
    headers = {},
    cancelToken = '',
    isToken = false,
    customToken = '',
    timeoutInterval = 300000,
    onUploadProgress = null
) {
    const UTLWithOutLan = `${customBaseURL}${URL}`;

    // Multilingual yet not support
    // const local = i18n.locale || 'en';
    // const URlWithLang = `${UTLWithOutLan}${
    //     UTLWithOutLan.includes('?') ? '&' : '?'
    // }lang=${local}`;

    

    const customURL = addParamsToURL(UTLWithOutLan, params);
    let reqStartTime;

    // window.alert("ff " + customURL);

    let reqHeaders = {
        'Content-type': 'application/json',
        ...headers
    };

    if (isToken) {
        // Get JWT Token using this way, because this method refresh it self, if token expeired
        // const userSession = customToken;
        // const accessToken = userSession.accessToken.jwtToken;
        // reqHeaders.Authorization = accessToken;
        // console.log("refreshing...")

        try {
            const requsetsParams = {
                method: Method.POST,
                url: `${customBaseURL}${ApiEndpoint.AUTH_SERVICE}/refresh-token`,
                responseType: 'json',
                timeout: timeoutInterval,
                headers: reqHeaders,
                cancelToken,
                data: body || null,
                withCredentials : true
            }
            await axios(requsetsParams)
        }
        catch (err) {
            // console.log("comes here")
            // console.log(err)
        };
    }

    if (EnvConstants.ENV === 0) {
        reqStartTime = moment();
        console.log(
            '******************* REQUEST ******************',
            '\nURL :',
            customURL,
            '\nReq Time:',
            reqStartTime.format('HH:mm:ss.SSS'),
            '\nMethod:',
            method,
            'Header:',
            reqHeaders,
            '\nBody:',
            body,
            '\nJson Body:',
            JSON.stringify(body),
            '\n*********************************************'
        );
    }

    return new Promise((resolve, reject) => {
        const response = {};
        const axiosBody = method === "GET" ? {
            method: method,
            url: customURL,
            responseType: 'json',
            timeout: timeoutInterval,
            headers: reqHeaders,
            cancelToken,
            withCredentials : true
        } : {
            method: method,
            url: customURL,
            responseType: 'json',
            timeout: timeoutInterval,
            headers: reqHeaders,
            cancelToken,
            data: body || null,
            withCredentials : true,
            onUploadProgress : onUploadProgress ?? (() => {})
        }
        axios(
            axiosBody
        )
            .then((res) => {
                // window.alert("ff " + res);
                // console.log("in success range************************************///////////////////////////")
                if (res.status > 199 && res.status < 300) {
                    // API Success Range
                    response.status = res.status;
                    response.data = res.data;
                    resolve(response);
                    if (EnvConstants.ENV === 0) {
                        console.log(
                            '***************** RESPONSE ****************',
                            '\nURL: ',
                            customURL,
                            '\nRes Time: ',
                            moment().format('HH:mm:ss.SSS'),
                            '\nDuration: ',
                            moment().diff(reqStartTime, 'seconds', true),
                            '\nMethod: ',
                            '\nBody: ',
                            body,
                            '\nResponse: ',
                            response,
                            '\n******************************************'
                        );
                    }
                } else {
                    // console.log("second error.............")
                    const newError = {
                        response: { message: res.data, status: res.status }
                    };
                    if (EnvConstants.ENV === 0) {
                        console.log(
                            '***************** RESPONSE ****************',
                            '\nURL: ',
                            customURL,
                            '\nRes Time: ',
                            moment().format('HH:mm:ss.SSS'),
                            '\nDuration: ',
                            moment().diff(reqStartTime, 'seconds', true),
                            '\nBody: ',
                            body,
                            '\nResponse: ',
                            response,
                            '\n******************************************'
                        );
                    }

                    reject(newError);
                }
            })
            .catch((error) => {
                // console.log("first error")
                // window.alert("ffff " + error);
                if(!error.response){
                    error.response = {}
                }
                    // console.log(error);
                    // const { status, data } = error.response;
                    // console.log({ status, data })
                    /**
                     * STATUS 6xx codes are not standards. Those are implemented to show
                     * errors that pinch manually handle.
                     *
                     * ####### LIST #######
                     * 1) 600 is Client side timeout
                     * 2) 601 is Network related error
                     * 3) 602 is Unknown error
                     * 4) 603 is Default error
                     *
                     * Error response structure
                     * response : error response object
                     *      message : elaborate error message.
                     *      reason : basic error reason. ex : timeout
                     */

                    let customErr = null;
                    customErr = {
                        response: { message: error.message, reason: error.reason }
                    };
                    switch (error.reason) {
                        case 'Timeout':
                            customErr.response.status = 600;
                            break;
                        case 'Network error':
                            customErr.response.status = 601;
                            break;
                        case 'Unknown error':
                            customErr.response.status = 602;
                            break;
                        default:
                            customErr.response.status = 603;
                            break;
                    }

                    if (error.response.status === 404) {
                        customErr.response.status = 404;
                    } else if (error.response.status === 401) {
                        customErr.response.status = 401;
                    } else if (error.response.status === 500) {
                        customErr.response.status = 500;
                    } else {
                        customErr.response.status = 400;
                    }

                    if (EnvConstants.ENV === 0) {
                        console.log(
                            '***************** RESPONSE ****************',
                            '\nURL :',
                            customURL,
                            '\nResponse:',
                            response,
                            '\n*********************************'
                        );
                    }

                    reject(customErr);
                
            });
    });
}
