import { Grid, TextField, Typography } from '@mui/material';
import React from 'react';

function QuestionStringInput({ title, value, setValue, ...rest }) {
    const onChange = (e) => {
        setValue(e.target.value);
    };

    return (
        <Grid container alignItems="center" spacing={2}>
            <Grid item xs={2} style={{ minWidth: '150px' }}>
                <Typography variant="subtitle2">
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={10}>
                <TextField sx={{ m: 1, width: '45%' }} id="outlined-basic" label={title} variant="outlined" value={value} onChange={onChange} {...rest} />
            </Grid>
        </Grid>
    );
}

export default QuestionStringInput;

