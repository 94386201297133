import { BlockWithAlignableContents } from "@lexical/react/LexicalBlockWithAlignableContents"
import { DecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode"
import * as React from "react"

function VideoComponent({ className, format, nodeKey, src, type }) {
  return (
      <BlockWithAlignableContents
          className={className}
          format={format}
          nodeKey={nodeKey}
      >
          <video style={{maxHeight : 250}} controls>
              <source src={src} type={type} />
          </video>
      </BlockWithAlignableContents>
  )
}

function $convertVideoElement(domNode) {
    const src = domNode.getAttribute("data-lexical-video-src")
    if (src) {
      const node = $createVideoNode({src})
      return { node }
    }
    return null
  }
  
export class VideoNode extends DecoratorBlockNode {
    static getType() {
        return "video"
    }

    static clone(node) {

      const {videoInfo : {width = 200, height = 300, duration = 0, type1 } = {}} = node
        return new VideoNode(node.__src, 
          {
            width,
            height,
            duration,
            type1
          },
          node.__format, node.__key, node.__createdTimestamp)
    }

    static importJSON(serializedNode) {
      const playLoad = {
        src: serializedNode.src,
        videoInfo: serializedNode.videoInfo
      }
        const node = $createVideoNode(playLoad)
        node.setFormat(serializedNode.format)
        return node
      }

      exportJSON() {
        return {
          ...super.exportJSON(),
          type: "video",
          version: 1,
          src: this.__src,
          videoInfo: {
            width: this.__width,
            height: this.__height,
            duration: this.__duration,
            type1 : this.__type1
          },
          createdTimestamp : this.__createdTimestamp
        }
      }

      constructor(src, videoInfo, format, key, createdTimestamp = null) {
        super(format, key)
        const {width = 200, height = 300, duration = 0, type1 } = videoInfo
        this.__src = src
        this.__width = width
        this.__height = height
        this.__duration = duration
        this.__type1 = type1 //file type
        this.__createdTimestamp = createdTimestamp ?  createdTimestamp :  Date.now()
      }

      exportDOM() {
        const element = document.createElement("video")
        element.setAttribute("data-lexical-video-src", this.__src)
        // element.setAttribute("width", this.__width)
        // element.setAttribute("height", this.__height)
        element.setAttribute("duration", this.__duration)
        element.setAttribute("type", this.__type1)
        element.setAttribute("controls", "true")
        element.style.maxHeight = "250px"

        const sourceElement  = document.createElement("source")
        sourceElement .setAttribute("src", this.__src)
        sourceElement .setAttribute("type", this.__type1)
        sourceElement.setAttribute("data_createdTime", this.__createdTimestamp.toString())
        element.appendChild(sourceElement)
        

        
        // element.setAttribute(
        //   "src",
        //   `https://www.youtube-nocookie.com/embed/${this.__id}`
        // )
        // element.setAttribute("frameborder", "0")
        // element.setAttribute(
        //   "allow",
        //   "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        // )
        // element.setAttribute("allowfullscreen", "true")
        element.setAttribute("title", "video")
        element.setAttribute("data_createdTime", this.__createdTimestamp.toString())
        return { element }
      }

      static importDOM() {
        return {
            video: domNode => {
            if (!domNode.hasAttribute("data-lexical-video-src")) {
              return null
            }
            return {
              conversion: $convertVideoElement,
              priority: 1
            }
          }
        }
      }

      updateDOM() {
        return false
      }
    
      getId() {
        return this.__src
      }

      getTextContent(_includeInert, _includeDirectionless) {
        return `Sorry video can't be displayed`
      }

      decorate(_editor, config) {
        const embedBlockTheme = config.theme.embedBlock || {}
        const className = {
          base: embedBlockTheme.base || "",
          focus: embedBlockTheme.focus || ""
        }
        return (
          <VideoComponent
            className={className}
            format={this.__format}
            nodeKey={this.getKey()}
            src={this.__src}
            type={this.__type1}
          />
        )
      }
}

  export function $createVideoNode({src, videoInfo = {}, format, key}) {
    return new VideoNode(src, videoInfo, format, key)
  }
  
  export function $isVideoNode(node) {
    return node instanceof VideoNode
  }