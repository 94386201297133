import api from ".."
import Env from '../../config/environments';
import { ApiEndpoint, Method } from "../../constants"

const params = {};
const body = {};

export const uploadVideo = (file, onUploadProgress) => {
    const formData = new FormData();
    formData.append("video", file);
    const headers ={
        'Content-Type': 'multipart/form-data'
    }
    return api(ApiEndpoint.VIDEOS, Method.POST, params, Env.BASE_URL, formData, headers,
         '',
        false,
        '',
        300000,
        onUploadProgress
    )
}
