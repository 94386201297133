import { connect } from "react-redux";
import { setUser } from "../store/actions/userActions";
import { Navigate, useLocation } from "react-router";
import { useEffect, useState } from "react";
import { refreshToken } from "../services/auth";

const mapStateToProps = state => {
    return {
        isAuthenticated: state.userReducer.isAuthenticated,
        user: state.userReducer.user , 
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setUser: (isAuth, user) => dispatch(setUser(isAuth, user))
    };
};

function AdminProtectedRoute({ children, isAuthenticated, user, setUser }) {

  const allowedRole = 'SUPER_ADMIN';

  const [loading, setLoading] = useState(true)
  const {roles = []} = user;

  useEffect(() => {
    if(!isAuthenticated){
      refreshSession()
    }else{
      setLoading(false)
    }
  }, [isAuthenticated, user])
   
    const location = useLocation();

    const refreshSession = async () => {  
      try {
        const response = await refreshToken();
        if (response.status === 200) {
          setUser(response.data)
        }
        setLoading(false)
      }
      catch (err) {
        console.log()
        setLoading(false)
      };
    }

    if (loading) {
      return null;
    }


    if (isAuthenticated && roles.includes(allowedRole)) {
        return children;
    } else {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminProtectedRoute);