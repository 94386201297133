import React from 'react'

function ImagePreview(props) {

    const { caption, description } = props
    return (
        <div style={{
            width: props.previewWidth,
            height: props.imageWidthAndHeight.height,
            // flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 8,
            marginBottom: 8,
        }}>
            <figure>
                <img style={{ maxWidth: Number(props.imageWidthAndHeight.width) }}
                    src={props.src}
                    alt={description} />
                <figcaption style={{ textAlign: 'center' }}>{caption}</figcaption>
            </figure>
            {/* <img
                style={{ maxWidth: Number(props.imageWidthAndHeight.width) }}
                src={props.src}
                 /> */}
        </div>
    )
}

export default ImagePreview