import React, { useState } from 'react'
import PlaygroundApp from '../../components/lexicalEditor/lexical-playground/src/App'
import { Button } from '@mui/material'

function OpenEditor() {

  const [editorState, setEditorState] = useState('')
  const [htmlState, setHtmlState] = useState('')


  const handleSave = () => {
    const state = {
      editorState,
      htmlState
    }
    const jsonString = JSON.stringify(state)
    window.ReactNativeWebView.postMessage(jsonString)
  }

  return (
    <>
      <PlaygroundApp
        // html = "mm"
        editorState={{}}
        onChangeEditorState={setEditorState}
        onChangeHtml={setHtmlState}
        isMobile={true}
      />

      <div style={{
        display: 'flex',
        // alignSelf: 'center',
        // alignItems: 'center',
        // alignContent: 'center',
        justifyContent: 'center'
      }}>
        <Button onClick={handleSave} variant="contained" color="primary"> Save </Button>
      </div>

    </>
  )
}

export default OpenEditor