import { CodeHighlightNode, CodeNode } from "@lexical/code"
import { HashtagNode } from "@lexical/hashtag"
import { AutoLinkNode, LinkNode } from "@lexical/link"
import { ListItemNode, ListNode } from "@lexical/list"
import { MarkNode } from "@lexical/mark"
import { OverflowNode } from "@lexical/overflow"
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode"
import { HeadingNode, QuoteNode } from "@lexical/rich-text"
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table"

import { CollapsibleContentNode } from "../plugins/CollapsiblePlugin/CollapsibleContentNode.js"
import { CollapsibleTitleNode } from "../plugins/CollapsiblePlugin/CollapsibleTitleNode.js"
import { AutocompleteNode } from "./AutocompleteNode.jsx"
import { EmojiNode } from "./EmojiNode.jsx"
import { EquationNode } from "./EquationNode.jsx"
import { ExcalidrawNode } from "./ExcalidrawNode/index.jsx"
import { FigmaNode } from "./FigmaNode.jsx"
import { ImageNode } from "./ImageNode.jsx"
import { InlineImageNode } from "./InlineImageNode.jsx"
import { KeywordNode } from "./KeywordNode.js"
import { LayoutContainerNode } from "./LayoutContainerNode.js"
import { LayoutItemNode } from "./LayoutItemNode.js"
import { MentionNode } from "./MentionNode.js"
import { PageBreakNode } from "./PageBreakNode/index.jsx"
import { PollNode } from "./PollNode.jsx"
import { StickyNode } from "./StickyNode.jsx"
import { TweetNode } from "./TweetNode.jsx"
import { YouTubeNode } from "./YouTubeNode.jsx"
import { CollapsibleContainerNode } from "../plugins/CollapsiblePlugin/CollapsibleContainerNode.js"
import { VideoNode } from "./VideoNode.jsx"

const PlaygroundNodes = [
  HeadingNode,
  ListNode,
  ListItemNode,
  QuoteNode,
  CodeNode,
  TableNode,
  TableCellNode,
  TableRowNode,
  HashtagNode,
  CodeHighlightNode,
  AutoLinkNode,
  LinkNode,
  OverflowNode,
  PollNode,
  StickyNode,
  ImageNode,
  InlineImageNode,
  MentionNode,
  EmojiNode,
  ExcalidrawNode,
  EquationNode,
  AutocompleteNode,
  KeywordNode,
  HorizontalRuleNode,
  TweetNode,
  YouTubeNode,
  FigmaNode,
  MarkNode,
  CollapsibleContainerNode,
  CollapsibleContentNode,
  CollapsibleTitleNode,
  PageBreakNode,
  LayoutContainerNode,
  LayoutItemNode,
  VideoNode,
]

export default PlaygroundNodes
