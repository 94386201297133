import api from "..";
import Env from '../../config/environments';
import { ApiEndpoint, Method } from "../../constants";

const params = {};
const body = {};

export const getQuestionPaperFiles = (subjectId,  params = {}) => {
    return api(`${ApiEndpoint.QUESTION_PAPER_FILES}/subject/${subjectId}`, Method.GET, params, Env.BASE_URL, body);
}

export const createPaper = (subjectId, paper) =>{

    const formData = new FormData();
    const {name, description, sortingOrder, isActive, file} = paper;

    // console.log(file)
    formData.append("file", file);
    const params = {
        name,
        description,
        sortingOrder,
        isActive
    };
    // formData.append("name", name);
    // formData.append("description", description);
    // formData.append("order", order);
    // formData.append("isActive", isActive);
    // formData.append("questionPaperFileDao", paper);
    const headers = {
        'Content-Type': 'multipart/form-data'
    }
    return api(`${ApiEndpoint.QUESTION_PAPER_FILES}/subject/${subjectId}`, Method.POST, params, Env.BASE_URL, formData, headers)
}

export const updatePaperInServer = (paper) => {
    const formData = new FormData();
    const {id, name, description, sortingOrder, isActive, file} = paper;

    console.log({isActive})
    if(file){
        formData.append("file", file);
    }

    const params = {}
    if(name){
        params.name = name
    }
    if(description){
        params.description = description
    }
    if(sortingOrder){
        params.sortingOrder = sortingOrder
    }
    if(isActive != null){
        params.isActive = isActive
    }
    // formData.append("name", name);
    // formData.append("description", description);
    // formData.append("order", order);
    // formData.append("isActive", isActive);
    // formData.append("questionPaperFileDao", paper);
    const headers = {
        'Content-Type': 'multipart/form-data'
    }
    return api(`${ApiEndpoint.QUESTION_PAPER_FILES}/${id}`, Method.PUT, params, Env.BASE_URL, formData, headers)
}