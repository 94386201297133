import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { getUnlimitedQuestions, updateUnlimitedQuestions } from '../../services/subjects/unlimitedQuestions';
import { Fab, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, InputLabel } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import QuestionViewPopup from '../questions/QuestionViewPopup';
import { connect } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';
import Select from '@mui/material/Select';

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        // disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

const tableStyle = {
  justifyContent: 'center',
  margin: '40px 70px',
  width: '1400px',
};

const mapStateToProps = (state) => {
  const selectedSyllabus = state.SyllabusReducer.syllabus || {};

  return {
    selectedSyllabusHere: selectedSyllabus,
  };
};

function UnlimitedQuestionBank({ selectedSyllabusHere }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [items, setItems] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [questionView, setQuestionView] = useState({
    open: false,
    question: null,
  });
  const [viewButton,setViewButton]= useState(false)
  const [openDialog, setOpenDialog] = useState(false);
  const [orderValue, setOrderValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [currentClickedQuestion, setCurrentClickedQuestion] = useState(null);
  const[questionName,setQuestionName] = useState('')
  const[sortStatus,setSortStatus] = useState('')
  const navigate = useNavigate();

  const location = useLocation();
const {subjectId} = useParams();
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - items.length) : 0;

  const handleChangePage = (event, newPage = page + 1) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setCurrentClickedQuestion(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRemove = () => {};

  const handleViewReviews = () => {
    const questionId = currentClickedQuestion.mcqQuestion.question.id;  
    navigate(`/dashboard/syllabuses/selectedSyllabus/reviews/${subjectId}/${questionId}`)
  };


  const handleChangeOrder = (row) => {
    setCurrentClickedQuestion(row);
    setOrderValue(row.order);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleOrderInputChange = (event) => {
    setOrderValue(event.target.value);
  };

  const handleSubmitOrderChange = () => {
    const selectedQuestionId = currentClickedQuestion.id;
    console.log(selectedQuestionId);
    const updateQuestion ={
      order:orderValue,
    } 
    const response = updateUnlimitedQuestions(selectedQuestionId,updateQuestion);
    setOrderValue("");
    setOpenDialog(false);
  };

  const loadQuestions = async () => {
    try {
      setLoading(true);
      console.log(subjectId)
      const encodedQuestionName = encodeURIComponent(questionName);
      const questions = await getUnlimitedQuestions(page, rowsPerPage,subjectId,true,encodedQuestionName,sortStatus);
      console.log(questions.data);
      setItems(questions.data || []);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadQuestions();
  }, [page, rowsPerPage]);

  const handleAddQeustion = () => {
    navigate(`/dashboard/syllabuses/selectedSyllabus/addUnlimitedQuestions/${subjectId}`);
  };

  const onViewQuestionClick = () => {
    console.log(currentClickedQuestion)
    setQuestionView({ open: true,question : currentClickedQuestion.mcqQuestion });
    handleMenuClose();
  };

  const handleSearchButtonClick = () => {
     loadQuestions()
  };
 
 const handleNameSearchChange = (event) => {
    const trimmedValue = event.target.value.trim();
    setQuestionName(trimmedValue);
    setPage(0);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearchButtonClick();
    }
  };

  const handleSortingStatusChange = (event) => {
    setSortStatus(event.target.value);
  };
    
  return (
    <>
    {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      ) : (
      <div>
        <div>Unlimited Questions</div>
        <Box display="flex" alignItems="center" mb={2}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={questionName}
          onChange={handleNameSearchChange}
          onKeyPress={handleKeyPress}
          style={{ margianRight: '10px' }}
        />
        <FormControl   size="small" style={{ marginRight: '10px', minWidth: 120 }}>
          <InputLabel id="sort-by-label">Sort By</InputLabel> 
              <Select
                labelId="sort-by-label"
                id="sort-by"
                value={sortStatus}
                label="Sort By"
                onChange={handleSortingStatusChange}
              >
                <MenuItem value="order">Order</MenuItem>
                <MenuItem value="questionName">Question Name</MenuItem>
              </Select>
        </FormControl>
        <Button variant="contained" color="primary" onClick={handleSearchButtonClick}>
          <SearchIcon />
        </Button>
      </Box>

        <TableContainer component={Paper} style={tableStyle}>
          <Table>
            <TableHead>
              <TableRow>
              <TableCell>Order</TableCell>
                <TableCell>Question Name</TableCell>
                <TableCell>Question</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {items.map((row, i) => (
                <TableRow key={row.id}>
                  <TableCell>  <div dangerouslySetInnerHTML={{ __html: row.order }} /></TableCell>
                  <TableCell>
                    <div dangerouslySetInnerHTML={{ __html: row.mcqQuestion.question.name }} />
                  </TableCell>
                  <TableCell>
                    <div dangerouslySetInnerHTML={{ __html: row.mcqQuestion.question.questionText }} />
                  </TableCell>
                  <TableCell>
                    <IconButton aria-label="more" size="small" onClick={(e) => handleMenuOpen(e, row)}>
                      <MoreHorizIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            colSpan={3}
            count={-1}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
          </TableContainer>

          <QuestionViewPopup questionView={questionView} setQuestionView={setQuestionView} />

          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose} onClick={handleMenuClose}>
            <MenuItem onClick={handleRemove}>Remove</MenuItem>
            <MenuItem onClick={handleViewReviews}>View Reviews</MenuItem>
            <MenuItem onClick={() => handleChangeOrder(currentClickedQuestion)}>Change Order</MenuItem>
            <MenuItem onClick={onViewQuestionClick}>View Question</MenuItem>
          </Menu>
        </div>
      )}
      <QuestionViewPopup 
        questionView={questionView} 
        setQuestionView={setQuestionView}
        viewButton={viewButton}
      />
      <Box sx={{ '& > :not(style)': { marginBottom: 1 } }}>
        <Fab color="primary" aria-label="add" onClick={handleAddQeustion}>
          <AddIcon />
        </Fab>
      </Box>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Change Order</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="order"
            label="Order"
            type="text"
            fullWidth
            value={orderValue}
            onChange={handleOrderInputChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmitOrderChange();
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleSubmitOrderChange}>Update</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default connect(mapStateToProps)(UnlimitedQuestionBank);
