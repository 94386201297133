import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {$generateHtmlFromNodes} from '@lexical/html';
import React, { useEffect } from 'react'

function HtmlConverterPlugin({ onChange, onChangeHtml }) {

  // const onChangeEditor = (editorState) => {
  //     const parser = new DOMParser();
  //      onChange(editorState);
  //     const htmlString = $generateHtmlFromNodes(editorState);
  //     console.log(htmlString)
  //     onChangeHtml(htmlString)
  // }

  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      //  onChangeEditor(editorState);
      // const htmlString = $generateHtmlFromNodes(editor);
      // console.log(htmlString)
      // console.log("999999999999999999999999999")
      editor.update(() => {
        const editorState = editor.getEditorState();
        const jsonString = JSON.stringify(editorState);
        // const json = editorState.toJSON();
        onChange(jsonString);
        const htmlString = $generateHtmlFromNodes(editor);
        onChangeHtml(htmlString)
        // console.log(htmlString)
        // console.log(jsonString)
      })
    });
  }, [editor, onChange, onChangeHtml]);
  return (
    null
  )
}

export default HtmlConverterPlugin