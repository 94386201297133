import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

function AddQuiz({ open, onClose, onAddQuiz ,editQuiz}) {
    const [editMode, setEditMode] = useState(false);
    const [quizDetails, setQuizDetails] = useState({
        name: '',
        description: '',
        date: '',
        startTime: '',
        endTime: ''
    });

    useEffect(() => {
        if (editQuiz) {
            setEditMode(true);
            setQuizDetails({
                name: editQuiz.name,
                description: editQuiz.description,
                date: editQuiz.date,
                startTime: editQuiz.startTime,
                endTime: editQuiz.endTime
            });
        } else {
            setEditMode(false);
            setQuizDetails({
                name: '',
                description: '',
                date: '',
                startTime: '',
                endTime: ''
            });
        }
    }, [editQuiz]);
    
    const handleAddQuiz = () => {
      
        onAddQuiz(quizDetails);
        onClose();  
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>  {editMode ? 'Edit Daily Quiz' : 'Add Daily Quiz'}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Question Name"
                    type="text"
                    fullWidth
                    value={quizDetails.name}
                    onChange={(e) => setQuizDetails({ ...quizDetails, name: e.target.value })}
                />
                <TextField
                    margin="dense"
                    id="description"
                    label="Description"
                    type="text"
                    fullWidth
                    value={quizDetails.description}
                    onChange={(e) => setQuizDetails({ ...quizDetails, description: e.target.value })}
                />
                <TextField
                    margin="dense"
                    id="date"
                    label="Date"
                 //   type="date"
                    fullWidth
                    value={quizDetails.date}
                    onChange={(e) => setQuizDetails({ ...quizDetails, date: e.target.value })}
                />
                <TextField
                    margin="dense"
                    id="startTime"
                    label="Start Time"
                   // type="time"
                    fullWidth
                    value={quizDetails.startTime}
                    onChange={(e) => setQuizDetails({ ...quizDetails, startTime: e.target.value })}
                />
                <TextField
                    margin="dense"
                    id="endTime"
                    label="End Time"
                  //  type="time"
                    fullWidth
                    value={quizDetails.endTime}
                    onChange={(e) => setQuizDetails({ ...quizDetails, endTime: e.target.value })}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleAddQuiz} variant="contained" color="primary">
                {editMode ? 'Save Changes' : 'Add'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddQuiz;
