import { DELETE_SYLLABUS, SET_SYLLABUS, UPDATE_SYLLABUS , GET_SYLLABUS_DETAILS_SUCCESS} from "../actions/SyllabusAction";
 
const initialState = {
    isAuthenticated: false,
    syllabus: {},
    syllabusDetails: null,
}

export default function SyllabusReducer(state = initialState, action) {
    switch (action.type) {
        
        case SET_SYLLABUS:
            return {
                ...state,
                isAuthenticated: true,
                syllabus: action.payload
            };
        case UPDATE_SYLLABUS: {
            return {
                ...state,
                syllabus: action.payload
            };
        }
        case DELETE_SYLLABUS:  {
            return {
                ...state,
                isAuthenticated: false,
                syllabus: {}
            };
        }
        case GET_SYLLABUS_DETAILS_SUCCESS:{
            return {
                ...state,
                syllabusDetails: action.payload,
            };
        }
        default:
            return state;
    }
}
