import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Box,
} from '@mui/material';
import { updateSyllabus } from '../../services/subjects/syllabus';
import { getLanguages } from '../../services/subjects/languages';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const EditSyllabusForm = ({ isOpen, onClose, syllabusData, setItems }) => {
  const [editedName, setEditedName] = useState('');
  const [editedDescription, setEditedDescription] = useState('');
  const [editedNameTags, setEditedNameTags] = useState([]);
  const [editOrder, setEditedOrder] = useState('');
  const [selectedLanguageCodes, setSelectedLanguages] = useState([]);
  const [languageNames, setLanguageNames] = useState({});
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [checkboxStates, setCheckboxStates] = useState({});

  useEffect(() => {
    if (syllabusData) {
      setEditedName(syllabusData.name || '');
      setEditedDescription(syllabusData.description || '');

      if (syllabusData.nameTags) {
        const nameTags = syllabusData.nameTags.map((tag) => ({
          id: tag.id,
          languageCode: tag.language.code,
          languageName: tag.language.name,
          value: tag.name,
          isActive : tag.isActive
        }));
        setEditedNameTags(nameTags);

        const matchingLanguageCodes = nameTags.map((tag) => tag.languageCode);
        setSelectedLanguages(matchingLanguageCodes);

        const initialLanguageNames = {};
        nameTags.forEach((tag) => {
          initialLanguageNames[tag.languageCode] = tag.value;
        });
        setLanguageNames(initialLanguageNames);

        const initialCheckboxStates = {};
      nameTags.forEach((tag) => {
        // const isActive = syllabusData.activeNameTags.some((activeTag) =>
        //    activeTag.nameTag.id === tag.id && activeTag.isActive
        // );
        initialCheckboxStates[tag.languageCode] = tag.isActive;
        // tag.isActive = isActive;
      });
      setCheckboxStates(initialCheckboxStates);

      }
      setEditedOrder(syllabusData.sortingOrder || '');
    }
  }, [syllabusData]);

  useEffect(() => {
    const loadLanguages = async () => {
      try {
        const response = await getLanguages();
        const { data: languages } = response;
        setAvailableLanguages(languages);
      } catch (error) {
        console.error('Error loading languages:', error);
      }
    };

    loadLanguages();
  }, []);

  const handleNameChange = (e) => {
    setEditedName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setEditedDescription(e.target.value);
  };

  const handleNameTagChange = (languageCode, value) => {
    setEditedNameTags((prevNameTags) => {
      const updatedNameTags = prevNameTags.map((tag) =>
        tag.languageCode === languageCode ? { ...tag, value } : tag
      );
      if (!updatedNameTags.some((tag) => tag.languageCode === languageCode)) {
        updatedNameTags.push({ languageCode, value });
      }
      return [...updatedNameTags];
    });
  };

  const handleOrderChange = (e) => {
    setEditedOrder(parseInt(e.target.value, 10));
  };

  const handleLanguageChange = (event) => {
    setSelectedLanguages(event.target.value);
  };

  const handleLanguageNameChange = (languageCode, event) => {
    setLanguageNames((prevLanguageNames) => ({
      ...prevLanguageNames,
      [languageCode]: event.target.value,
    }));
  };

  const handleDeleteNameTag = (languageCode) => {
    setLanguageNames((prevLanguageNames) => {
      const updatedLanguageNames = { ...prevLanguageNames };
      delete updatedLanguageNames[languageCode];

      return updatedLanguageNames;
    });

    setEditedNameTags((prevNameTags) => prevNameTags.filter((tag) => tag.languageCode !== languageCode));
    setCheckboxStates((prevStates) => {
      const updatedStates = { ...prevStates };
      delete updatedStates[languageCode];
      return updatedStates;
    });
  };

  const handleCheckboxChange = (languageCode, event) => {
    const isChecked = event.target.checked;
    setCheckboxStates((prevStates) => 
      {
        const newObject =  {
      ...prevStates,
      [languageCode]: isChecked,
    }
    console.log(newObject)
    return newObject;
  });

    setEditedNameTags((prevNameTags) =>
    prevNameTags.map((tag) =>
      tag.languageCode === languageCode ? { ...tag, isActive: isChecked } : tag
    )
  );
   
  };

  const handleEditSyllabus = async () => {
    try {
      const updatedNameTagsArray = selectedLanguageCodes.map((languageCode) => {
        const tag = editedNameTags.find((tag) => tag.languageCode === languageCode);
        const languageId = getLanguageIdByCode(languageCode);
        return {
          id: tag ? tag.id : null,
          language: { id: languageId },
          name: languageNames[languageCode] || '',
          isActive: checkboxStates[languageCode] || false,
        };
      });

      

      const editedSyllabus = {
        name: editedName,
        description: editedDescription,
        nameTags: updatedNameTagsArray,
        sortingOrder: editOrder,
        activeNameTags: editedNameTags.map((tag) => ({
          isActive: tag.isActive || false,  
          nameTag: { id: tag.id },
        })),
      };

      console.log('Payload:', editedSyllabus);  
      const response = await updateSyllabus(syllabusData.id, editedSyllabus);
      setItems((prevItems) =>
        prevItems.map((item) =>
          item.id === syllabusData.id ? { ...item, ...response.data } : item
        )
      );

      onClose();
    } catch (error) {
      console.error('Error editing syllabus:', error);
    }
  };

  const getLanguageIdByCode = (code) => {
    const language = availableLanguages.find((lang) => lang.code === code);
    return language ? language.id : null;
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Edit Syllabus</DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          value={editedName}
          onChange={handleNameChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Description"
          value={editedDescription}
          onChange={handleDescriptionChange}
          fullWidth
          margin="normal"
        />

        <FormControl fullWidth style={{ margin: '5px' }}>
          <InputLabel id="language-select-label">Languages</InputLabel>
          <Select
            labelId="language-select-label"
            id="language-select"
            multiple
            value={selectedLanguageCodes}
            onChange={handleLanguageChange}
            renderValue={(selected) => {
              const selectedLanguageNames = selected.map((code) =>
                availableLanguages.find((language) => language.code === code)?.name
              );
              return selectedLanguageNames.join(', ');
            }}
          >
            {availableLanguages.map((language) => (
              <MenuItem key={language.id} value={language.code}>
                {language.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedLanguageCodes.map((code) => (
          <Box key={code} display="flex" alignItems="center" style={{ margin: '5px' }}>
            <TextField
              style={{ flexGrow: 1, marginRight: '5px' }}
              label={`${availableLanguages.find((language) => language.code === code)?.name} Name`}
              value={languageNames[code] || ''}
              onChange={(e) => handleLanguageNameChange(code, e)}
            />
            <IconButton onClick={() => handleDeleteNameTag(code)}>
              <DeleteIcon />
            </IconButton>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxStates[code] || false}
                  onChange={(e) => handleCheckboxChange(code, e)}
                />
              }
              label="Active"
            />
          </Box>
        ))}

        <TextField
          style={{ margin: '5px' }}
          label="Order"
          fullWidth
          value={editOrder}
          onChange={handleOrderChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleEditSyllabus}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditSyllabusForm;
