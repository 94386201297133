import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router';
import GradePage from './Grades/GradePage';
import GroupPage from '../../components/syllabuses/GroupPage';  
import SubjectsPage from './SubjectsPage';
import { connect, useSelector } from 'react-redux';
import {SyllabusReducer} from '../../store/reducers/SyllabusReducer'
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
const mapStateToProps = (state) => {
  const selectedSyllabus = state.SyllabusReducer.syllabus || {};
  const nameTags = selectedSyllabus.nameTags || [];
  const selectedLanguages = nameTags.map((tag) => tag.language);
  

  return {
    selectedSyllabusHere: selectedSyllabus,
    nameTags: nameTags,
    selectedLanguages: selectedLanguages,
     
  };

};

 

function SelectedSyllabusPage({selectedSyllabusHere,selectedLanguages} ) {
  const [value, setValue] = useState(0);
  const user = useSelector((state) => state.userReducer.user);
  const navigate = useNavigate();
  console.log("selectedLanguages",selectedLanguages);
  
  const handleQuestionsButtonClick = (syllabusId,subjectId) => {
    console.log(`Questions button clicked for subject with ID ${subjectId}`);
    navigate('/dashboard/syllabuses/selectedSyllabus/questionsBank', {
      state: {
        syllabusId:syllabusId,
        subjectId: subjectId,
      },
    });
  };

  const userHasRole = (user, role) => {
    return user.roles && user.roles.includes(role);
  };

  const isTyper = userHasRole(user, 'TYPER');

  return (
    <div>
      <div className="syllabusName">
        <Typography variant="h4">
          {selectedSyllabusHere?.name || 'No syllabus selected'}
        </Typography>
      </div>
      <div style={{ margin: '20px' }}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={(event, newValue) => setValue(newValue)}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Subjects" {...a11yProps(0)} />
            {!isTyper &&<Tab label="Group" {...a11yProps(1)} />}
            {!isTyper &&<Tab label="Grades" {...a11yProps(2)} />}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} dir="ltr">
          <SubjectsPage
            syllabusId={selectedSyllabusHere?.id}
            selectedLanguages={selectedLanguages}
            
          />
        </TabPanel>
        {!isTyper &&<TabPanel value={value} index={1} dir="ltr">
          <GroupPage selectedLanguages={selectedLanguages}/>
        </TabPanel>}
        {!isTyper &&<TabPanel value={value} index={2} dir="ltr">
          <GradePage 
            syllabusId={selectedSyllabusHere?.id}
            selectedLanguages={selectedLanguages}
          />
        </TabPanel>}
      </div>
    </div>
  );
}

export default connect(mapStateToProps) (SelectedSyllabusPage);
