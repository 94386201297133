import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress, // Import CircularProgress component
  IconButton,
  Grid,  
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import {
  createGroupsForSyllabus,
  getGroupsForASyllabus,
} from '../../services/subjects/subjectGroup';

const AddParentGroupForm = ({
  isOpen,
  onClose,
  languages,
  createSubgroup,
  parentGroup,
  syllabusId,
  loadGroups
}) => {
  const [generalName, setGeneralName] = useState('');
  const [description, setDescription] = useState('');
  const [subGroupNames, setSubGroupNames] = useState({});
  const [isLabelChecked, setIsLabelChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [order, setOrder] = useState('');
  const [parentGroups, setParentGroups] = useState([]);
  const [selectedParentGroup, setSelectedParentGroup] = useState(null);
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [isAddingGroup, setIsAddingGroup] = useState(false); // State to track if adding group
  const [isActive, setIsActive] = useState(false);
  
  useEffect(() => {
    fetchParentGroups();
  }, [syllabusId]);

  const fetchParentGroups = async () => {
    try {
      setLoading(true); // Set loading state to true when fetching data
      const response = await getGroupsForASyllabus(syllabusId);
      setParentGroups(response.data);
    } catch (error) {
      console.error('Error fetching parent groups:', error);
    } finally {
      setLoading(false); // Set loading state to false after fetching data
    }
  };

  const handleGeneralNameChange = (e) => {
    setGeneralName(e.target.value);
  };
  const handleOrderChange = (e) => {
    setOrder(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSubgroupNameChange = (languageCode, value) => {
    setSubGroupNames((prevSubGroupNames) => ({
      ...prevSubGroupNames,
      [languageCode]: value,
    }));
  };
   
  const addSubgroup = async () => {
    setLoading(true); // Set loading state to true when adding subgroups
    const nameTags = languages.map((language) => ({
      language: language,
      name: subGroupNames[language.code],
    }));
  
    try {
      let newGroup = {
        name: generalName,
        description,
        nameTags,
        isLabelChecked,
        selectedOption,
        subGroups: [], 
        sortingOrder:order,
        isActive
      };
  
      if (selectedParentGroup) {
        newGroup.parentGroup = { id: selectedParentGroup.id }
      }
  
      await createGroupsForSyllabus(syllabusId, newGroup);
      setGeneralName('');
      setDescription('');
      setSubGroupNames({});
      setIsLabelChecked(false);
      setSelectedOption('');
      setSelectedParentGroup(null);
      setOrder('');
      onClose();
      loadGroups();
    } catch (error) {
      console.error('Error adding group:', error);
    } finally {
      setLoading(false); // Set loading state to false after adding subgroups
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
  <Grid container alignItems="center" justifyContent="space-between">
    <div>{isLabelChecked ? 'Add Subgroup' : 'Add Group'}</div>
    <IconButton aria-label="close" onClick={onClose}>
      <CloseIcon />
    </IconButton>
  </Grid>
</DialogTitle>

      <DialogContent>
        {loading && <CircularProgress />} {/* Display loading indicator */}
        <TextField
          label="General Name"
          value={generalName}
          onChange={handleGeneralNameChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Description"
          value={description}
          onChange={handleDescriptionChange}
          fullWidth
          margin="normal"
        />
        {languages.map((language) => (
          <TextField
            key={language.code}
            label={`${language.name} Name`}
            value={subGroupNames[language.code] || ''}
            onChange={(e) =>
              handleSubgroupNameChange(language.code, e.target.value)
            }
            fullWidth
            margin="normal"
          />
        ))}
        <FormControlLabel
          control={
            <Checkbox
              checked={isLabelChecked}
              onChange={() => {
                setIsLabelChecked(!isLabelChecked);
                setIsAddingGroup(!isLabelChecked); // Toggle isAddingGroup state
              }}
              color="primary"
            />
          }
          label="Select Parent group"
        />
        {isLabelChecked && (
          <FormControl fullWidth>
            <InputLabel id="select-label">Select Subgroup</InputLabel>
            <Select
              labelId="select-label"
              value={selectedParentGroup ? selectedParentGroup.id : ''}
              onChange={(e) => {
                const selectedGroupId = e.target.value;
                const selectedGroup = parentGroups.find(
                  (group) => group.id === selectedGroupId
                );
                setSelectedParentGroup(selectedGroup);
              }}
            >
              <MenuItem value="">No Subgroup (Subject Group)</MenuItem>
              {parentGroups.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <TextField
          label="Order"
          value={order}
          onChange={handleOrderChange}
          fullWidth
          margin="normal"
        />

        <FormControlLabel
          control={<Checkbox checked={isActive} onChange={(e) => setIsActive (e.target.checked)} />}
          label="Active"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={addSubgroup}
          disabled={Object.keys(subGroupNames).length !== languages.length}
        >
          Add {isAddingGroup ? 'Group' : 'Subgroup'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddParentGroupForm;
