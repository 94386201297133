import React from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Margin } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import ImageUpload from "./ImageUpload";
import { useState } from "react";

const inputStsyle = {
  backgroundColor: '#fff',
  textAlign: 'left',
  color: '#8888', 
  border:'none' ,
  border: '1px solid #87BCDE',  
  width: '100px',  
  padding: '10px 20px',
  marginLeft: '10px',
  size:"100" 
  
};

const inputStyle={
  display:'flex',
  flexDirection:'column',
  margin:'10px',
  padding:'5px'
}

const subButton={
  backgroundColor:'#203C7E',
  color:'#fff'
}

 
function AddTeacherForm({ open, handleClose }) {

  const [file, setFile] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
  };
   
  return (
     
      <Dialog open={open} onClose={handleClose}>
        <div style={{display:'flex',padding:'0px 0px 0px 45px'}}> 
          <DialogTitle style={{color:'#203C7E'}}>Theacher Details</DialogTitle>
          <CloseIcon onClick={handleClose} style={{marginTop:'19px',cursor:'pointer'}}/>
        </div>
         
        <DialogContent style={{display:'flex',flexDirection:'column'}}>  
         
          <div style={inputStyle}> Name<TextField  inputProps={{ style: { height: '10px' } }} /> </div>
          <div style={inputStyle}> Contact Number<TextField inputProps={{ style: { height: '10px' } }}/> </div>
          <div style={inputStyle}> Email<TextField inputProps={{ style: { height: '10px' } }}/> </div>
          <div style={inputStyle}> NIC Number<TextField inputProps={{ style: { height: '10px' } }}/> </div>
          <div style={inputStyle}> School<TextField inputProps={{ style: { height: '10px' } }}/> </div>
          <div style={{display:'flex',flexDirection:'column',margin:'10px',width:'250px'}}>
            Add Picture
            <ImageUpload  />
          </div>
        </DialogContent>
         
        <Button style={subButton}onClick={handleClose}>Submit</Button>
         
      </Dialog>
     
  )
}

export default AddTeacherForm
