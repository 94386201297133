import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { registerUser } from '../../services/auth';

const SignUpForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    userName: '',
    password: '',
    otp: '',
  });
  const [showOTP, setShowOTP] = useState(false);


  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

 

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      if (showOTP) {
        console.log('OTP Verification:', formData.otp);
        onClose();
      } else {
      
        await registerUser(formData.userName, formData.password);
        console.log('Registration successful');
        setShowOTP(true);
      }
    } catch (error) {
      console.error('Registration failed', error);
      
    }
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '20px',
        backgroundColor: 'white',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Typography variant="h6" color="inherit" sx={{ marginBottom: '10px' }}>
        {showOTP ? 'Verify OTP' : 'Signup'}
      </Typography>
      <form onSubmit={handleSubmit}>
      
        
        <TextField
              type="text"
              label="User Name"
              name="userName"
              fullWidth
              margin="normal"
              value={formData.userName}
              onChange={handleChange}
              required
        />
        <TextField
              type="password"
              label="Password"
              name="password"
              fullWidth
              margin="normal"
              value={formData.password}
              onChange={handleChange}
              required
        />
        
        {showOTP && ( 
          <TextField
            type="text"
            label="OTP"
            name="otp"
            fullWidth
            margin="normal"
            value={formData.otp}
            onChange={handleChange}
            required={!showOTP}
          />
        )}
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 3, mr: 2 }}>
        {showOTP ? 'Verify OTP' : 'SignUp'}
        </Button>
        <Button onClick={onClose} variant="outlined" color="primary" sx={{ mt: 3 }}>
          Cancel
        </Button>
      </form>
      {showOTP || (
        <Typography variant="body2" sx={{ mt: 2 }}>
          Already have an account? <Link href="#" color="primary">LogIn</Link>
        </Typography>

      )}
    </Box>
  );
};

export default SignUpForm;





