import { FormatAlignCenter, FormatAlignLeft, FormatAlignRight, FormatBold, FormatIndentDecrease, FormatIndentIncrease, FormatItalic, FormatListBulleted, FormatListNumbered, FormatUnderlined, Image, Redo, StrikethroughS, Subscript, Superscript, Undo } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React, { useState } from 'react'
import ControlButton from './ControlButton'
import ImageForm from './imageForm'

function Toolbar({ inlineToggle, currentStyle, listToggle, blockType, insertImage, insertEquation }) {

    const [openImageUploader, setOpenImageUploader] = useState(false)

    const onUndo = () => {
        insertEquation()

    }
    const onRedo = () => {

    }


    const onItalic = () => {

    }
    const onUnderLine = () => {

    }
    const addImage = () => {
        setOpenImageUploader(true)

    }
    const onFormatAlignLeft = () => {

    }
    const onFormatCenter = () => {

    }
    const onFormatAlignRight = () => {

    }
    const onIndentDecrease = () => {

    }
    const onFormatIndentIncrease = () => {

    }


    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'row' }}>

                <IconButton onClick={onUndo}>
                    <Undo />
                </IconButton>
                <IconButton onClick={onRedo}>
                    <Redo />
                </IconButton>


                <ControlButton
                    inlineToggle={inlineToggle}
                    icon={<FormatBold />}
                    controlName='bold'
                    currentStyle={currentStyle}
                />
                <ControlButton
                    inlineToggle={inlineToggle}
                    icon={<FormatItalic />}
                    controlName='italic'
                    currentStyle={currentStyle}
                />

                <ControlButton
                    inlineToggle={inlineToggle}
                    icon={<FormatUnderlined />}
                    controlName='underline'
                    currentStyle={currentStyle}
                />

                <ControlButton
                    inlineToggle={inlineToggle}
                    icon={<StrikethroughS />}
                    controlName='strikethrough'
                    currentStyle={currentStyle}
                />

                <ControlButton
                    inlineToggle={listToggle}
                    icon={<FormatListBulleted />}
                    controlName='bulletList'
                    currentStyle={currentStyle}
                    blockType={blockType}
                />

                <ControlButton
                    inlineToggle={listToggle}
                    icon={<FormatListNumbered />}
                    controlName='numberList'
                    currentStyle={currentStyle}
                    blockType={blockType}
                />

                <ControlButton
                    inlineToggle={inlineToggle}
                    icon={<Subscript />}
                    controlName='subscript'
                    currentStyle={currentStyle}
                    blockType={blockType}
                />

                <ControlButton
                    inlineToggle={inlineToggle}
                    icon={<Superscript />}
                    controlName='superscript'
                    currentStyle={currentStyle}
                    blockType={blockType}
                />
{/* 
                <IconButton onMouseDown={onSubscript}>
                    <Subscript />
                </IconButton> */}

                {/* <IconButton onMouseDown={onSuperscript}>
                    <Superscript />
                </IconButton> */}

                <IconButton onClick={addImage}>
                    <Image />
                </IconButton>

                <IconButton onClick={onFormatAlignLeft}>
                    <FormatAlignLeft />
                </IconButton>
                <IconButton onClick={onFormatCenter}>
                    <FormatAlignCenter />
                </IconButton>

                <IconButton onClick={onFormatAlignRight}>
                    <FormatAlignRight />
                </IconButton>

                <IconButton onClick={onIndentDecrease}>
                    <FormatIndentDecrease />
                </IconButton>

                <IconButton onClick={onFormatIndentIncrease}>
                    <FormatIndentIncrease />
                </IconButton>


            </div>
            <ImageForm open={openImageUploader} handleSubmit={insertImage} onClose={() => setOpenImageUploader(false)} />
        </>
    )
}

export default Toolbar