import React, { useState, useEffect, useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { getQuestions } from '../../services/subjects/questions';
import { Fab, InputAdornment, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate,useParams } from 'react-router-dom';
import QuestionViewPopup from '../questions/QuestionViewPopup';
import SubjectChangingDialog from '../questions/SubjectChangingDialog';
import { updateMcqQuestion } from '../../services/question/mcq';
import SearchIcon from '@mui/icons-material/Search';
import { getSubjectsForASyllabus } from '../../services/subjects/subjects';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert'; 
import MoveToUnlimitedQuestionModal from '../questions/MoveToUnlimitedQuestion';
import CircularProgress from '@mui/material/CircularProgress';
import QuestionViewPopupToSetAnswer from '../questions/QuestionViewPopupToSetAnswer';
import { useSelector } from 'react-redux';

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        // disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

const tableStyle = {
  justifyContent: 'center',
  margin: '40px 70px',
  width: '1400px',
};

function QuestionsBank() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [items, setItems] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [questionView, setQuestionView] = useState({
    open: false,
    question: null,
  })
  const [approvalPopup, setApprovalPopup] = useState(false);
  const { subjectId } = useParams();
  const [openSubjectChange, setOpenSubjectChange] = useState(false)
  const [subjectIdRequired, setSubjectIdRequired] = useState(true);

  const currentClickedQuestion = useRef(null);

  const navigate = useNavigate();

  const location = useLocation();
  const syllabusId = location.state?.syllabusId || null;
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - items.length) : 0;
  const [questionName, setquestionName] = useState('');
  const [questionStatus, setquestionStatus] = useState('');
  const [subjects,setSubjects] =useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openquestionMove ,setOpenquestionMove ] = useState(false);
  const [moveQuestion, setMoveQuestion] = useState({})
  const [snackbarSeverity, setSnackbarSeverity] = useState('');
  const [loading, setLoading] = useState(false); // Added loading state
  const [moveLoading, setMoveLoading] = useState(false); // Add a loading state for move operation
  const [approveLoading, setApproveLoading] = useState(false);
  const [questionViewToSetAnswer, setQuestionViewToSetAnswer] = useState({
    open: false,
    question: null,
  })
  const [viewButton,setViewButton]= useState(true)
  const user = useSelector((state) => state.userReducer.user);
  const loadSubjects = async () => {
     
    try {
      const subjects = await getSubjectsForASyllabus(syllabusId);
      console.log('subjects:', subjects.data);
      setSubjects(subjects.data || []);
    } catch (error) {
      console.log(error);
    } finally {
       
    }
  };

  useEffect(() => {
    loadSubjects();
    setquestionStatus("ALL");
  }, [syllabusId]);

  

  const handleSnackbarOpen = (message,severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // localStorage.setItem('currentPage', newPage);
  };

  useEffect(() => {// detect comming from which page
    const storedPage = localStorage.getItem('questionBank_currentPage');    
    const parsedPage = storedPage ? parseInt(storedPage, 10) : 0;
    setPage(parsedPage);

    const rowsPerPage = localStorage.getItem('questionBank_currentRowsPerPage');
    if(rowsPerPage != undefined && rowsPerPage != null){
      setRowsPerPage(parseInt(rowsPerPage, 10));
    }

    const questionName = localStorage.getItem('questionBank_currentQuestionName');
    if(questionName != undefined && questionName != null){
      setquestionName(questionName);
    }

    const questionStatus = localStorage.getItem('questionBank_currentQuestionStatus');
    if(questionStatus != undefined && questionStatus != null){
      setquestionStatus(questionStatus);
    }


  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    currentClickedQuestion.current = row;
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {

    localStorage.setItem('questionBank_currentPage', page);
    localStorage.setItem('questionBank_currentRowsPerPage', rowsPerPage);
    localStorage.setItem('questionBank_currentQuestionName', questionName);
    localStorage.setItem('questionBank_currentQuestionStatus', questionStatus);

    navigate(`questions/mcq?id=${currentClickedQuestion.current.id}`)
    handleMenuClose();
  };
  

  const handleApproveClick = async () => {
    try {
      setApproveLoading(true); // Set loading state to true when approving a question
  
      const newItems = items.map((item) => {
        if (item.id === currentClickedQuestion.current.id) {
          return {
            ...item,
            question: {
              ...item.question,
              status: 'APPROVED',
            },
          };
        }
        return item;
      });
      setItems(newItems);
  
      const updateMCQQuestion = {
        question:{
          status:'APPROVED'
        }
      };
      const response = await updateMcqQuestion(currentClickedQuestion.current.id, updateMCQQuestion);

      if (response.status === 200) {
        handleSnackbarOpen('Question Approved!', 'success');
        handleMenuClose();
      } else {
        console.error('Failed to approve question');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setApproveLoading(false); // Reset loading state regardless of the outcome
    }
  };

  const updateMcqQuestion2 = async(id, question) => {
    try{
      const response = await updateMcqQuestion(id, question);
      if (response.status === 200) {
        handleSnackbarOpen(  'Question Approved!','success' );

        const newItems = items.map((item) => {
          if (item.id === id) {
            return response.data;
          }
          return item;
        });

        setItems(newItems);

      } else {
        console.error('Failed to approve question');
      }
    }catch(error){
      console.log(error)
    }
  }

  const handleRejectClick = async()=>{
    try {
      const newItems = items.map((item) => {
        if (item.id === currentClickedQuestion.current.id) {
          return {
            ...item,
            question: {
              ...item.question,
              status: 'REJECTED',
            },
          };
        }
        return item;
      });
      setItems(newItems);
      const updateMCQQuestion = {
       question:{
        status:'REJECTED'
       }
      }
      const response = await updateMcqQuestion(currentClickedQuestion.current.id, updateMCQQuestion);
  
      if (response.status === 200) {
        handleSnackbarOpen( 'Question Rejected!','error');
        handleMenuClose();
      } else {
        console.error('Failed to approve question');
       
        setItems(items);
      }
    } catch (error) {
      console.error(error);
       
    }
  }
  
  
  
  const onViewQuestionClick = () => {
    console.log(currentClickedQuestion.current)
    setQuestionView({ open: true,question : currentClickedQuestion.current });
    handleMenuClose();
  }

  const onSetCorrectAnswer = () => {
    setQuestionViewToSetAnswer({ open: true, question: currentClickedQuestion.current })
    handleMenuClose();
  }

  const loadQuestions = async (page, rowsPerPage,subjectIdRequired, questionName ,questionStatus) => {
    try {
      console.log('subject',subjectId)
      const encodedQuestionName = encodeURIComponent(questionName);
      const questions = await getQuestions(page, rowsPerPage, subjectIdRequired, encodedQuestionName, questionStatus, subjectId);
     
      setItems(questions.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const status = questionStatus === "ALL" ? "" : questionStatus;
    loadQuestions(page, rowsPerPage,subjectIdRequired, questionName, status);
  }, [page, rowsPerPage]);

   
  const handleAddQeustion = () => {
    // Add logic for handling add question click
    navigate(`questions/mcq/${subjectId}`)
  };

  const onChangeSUbject = () => {
    // Add logic for handling add question click
    console.log(currentClickedQuestion.current)
    setOpenSubjectChange(true)
  }

 const onChangeMoveToUnlimitedQuestion = (selectedQuestion) => {
  console.log('questionnn', selectedQuestion);
  setMoveQuestion(selectedQuestion);
  setMoveLoading(true); // Set loading state to true when move button is clicked
  setOpenquestionMove(true);
};
  

  const updateSubjectIdInBackEnd = async(subjectId) => {
    if(currentClickedQuestion.current.question.subjectId === subjectId){
      return
    }

    try{
      const response = await updateMcqQuestion(currentClickedQuestion.current.id, {question: {subjectId: subjectId}})
      if(response.status === 200){
        const newItems = items.map(item => {
          if(item.id === currentClickedQuestion.current.id){
            return {
              ...item,
              question: {
                ...item.question,
                subjectId: subjectId
              }
            
            }
          }

          return item
        })
        setItems(newItems)
        const status = questionStatus === "ALL" ? "" : questionStatus;
        loadQuestions(page, rowsPerPage,  subjectIdRequired ,questionName, status);

      }

    }catch(error){
      console.log(error)
    }

  }
  const handleNameSearchChange = (event) => {
    const trimmedValue = event.target.value.trim();
    setquestionName(trimmedValue);
  };

  const handleStatusChange = (event) => {
   // const selectedValue = event.target.value === "ALL" ? "" : event.target.value;
    setquestionStatus(event.target.value);
  };
   
  const handleSearchButtonClick = () => {
    const status = questionStatus === "ALL" ? "" : questionStatus;
    setLoading(true); // Set loading to true when searching
    loadQuestions(0, rowsPerPage, subjectIdRequired, questionName, status)
      .finally(() => setLoading(false)); // Set loading to false after loading is done

      setPage(0)
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearchButtonClick();
    }
  };
  
  const rowStyle = (status) => {
    switch (status) {
      case 'APPROVED':
        return { backgroundColor: '#4b7c73', color: 'white' };
      case 'PENDING':
        return { backgroundColor: 'skyblue' };
      case 'REJECTED':
        return { backgroundColor: '#c61938', color: 'white' };
      default:
        return {};
    }
  };
  
  const userHasRole = (user, role) => {
    return user.roles && user.roles.includes(role);
  };

  const isTyper = userHasRole(user, 'TYPER');
  return (
    <>
      <div>
        <div>Questions</div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={questionName}
            onChange={handleNameSearchChange}
            onKeyPress={handleKeyPress} // Add this line
            style={{ marginBottom: '10px', marginRight: '10px' }}
          />
          {!isTyper && <Select
            label="Status"
            value={questionStatus}
            onChange={handleStatusChange}
            style={{ marginRight: '10px' }}
            //renderValue={(selected) => selected === "" ? "All" : selected}
          >
            <MenuItem value="ALL">All</MenuItem>
            <MenuItem value="PENDING">Pending</MenuItem>
            <MenuItem value="APPROVED">Approved</MenuItem>
            <MenuItem value="REJECTED">Rejected</MenuItem>
          </Select>}

          <Button onClick={handleSearchButtonClick}>
            <Typography>Search</Typography>
            <IconButton>
              <SearchIcon />
            </IconButton>
          </Button>
        </div>


      {loading ? ( // Render CircularProgress while loading
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer component={Paper} style={tableStyle}>
            {/* Rest of the table rendering code... */}
          </TableContainer>
        )}

      <TableContainer component={Paper} style={tableStyle}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Question Number</TableCell>
              <TableCell>Question Name</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell>Question</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
  {items.map((row, i) => (
    <TableRow key={row.id} style={rowStyle(row.question.status)}>
      <TableCell sx={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{row.id}</TableCell>
      <TableCell sx={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        <div dangerouslySetInnerHTML={{ __html: row.question.name }} />
      </TableCell>
      <TableCell sx={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {subjects.find((subject) => subject.id === row.question.subjectId)?.name || row.question.subjectId}
      </TableCell>
      <TableCell sx={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        <div dangerouslySetInnerHTML={{ __html: row.question.questionText }} />
      </TableCell>
      <TableCell sx={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        <IconButton aria-label="more" size="small" onClick={(e) => handleMenuOpen(e, row)}>
          <MoreHorizIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  ))}
</TableBody>

        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
          colSpan={3}
          count={-1}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </TableContainer>
      
      <QuestionViewPopup 
        questionView={questionView} 
        setQuestionView={setQuestionView}
        handleApproveClick={handleApproveClick}
        handleRejectClick={handleRejectClick}
        viewButton={viewButton}
      />

       {questionViewToSetAnswer.open &&  <QuestionViewPopupToSetAnswer
          questionView={questionViewToSetAnswer}
          setQuestionView={setQuestionViewToSetAnswer}
          handleApproveClick={handleApproveClick}
          handleRejectClick={handleRejectClick}
          updateMcqQuestion = {updateMcqQuestion2}
        />}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
      >
        <MenuItem onClick={handleEditClick}>Edit</MenuItem>

          {viewButton && currentClickedQuestion.current && (
            <>
              {currentClickedQuestion.current.question.status === 'PENDING' && (
                <>
                 {!isTyper && <MenuItem onClick={handleApproveClick}>Approve</MenuItem>}
                {!isTyper &&<MenuItem onClick={handleRejectClick}>Reject</MenuItem>}
                </>
              )}

              {currentClickedQuestion.current.question.status === 'APPROVED' && !isTyper &&(
                <MenuItem onClick={handleRejectClick}>Reject</MenuItem>
              )}

              {currentClickedQuestion.current.question.status === 'REJECTED' && !isTyper &&(
                <MenuItem onClick={handleApproveClick}>Approve {approveLoading && (
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '8px' }}>
                    <CircularProgress size={24} /> {/* Show loading indicator if approveLoading is true */}
                  </Box>
                )}
                </MenuItem>

              )}
            </>
          )}

          {currentClickedQuestion.current && !isTyper &&(
            <>
              <MenuItem onClick={onViewQuestionClick}>View Question</MenuItem>
              <MenuItem onClick={onChangeSUbject}>Change Subject</MenuItem>
              <MenuItem onClick={() => onChangeMoveToUnlimitedQuestion(currentClickedQuestion.current)}>Move TO Unlimited Questions</MenuItem>
              <MenuItem onClick={onSetCorrectAnswer}>Set Correct Answer</MenuItem>


            </>
          )}
      </Menu>


    </div>
    <Box sx={{ '& > :not(style)': { marginBottom : 1 } }}>
      <Fab color="primary" aria-label="add" onClick={handleAddQeustion}>
        <AddIcon />
      </Fab>
    </Box>

    {openSubjectChange && <SubjectChangingDialog
      handleClose={() => setOpenSubjectChange(false)}
      open={openSubjectChange}
      question={currentClickedQuestion.current}
      handleOk = {updateSubjectIdInBackEnd}
    />}

    <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000} 
      onClose={() => setSnackbarOpen(false)}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={() => setSnackbarOpen(false)}
        severity={snackbarSeverity} 
      >
        {snackbarMessage}
      </MuiAlert>
    </Snackbar>

      <MoveToUnlimitedQuestionModal
        handleClose={() => setOpenquestionMove(false)}
        open={openquestionMove}
        MovedQuestion={moveQuestion}
        moveLoading={moveLoading}
      />  
     
    </>
  );
}

export default QuestionsBank;
