import api from ".."
import Env from '../../config/environments';
import { ApiEndpoint, Method } from "../../constants"

const params = {};
const body = {};
 
export const getAllMCQPastPapers = ( subjectId=0,year="") => {
    if (subjectId) {
        params.subjectId = subjectId;
    }
        
    if (year) {
        params.year = year;
    }
    
    return api( ApiEndpoint.MCQ_PAST_PAPER  , Method.GET, params, Env.BASE_URL, body)

}

export const getMCQPastPaperById = (id) => {
    
    return api( `${ApiEndpoint.MCQ_PAST_PAPER}/${id}`  , Method.GET, params, Env.BASE_URL, body)

}

export const createMCQPastPaper = (body) =>{
    return api(ApiEndpoint.MCQ_PAST_PAPER , Method.POST, params, Env.BASE_URL, body)
}

export const updateMCQPastPaper = ( id, body ) =>{
    return api(`${ApiEndpoint.MCQ_PAST_PAPER}/${id}` , Method.PUT, params, Env.BASE_URL, body)
}

export const deleteMCQPastPaper = (  id ) =>{
    return api(`${ApiEndpoint.MCQ_PAST_PAPER}/${id}`, Method.DELETE, params, Env.BASE_URL, body)
}
