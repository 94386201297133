import { Box, Grid, IconButton, Paper, Typography } from '@mui/material'
import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';

function PaperCard({paper, onViewPdf, onEditPaper, onDeletePaper}) {
    const { name = "", description = "", sortingOrder = 0, isActive = true , file, url} = paper ?? {}

    const _onViewPdf = () =>{
        onViewPdf(url)
    }

    const _onEditPaper = () =>{
        onEditPaper(paper)
    }

    const _onDelete = () =>{
        onDeletePaper(paper)
    }

  return (
    <Grid
        xm={12}
        lg={2}
     sx={{
        backgroundColor: 'white',
    }}>
        <Paper sx={{
            padding: 2,
        }}>
          <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                // padding: 2,
                // borderBottom: 1,
                borderColor: 'grey.200'
          }}>
              <Typography>{name}</Typography>
              <Box >
              <IconButton aria-label="visibilityIcon" onClick={_onViewPdf}>
                      <VisibilityIcon />
                  </IconButton>
                  <IconButton aria-label="edit" onClick={_onEditPaper}>
                      <EditIcon />
                  </IconButton>
                  <IconButton aria-label="delete" onClick={_onDelete}>
                      <DeleteIcon />
                  </IconButton>
              </Box>
          </Box>
        
        <Typography>{description}</Typography>
        <Typography>{`order ${sortingOrder}`}</Typography>
        <Typography>{isActive ? "Active" : "Deactive"}</Typography>
        </Paper>
    </Grid>
  )
}

export default PaperCard