import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogTitle, TextField, Button } from '@mui/material'

function SubjectChangingDialog({handleClose, open,question, handleOk}) {
    const [subjectId, setSubjectId] = useState(-1)
    useEffect(() => {
      if(question.question.subjectId){
        setSubjectId(question.question.subjectId)
        // console.log("subject id " + question.question.subjectId)
      }
    }, [question])

    const _handleOk = () => {   
        handleOk(subjectId)
        handleClose()
    }
    

  return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Set backup account</DialogTitle>
        <TextField label="Subject id" variant="filled" value={subjectId} onChange={e => setSubjectId(e.target.value)}/>

        <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={_handleOk}>Submit</Button>
      </DialogActions>
      </Dialog>
  )
}

export default SubjectChangingDialog