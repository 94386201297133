import api from ".."
import Env from '../../config/environments';
import { ApiEndpoint, Method } from "../../constants"

const params = {};
const body = {};

export const getGroupsForASyllabus = (syllabusId) => {

    return api(`${ApiEndpoint.GROUPS}/syllabus/${syllabusId}`, Method.GET, params, Env.BASE_URL, body)

}

export const createGroupsForSyllabus = (syllabusId , body ) =>{
    return api(`${ApiEndpoint.GROUPS}/syllabus/${syllabusId}`, Method.POST, params, Env.BASE_URL, body)
}


export const updateGroupsOfSyllabus = (syllabusId , id ,body) =>{
    return api(`${ApiEndpoint.GROUPS}/syllabus/${syllabusId}/${id}`, Method.PUT, params, Env.BASE_URL, body)
}

export const deleteSubjectGroup = (syllabusId,id)=>{
    return api(`${ApiEndpoint.GROUPS}/${id}`, Method.DELETE, params, Env.BASE_URL, body)
}
 
export const addSubjectsToGroup = (syllabusId ,groupId, body ) =>{
    return api(`${ApiEndpoint.GROUPS}/syllabus/${syllabusId}/${groupId}`, Method.POST, params, Env.BASE_URL, body)
}

export const deleteSubjectsFromGroup = (syllabusId ,groupId, body)=>{
    return api(`${ApiEndpoint.GROUPS}/syllabus//${syllabusId}/${groupId}`, Method.DELETE, params, Env.BASE_URL, body)
}