import React,{useState} from 'react'
import ReusableTable from '../reusableComponents/ReusableTable'
import { Box, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
function QuizPaper() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const columns = [
        { id: 'id', label: 'Question Number' },
        { id: 'name', label: 'Question Name' },
        { id: 'questionText', label: 'Question' },
        
      ];

      const handleAddQeustion= ()=>{
    
      }
      
 
  const data = []
  return (
    <div>
      <ReusableTable 
        columns={columns}
        data={data}
      
      />
       <Box sx={{ '& > :not(style)': { marginBottom: 1 } }}>
        <Fab color="primary" aria-label="add" onClick={handleAddQeustion}>
          <AddIcon />
        </Fab>
      </Box>
    </div>
  )
}

export default QuizPaper
