import { Box, Button, Modal, Typography, CircularProgress } from '@mui/material';
import React,{useEffect, useState} from 'react'
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import YesNoDialog from './YesNoDialog';

const dialogStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    minWidth: '400px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '80vh',
    overflow: 'auto',
  };
 
function QuestionViewPopupToSetAnswer({questionView, setQuestionView,handleApproveClick,handleRejectClick, updateMcqQuestion}) {

  const [multiChoiceOptions, setMultiChoiceOptions] = useState([])
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const multiChoiceOptions = [...questionView?.question?.multiChoiceOptions] ?? [];
    setMultiChoiceOptions(multiChoiceOptions)
  }, [questionView])
  

  const handleClikOnCheckBox = (e, i) => {
    const checked = e.target.checked;
    // console.log({i, checked})
    const newOptions = [...multiChoiceOptions]
    newOptions[i].mark = checked ? 1 : 0;
    setMultiChoiceOptions(newOptions)
  }

  const handleUpdate = () => {
    //add all mark values
    const totalMark = multiChoiceOptions.reduce((acc, option) => acc + option.mark, 0)
    if (totalMark > 1) {
      setOpenConfirmation(true)
    } else {
      updateQuestionHere()
    }
  }

  const updateQuestionHere = async () => {
    const newQuestion = {
      ...questionView.question,
      multiChoiceOptions
    }
    // console.log(newQuestion.id)
    try {
      setLoading(true)
      await updateMcqQuestion(newQuestion.id, newQuestion)
      setLoading(false)
      setQuestionView({ open: false })
    } catch (e) {
    }

  }

  const handleYes = () => {
    updateQuestionHere()
    setOpenConfirmation(false)
  }


 

  return (
    <>
    <Modal
      open={questionView.open}
      onClose={() => setQuestionView({ open: false })}
    >
      <Box sx={dialogStyle}>
        <CircularProgress style={{ display: loading ? 'block' : 'none' }} />

        <Typography variant="h6" component="h2">
          {`${questionView?.question?.question.name ?? "Can not find name"} `}

        </Typography>
        <Typography variant="h6" component="h3"> Question </Typography>
        <div dangerouslySetInnerHTML={{ __html: questionView?.question ? questionView?.question?.question.questionText : "" }} />

        <Typography variant="h6" component="h3"> Answers </Typography>

        {multiChoiceOptions.map((option, i) => (
          <Box key={i} sx={{
            display: 'flex',
            flexDirection: 'row',
          }}>
            <Checkbox
              checked={option.mark == 1}
              onChange={(e) => handleClikOnCheckBox(e, i)}
            />
            <Box key={i} sx={[{ border: 'solid', borderWidth: 1, marginTop: 2, padding: 2 }, { backgroundColor: option.mark == 1 ? '#ADD8E6' : 'white' }]}>


              <div dangerouslySetInnerHTML={{ __html: option.answerText }} />
            </Box>
          </Box>
        ))}

        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}>
          <Button onClick={handleUpdate} variant='contained'>Update</Button>
        </Box>
      </Box>

    </Modal>
    <YesNoDialog open={openConfirmation}
      handleNo={() => setOpenConfirmation(false)}
      handleYes={handleYes}
      titile={'Confirmation'}
      message ={"Are you sure you want to approve this question with mulitple answers?"}
    />
    </>
  )
}

export default QuestionViewPopupToSetAnswer