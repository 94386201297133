import imageCompression from 'browser-image-compression';
import api from ".."
import Env from '../../config/environments';
import { ApiEndpoint, Method } from "../../constants"

const params = {};
const body = {};

const compressImage = async (file) => {
    try {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 800,
            useWebWorker: true
        }
        return await imageCompression(file, options);
    } catch (e) {
        console.log(e)
    }
}

export const uploadImage = async(file) => {
    try {
        const compressedFile = await compressImage(file);
        
        // console.log(compressedFile.type)
        // console.log(compressedFile.name)
        // console.log(compressedFile)

        // const compressedBlob = new Blob([compressedFile], { type: compressedFile.type });
        // const compressedUrl = URL.createObjectURL(compressedBlob);
        
        // // Create a downloadable link and trigger the download
        // const downloadLink = document.createElement('a');
        // downloadLink.href = compressedUrl;
        // downloadLink.download = compressedFile.name; // Optional: set a custom file name
        // document.body.appendChild(downloadLink);
        // downloadLink.click();
        // document.body.removeChild(downloadLink);


        const formData = new FormData();

        formData.append("image", compressedFile);
        const headers = {
            'Content-Type': 'multipart/form-data'
        }
        return api(ApiEndpoint.IMAGE, Method.POST, params, Env.BASE_URL, formData, headers)
    } catch (e) {
        console.log(e)
        return null;
    }
}
