import React from 'react'
import { Checkbox, Grid, Typography } from '@mui/material'

function QuestionCheckBox({title, checked, setChecked}) {
    const handleChange = (e) =>{
        setChecked(e.target.checked);
    }

  return (
    <Grid container alignItems="center" spacing={2}>
        <Grid  item xs={2} style={{ minWidth: '150px' }}>
            <Typography variant='subtitle2' >
                {title}
            </Typography>
        </Grid>
        <Grid item xs={10}>
              <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
              />
        </Grid>
    </Grid>
  )
}

export default QuestionCheckBox