import { FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import React from 'react';



function QuestionSelectInput({ title, value, setValue, items, multiSelect = false, menuItemSxProps = {} , getVale}) {

    const handleChange = e => {
        setValue(e.target.value);
    }

    return (
        <Grid container alignItems="center" spacing={2}>
        <Grid item xs={2} style={{ minWidth: '150px' }}>
            <Typography variant='subtitle2'>
                {title}
            </Typography>
        </Grid>
        <Grid item xs={10}>
            <FormControl sx={{ m: 1, width: '45%' }}>
                <InputLabel id="demo-multiple-name-label">{title}</InputLabel>
                <Select
                    sx={{ '& .Mui-Selected-selected': { backgroundColor: 'red' } }}
                    multiple={multiSelect}
                    value={getVale ? getVale(value) : value}
                    onChange={handleChange}
                    input={<OutlinedInput label={title} />}
                        //classes={{ root: styles.customSelect }}
                >
                    {items.map((item, i) => (
                        <MenuItem
                            key={i}
                            value={item}
                                sx={{...menuItemSxProps}  }
                        >
                            {item.name ?? item}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    </Grid>
    )
}

export default QuestionSelectInput;
