import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import SignUpForm from './SignUpForm';
import Login from '../login/index';  
import { setUser } from '../../store/actions/userActions'; 

const menuStyle = {
  cursor: 'pointer'
};

const buttons = {
  border: '2px solid #203C7E',
  padding: '10px',
  borderRadius: '15px',
  cursor: 'pointer'
};

function LandingPage() {
  const [showSignUpForm, setShowSignUpForm] = useState(false);
  const [showLogInForm, setShowLogInForm] = useState(false);  

  const handleSignUpClick = () => {
    setShowSignUpForm(true);
    setShowLogInForm(false);  
  };

  const closeSignUpForm = () => {
    setShowSignUpForm(false);
  };

  const handleLogInClick = () => {
    setShowLogInForm(true);
    setShowSignUpForm(false);  
  };

  const closeLogInForm = () => {
    setShowLogInForm(false);
  };

  return (
    <div>
      <AppBar position="static" sx={{ bgcolor: 'transparent', boxShadow: 'none', color: 'inherit' }}>
        <Container maxWidth="xl" sx={{ backgroundColor: 'transparent' }}>
          <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
            <Typography
              variant="h3"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '0',
                textDecoration: 'none',
                color: 'white',
                textShadow: '1px 1px 2px blue',
              }}
            >
              PAPERE
            </Typography>

            <List sx={{ display: 'flex', gap: '20px' }} style={menuStyle}>
              <ListItem>
                <Typography variant="h6" color="inherit">
                  Home
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="h6" color="inherit" style={buttons} onClick={handleLogInClick}>
                  LogIn
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="h6" color="inherit" style={buttons} onClick={handleSignUpClick}>
                  SignUp
                </Typography>
              </ListItem>
            </List>
          </Toolbar>
        </Container>
      </AppBar>

      {showSignUpForm && <SignUpForm onClose={closeSignUpForm} />}
      {showLogInForm && <Login setUser={setUser} onClose={closeLogInForm} /> }  
    </div>
  );
}

export default LandingPage;







