import api from ".."
import Env from '../../config/environments';
import { ApiEndpoint, Method } from "../../constants"

const params = {};
const body = {};
 

export const createMCQPastPaperQuestion = (paperId,body) =>{
    return api(`${ApiEndpoint.MCQ_PAST_PAPER_QUESTIONS}/${paperId}` , Method.POST, params, Env.BASE_URL, body)
}

export const deleteMCQPastPaperQuestion = (paperId,questionId) =>{
    return api(`${ApiEndpoint.MCQ_PAST_PAPER_QUESTIONS}/${paperId}/question/${questionId}` , Method.DELETE, params, Env.BASE_URL, body)
}

export const getMCQPastPaperQuestion = (paperId) =>{
    return api(`${ApiEndpoint.MCQ_PAST_PAPER_QUESTIONS}/${paperId}` , Method.GET, params, Env.BASE_URL, body)
}

export const editMCQPastPaperQuestion = (paperId,questionId,body) =>{
    return api(`${ApiEndpoint.MCQ_PAST_PAPER_QUESTIONS}/${paperId}/question/${questionId}`, Method.PUT, params, Env.BASE_URL, body)
}


 
