import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Paper,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress // Import CircularProgress for the loading indicator
} from '@mui/material';
import { getLanguages } from '../../../services/subjects/languages';
import { createGrade, deleteGrade, getALLGrades, updateGrade } from '../../../services/subjects/grades';
import { useLocation } from 'react-router-dom';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddGradeForm from './AddGradeForm';
import EditGradeForm from './EditGradeForm';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    selectedSyllabusHere: state.SyllabusReducer.syllabus,
  };
};

function GradePage({ selectedSyllabusHere, selectedLanguages }) {
  const [grades, setGrades] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [loading, setLoading] = useState(false); // State variable for loading indicator

  const loadGrades = async () => {
    if (selectedSyllabusHere.id !== -1) {
      try {
        setLoading(true); // Set loading to true when data fetching starts
        const response = await getALLGrades(selectedSyllabusHere.id);
        const fetchedGrades = response.data || [];

        console.log('API Response:', response);
        console.log('Fetched grades:', fetchedGrades);

        setGrades(fetchedGrades);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Set loading to false when data fetching completes
      }
    }
  };

  useEffect(() => {
    loadGrades();
  }, [selectedSyllabusHere]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenEditDialog = () => {
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    handleMenuClose();
  };

  const handleMenuOpen = (event, grade) => {
    setMenuAnchor(event.currentTarget);
    setSelectedGrade(grade);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const deleteSubjectGrade = async () => {
    try {
      if (selectedGrade) {
        await deleteGrade(selectedSyllabusHere.id, selectedGrade.id);

        handleMenuClose();
        loadGrades();
      }
    } catch (error) {
      console.error('Error deleting subject Grades:', error);
    }
  };

  return (
    <div>
      <div style={{ marginBottom: '8px' }}>
        <Button variant="contained" onClick={handleOpenDialog}>
          Add New Grade
        </Button>
      </div>

      {loading && <CircularProgress />} {/* Render loading indicator while loading is true */}

      <Grid container spacing={2} style={{ marginTop: '8px' }}>
        {grades.map((subjectGrade ) => (
          <Grid item key={subjectGrade.id} xs={12} sm={6} md={4} lg={3}>
            <Paper elevation={3} style={{ position: 'relative', padding: '16px' }}>
              {subjectGrade.nameTags && (
                <div>
                  {subjectGrade.nameTags.map((nameTag, index) => (
                    <Typography key={index}>{nameTag.name}</Typography>
                  ))}
                </div>
              )}

              <div style={{ position: 'absolute', top: 0, right: 0, fontSize: '20px' }}>
                <IconButton style={{ marginLeft: '8px', fontSize: '20px' }} onClick={(event) => handleMenuOpen(event, subjectGrade)}>
                  <MoreHorizIcon />
                </IconButton>

                <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={handleMenuClose}>
                  <MenuItem onClick={handleOpenEditDialog}>
                    <IconButton style={{ marginLeft: '8px', fontSize: '20px' }}>
                      <EditIcon style={{ fontSize: '16px' }} />
                    </IconButton>
                    Edit
                  </MenuItem>
                  <MenuItem onClick={deleteSubjectGrade}>
                    <IconButton style={{ marginLeft: '8px', fontSize: '20px' }}>
                      <DeleteIcon style={{ fontSize: '16px' }} />
                    </IconButton>
                    Delete
                  </MenuItem>
                </Menu>
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <AddGradeForm
        open={openDialog}
        onClose={handleCloseDialog}
        languages={selectedLanguages}
        loadGrades={loadGrades}
        syllabusId={selectedSyllabusHere.id}
      />
      {openEditDialog && (
        <EditGradeForm
          open={openEditDialog}
          onClose={handleCloseEditDialog}
          languages={selectedLanguages}
          selectedGrade={selectedGrade}
          syllabusId={selectedSyllabusHere.id}
          loadGrades={loadGrades}
        />
      )}
    </div>
  );
}

export default connect(mapStateToProps)(GradePage);
