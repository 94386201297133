import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, CircularProgress, Box, FormControlLabel, Checkbox, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { updateGrade } from '../../../services/subjects/grades';

function EditGradeForm({ open, onClose, languages, loadGrades, selectedGrade, syllabusId }) {
  const [nameTags, setNameTags] = useState({});
  const [description, setDescription] = useState('');
  const [order, setOrder] = useState('');
  const [loading, setLoading] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({});

  useEffect(() => {
    if (selectedGrade) {
      const initialCheckboxStates = {};
      const initialNameTags = selectedGrade.nameTags.reduce((acc, cur) => {
        acc[cur.language.code] = cur.name;
        initialCheckboxStates[cur.language.code] = cur.isActive || false;
        return acc;
      }, {});

      setNameTags(initialNameTags);
      setCheckboxStates(initialCheckboxStates);
      setDescription(selectedGrade.description);
      setOrder(selectedGrade.sortingOrder);
    }
  }, [selectedGrade]);

  const handleGradeNameChange = (languageCode, value) => {
    setNameTags((nameTags) => ({
      ...nameTags,
      [languageCode]: value,
    }));
  };

  const handleCheckboxChange = (languageCode, event) => {
    const isChecked = event.target.checked;
    setCheckboxStates((prevStates) => ({
      ...prevStates,
      [languageCode]: isChecked,
    }));
  };

  const handleEditGrade = async () => {
    setLoading(true);

    try {
      const editGrade = {
        description: description,
        sortingOrder: order,
        nameTags: languages.map((language) => {
          const tag = selectedGrade.nameTags.find(tag => tag.language.code === language.code);
          return {
            id: tag ? tag.id : null,
            name: nameTags[language.code] || '',
            language: { id: language.id },
            isActive: checkboxStates[language.code] || false,
          };
        }),
      };

      await updateGrade(syllabusId, selectedGrade.id, editGrade);

      loadGrades();
      onClose();
    } catch (error) {
      console.error('Error updating grade:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Grade</DialogTitle>
      <DialogContent>
        {languages.map((language) => (
          <Box key={language.code} display="flex" alignItems="center" style={{ margin: '5px' }}>
            <TextField
              style={{ flexGrow: 1, marginRight: '5px' }}
              label={`${language.name} Name`}
              value={nameTags[language.code] || ''}
              onChange={(e) => handleGradeNameChange(language.code, e.target.value)}
              fullWidth
              margin="normal"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxStates[language.code] || false}
                  onChange={(e) => handleCheckboxChange(language.code, e)}
                />
              }
              label="Active"
            />
          </Box>
        ))}

        <TextField
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          margin="normal"
        />

        <TextField
          label="Order"
          fullWidth
          value={order}
          onChange={(e) => setOrder(e.target.value)}
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleEditGrade} disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Save Changes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditGradeForm;
