import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { updateSubject } from '../../services/subjects/subjects';
import { getALLGrades } from '../../services/subjects/grades';
import { FormControl, InputLabel, MenuItem, Select, FormControlLabel, CircularProgress, Checkbox, Box } from '@mui/material';

function EditSubjectForm({ open, onClose, subjectData, syllabusId, selectedLanguages, loadSubjects }) {
  const { name, description, sortingOrder, isActiveUnlimitedQuestion, isActivePastPaper, nameTags, subjectGrade, isActiveDailyQuiz } = subjectData;

  const [subjectName, setSubjectName] = useState('');
  const [subjectNameTags, setNameTags] = useState([]);
  const [subjectDescription, setDescription] = useState('');
  const [order, setOrder] = useState('');
  const [grades, setGrades] = useState([]);
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [selectedGrade, setSelectedGrade] = useState({});
  const [activeUnlimitedQuestion, setIsActiveUnlimitedQuestion] = useState(false);
  const [activePastPaper, setIsActivePastPaper] = useState(false);
  const [activeQuiz, setIsActiveQuiz] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({});

  useEffect(() => {
    fetchGrades();
  }, [subjectData, syllabusId]);

  useEffect(() => {
    if (subjectData) {
      setSubjectName(name || '');
      setDescription(description || '');
      setOrder(sortingOrder || '');
      setIsActiveUnlimitedQuestion(isActiveUnlimitedQuestion || false);
      setIsActivePastPaper(isActivePastPaper || false);
      setIsActiveQuiz(isActiveDailyQuiz || false);
      fetchGrades();
    }
  }, [subjectData, syllabusId]);

  useEffect(() => {
    const initialNameTags = {};
    const initialCheckboxStates = {};
    nameTags.forEach((tag) => {
      initialNameTags[tag.language.code] = tag.name;
      initialCheckboxStates[tag.language.code] = tag.isActive || false;
    });
    setNameTags(initialNameTags);
    setCheckboxStates(initialCheckboxStates);
  }, [subjectData]);

  const fetchGrades = async () => {
    try {
      const gradesData = await getALLGrades(syllabusId);
      setGrades(gradesData.data);

      if (subjectData) {
        const matchingGrade = gradesData.data.find(grade => grade.id === subjectGrade.id);
        setSelectedGrade(matchingGrade || {});
      }
    } catch (error) {
      console.error('Error fetching grades:', error);
    }
  };

  const handleNameTagsChange = (languageCode, value) => {
    setNameTags(prevNames => ({
      ...prevNames,
      [languageCode]: value,
    }));
  };

  const handleLanguageCheckboxChange = (languageCode, event) => {
    const isChecked = event.target.checked;
    setCheckboxStates((prevStates) => ({
      ...prevStates,
      [languageCode]: isChecked,
    }));
  };

  const handleGradeChange = event => {
    setSelectedGrade(event.target.value);
  };

  const renderGradeName = (grade) => {
    if (grade.nameTags && grade.nameTags.length > 0) {
      const gradeNameTags = grade.nameTags.map((tag) => tag.name).join(' - ');
      return gradeNameTags || '';
    }
    return '';
  };

  const handleEditSubject = async () => {
    setLoading(true); // Set loading state to true when saving data
    try {
      const updatedSubject = {
        name: subjectName,
        description: subjectDescription,
        subjectGrade: selectedGrade,
        nameTags: selectedLanguages.map(language => {
          const tag = nameTags.find(tag => tag.language.code === language.code);
          return {
            id: tag ? tag.id : null,
            name: nameTags[language.code] || '',
            language: {
              id: language.id
            },
            isActive: checkboxStates[language.code] || false,
          };
        }),
        sortingOrder: order,
        isActiveUnlimitedQuestion: activeUnlimitedQuestion,
        isActivePastPaper: activePastPaper,
        isActiveDailyQuiz: activeQuiz,
      };

      const response = await updateSubject(subjectData.id, updatedSubject, syllabusId);
      onClose();
      loadSubjects();
    } catch (error) {
      console.error('Error updating subject:', error);
    } finally {
      setLoading(false); // Set loading state to false after saving data
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Subject</DialogTitle>
      <DialogContent>
        <TextField
          label="Subject Name"
          fullWidth
          value={subjectName}
          onChange={e => setSubjectName(e.target.value)}
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="grade-label">Grade</InputLabel>
          <Select
            labelId="grade-label"
            value={selectedGrade || ''}
            onChange={handleGradeChange}
            renderValue={() =>
              renderGradeName(selectedGrade) || 'Select Grade'
            }
          >
            {grades.map(grade => (
              <MenuItem key={grade.id} value={grade}>
                {renderGradeName(grade)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Syllabus Description"
          multiline
          rows={4}
          fullWidth
          value={subjectDescription}
          onChange={e => setDescription(e.target.value)}
        />
        {selectedLanguages.map(language => (
          <Box key={language.code} display="flex" alignItems="center" style={{ margin: '5px' }}>
            <TextField
              style={{ flexGrow: 1, marginRight: '5px' }}
              label={`${language.name} Name`}
              value={subjectNameTags[language.code] || ''}
              onChange={e => handleNameTagsChange(language.code, e.target.value)}
              fullWidth
              margin="normal"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxStates[language.code] || false}
                  onChange={e => handleLanguageCheckboxChange(language.code, e)}
                />
              }
              label="Active"
            />
          </Box>
        ))}
        <TextField
          label="Order"
          fullWidth
          value={order}
          onChange={e => setOrder(parseInt(e.target.value, 10))}
          margin="normal"
        />
        <FormControlLabel
          control={<Checkbox checked={activeUnlimitedQuestion} onChange={e => setIsActiveUnlimitedQuestion(e.target.checked)} />}
          label="Active Unlimited Questions"
        />
        <FormControlLabel
          control={<Checkbox checked={activePastPaper} onChange={e => setIsActivePastPaper(e.target.checked)} />}
          label="Active Past Papers"
        />
        <FormControlLabel
          control={<Checkbox checked={activeQuiz} onChange={e => setIsActiveQuiz(e.target.checked)} />}
          label="Active Daily Quiz"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleEditSubject} color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Save Changes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditSubjectForm;
