import React, { useState, useEffect,useRef } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Chip from '@mui/material/Chip';
import { useNavigate } from 'react-router';
import EditSyllabusForm from './EditSyllabusForm';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getSyllabusDetails, setSyllabus } from '../../store/actions/SyllabusAction';
import { readAllSyllabues, deleteSyllabus, updateSyllabus } from '../../services/subjects/syllabus';
import SyllabusPage from './SyllabusPage';
import { uploadImage } from '../../services/file/image';
import Avatar from '@mui/material/Avatar';
const buttonStyle = {
  backgroundColor: '#203C7E',
  color: '#fff',
  padding: '10px 20px',
  borderRadius: '4px',
  border: 'none',
  cursor: 'pointer',
  height: '64px',
  fontSize: '26px',
  marginRight: '10px',
};

const gridStyle = {
  padding: '10px 10px',
};

const cardStyle = {
  width: '100%',
  height: '100%',
  cursor: 'pointer',
  position: 'relative', // Added for positioning the buttons
};

const nameStyle = {
  fontSize: '18px',
};

const nameTagsStyle = {
  fontSize: '16px',
  marginLeft: '-7px', // Move the name tags a little bit to the left
};

const discStyle = {
  fontSize: '12px',
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSyllabusHere: (syllabus) => dispatch(setSyllabus(syllabus)),
  }
}
 

function SyllabusesPage({addSyllabusHere}) {
  const [items, setItems] = useState([]);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null); // New state for profile picture
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const syllabusDetails = useSelector((state) => state.syllabusReducer?.syllabusDetails);
  const [selectedNameTagsLanguages, setSelectedNameTagsLanguages] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [isAddSyllabusFormOpen, setAddSyllabusFormOpen] = useState(false);
  const [isOpenAddSyllabusForm, setIsOpenAddSyllabusForm] = useState(false);
  const [editSyllabusIndex, setEditSyllabusIndex] = useState(null);
  const [isOpenEditSyllabusForm, setIsOpenEditSyllabusForm] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [profilePictures, setProfilePictures] = useState({});
  const fileInputRef = useRef(null);
  const user = useSelector((state) => state.userReducer.user);
  const [role, setUserRole] = useState('');

  const userHasRole = (user, role) => {
    return user.roles && user.roles.includes(role);
  };

  const loadSyllabuses = async () => {
    try {
      const syllabuses = await readAllSyllabues();
      console.log('Syllabuses:', syllabuses);
      const modifiedSyllabuses = syllabuses.data.map((syllabus) => ({
        ...syllabus,
        nameTagsLanguages: syllabus.nameTags ? syllabus.nameTags.map((tag) => tag.language.code) : [],
      }));

      setItems(modifiedSyllabuses || []);

      setItems(syllabuses.data || []);
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    loadSyllabuses();
    if (userHasRole(user, 'TYPER')) {
      setUserRole('TYPER');
    }
  }, [user.role]);

 
  const handleProfilePictureChange = async (event, syllabusId,index) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const response = await uploadImage(file);  
        const imageUrl = response.data;
       
      setProfilePictures({ ...profilePictures, [syllabusId]: imageUrl });
      
 
      const updatedItems = [...items];
      updatedItems[index] = { ...updatedItems[index], profileurl: imageUrl };
      setItems(updatedItems);
      
        const updatedSubject = {
          profileurl:imageUrl
        }
        const updateProfile = await updateSyllabus(syllabusId,updatedSubject)
        
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };
  const handleOpenEditForm = (index) => {
    setIsOpenEditSyllabusForm(true);
    setEditSyllabusIndex(index);
  };

  const handleDeleteSyllabus = async (index) => {
    const selectedSyllabus = items[index];
    try {
      console.log('selectedSyllabus.objectId', selectedSyllabus.id);
      if (selectedSyllabus) {
        await deleteSyllabus(selectedSyllabus.id);
        // setItems((prevItems) => prevItems.filter((item) => item.objectId !== selectedSyllabus.objectId));
        loadSyllabuses()
      } else {
        console.error('Invalid syllabusId for deletion');
      }
    } catch (error) {
      console.error('Error deleting syllabus:', error);
    }
  };

  const handlePaperClick = async (index) => {
    setClickedIndex(index);
    const selectedSyllabus = items[index];
    console.log('selectedSyllabus:', selectedSyllabus);
    
    const selectedLanguages = selectedSyllabus.nameTagsLanguages || [];
    setSelectedNameTagsLanguages(selectedLanguages);

    try {
      addSyllabusHere(selectedSyllabus);
      navigate('selectedSyllabus', {
        state: {
          syllabusDetails: selectedSyllabus,
          syllabusObjectId: selectedSyllabus.objectId,
          selectedLanguages,
        },
         
      });
     
    } catch (error) {
      console.error('Error fetching syllabus details:', error);
    }
  };

  const handleOpenAddSyllabusForm = () => {
    setIsOpenAddSyllabusForm(true)
  };

   

 

  const profilePicStyle = {
    position: 'absolute',
    top: '50%',
    left: '10px',
    transform: 'translateY(-50%)',
    marginRight: '10px',
    borderRadius: '50%', // Make the image circular
    
  };

  const contentStyle = {
    marginLeft: '70px', // Adjust the left margin to create space between the profile picture and other content
  };
  
  const handleCloseAddSyllabusForm = (newSyllabus) => {
    console.log('newSyllabus',newSyllabus)
    if (newSyllabus) {
      setItems((prevItems) => [...prevItems, newSyllabus]);
    }
    setIsOpenAddSyllabusForm(false);
  };

  const handleMenuItemClick = (action) => {
     console.log(role)
    handleCloseMenu();
    if (action === 'More') { // Changed from 'Read More' to 'More'
        handlePaperClick(selectedIndex);
      } else if (action === 'Edit') {
      handleOpenEditForm(selectedIndex)
      } else if (action === 'Delete') {
      handleDeleteSyllabus(selectedIndex)
    }
  };

  const handleOpenMenu = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index)
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedIndex(-1)
  };



  return (
    <Grid container spacing={2} style={gridStyle}>
      <Grid item xs={12}>
        <Button style={buttonStyle} onClick={handleOpenAddSyllabusForm}>
          + Add New Syllabus
        </Button>
      </Grid>

      {items.map((syllabus, index) => (
        <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
          <Card
            style={{
              ...cardStyle,
              backgroundColor: index === hoveredIndex ? '#CADCE8' : 'white',
              transform: index === clickedIndex ? 'scale(1.05)' : 'scale(1)',
              position: 'relative', // Ensure relative positioning for absolute positioning of elements inside the card
            }}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
           
            

            <CardContent style={{ ...contentStyle, marginBottom: '20px' }} >
            <div style={{ position: 'relative' }}>
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={(event) => handleProfilePictureChange(event, syllabus.id,index)}
                    id={`upload-profile-${syllabus.id}`}
              />  
            <label htmlFor={`upload-profile-${syllabus.id}`}>
                  {syllabus.profileurl ? (
                            <Avatar
                              src={syllabus.profileurl}
                              alt={syllabus.name.charAt(0).toUpperCase()}
                              style={{ width: '50px', height: '50px', cursor: 'pointer' }}
                            />
                          ) : (
                            <Avatar
                              alt={syllabus.name.charAt(0).toUpperCase()}
                              style={{
                                width: '50px',
                                height: '50px',
                                cursor: 'pointer',
                                backgroundColor: '#ccc',
                              }}
                            >
                              {syllabus.name.charAt(0).toUpperCase()}
                            </Avatar>
                  )}           
            </label>
            </div>
              <Typography variant="subtitle1" align="left" style={nameStyle}>
                {syllabus.name}
              </Typography>
              {/* Description */}
              <Typography align="left" style={discStyle}>
                {syllabus.description}
              </Typography>
              {/* Tags */}
              <Typography variant="subtitle1" align="left" style={nameTagsStyle}>
                {syllabus.nameTags && syllabus.nameTags.map((tag, tagIndex) => (
                  <Chip key={tagIndex} label={tag.name} style={{ margin: '6px 4px', backgroundColor: '#87BCDE', color: 'black' }} />
                ))}
              </Typography>
            </CardContent>

            {/* Add More button */}
            <div style={{ position: 'absolute', top: 0, right: 0 }}>
              <IconButton onClick={(e) => handleOpenMenu(e, index)}>
                <MoreVertIcon />
              </IconButton>
              
            </div>
          </Card>
        </Grid>
      ))}
       <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => handleMenuItemClick('More')}>
          <ReadMoreIcon />
          More
        </MenuItem>
        {role !== 'TYPER' && (
          <>
            <MenuItem onClick={() => handleMenuItemClick('Edit')}>
              <EditIcon />
              Edit
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('Delete')}>
              <DeleteIcon />
              Delete
            </MenuItem>
          </>
        )}
      </Menu>
      <SyllabusPage
          open={isOpenAddSyllabusForm}
          onClose={handleCloseAddSyllabusForm}
          languages={languages}
          loadSyllabuses={loadSyllabuses} 
      />

      {isOpenEditSyllabusForm && (
        <EditSyllabusForm
          isOpen={isOpenEditSyllabusForm}
          onClose={() => setIsOpenEditSyllabusForm(false)}
          syllabusData={items[editSyllabusIndex]}
          languages={languages}
          syllabusId={items[editSyllabusIndex]?.objectId}
          setItems={setItems}
        />
      )}
    </Grid>
  );
}

export default connect(null,mapDispatchToProps) (SyllabusesPage);
