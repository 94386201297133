import { TextField } from "@mui/material";
import { useState } from "react";
const ImageUpload = () => {
  const [file, setFile] = useState(null);

  const handleChange = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <TextField
      type="file"
      onChange={handleChange}
      accept="image/*"
    />
  );
};

export default ImageUpload