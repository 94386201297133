import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { getALLGrades } from "../../services/subjects/grades";
import { useLocation } from "react-router-dom";
import { getSubjectsForASyllabus } from "../../services/subjects/subjects";
import {
  addSubjectsToGroup,
  deleteSubjectsFromGroup,
} from "../../services/subjects/subjectGroup";

function AddSubjectBox({
  Grade,
  setGrade,
  newSubject,
  setNewSubject,
  selectedSyllabus,
  subjects,
  selectedGroup,
  setSubjects,
}) {
  const [grades, setGrades] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedGradeSubjects, setSelectedGradeSubjects] = useState([]);

  console.log('selectedSyllabus', selectedSyllabus.id)
  const [subjectInGrade, setSubjectInGrade] = useState([]);

  console.log("subjects",subjects)
  const loadGrades = async () => {
    const syllabusId = selectedSyllabus.id || -1;
    if (syllabusId !== -1) {
      try {
        const response = await getALLGrades(syllabusId);
        const fetchedGrades = response.data || [];

        console.log("API Response - Grades:", response);
        console.log("Fetched grades:", fetchedGrades);

        setGrades(fetchedGrades);
      } catch (error) {
        console.error("Error fetching grades:", error);
      }
    }
  };

  useEffect(() => {
    if (selectedSyllabus !== null && selectedSyllabus.id !== undefined) {
      loadGrades();
    }
  }, [selectedSyllabus]);

  const handleGradeChange = async (e) => {
    const selectedGradeId = e.target.value;
    setGrade(selectedGradeId);
    console.log("Selected Grade ID:", selectedGradeId);

    try {
      const response = await getSubjectsForASyllabus(
        selectedSyllabus.id,
        selectedGradeId
      );
      const fetchedSubjects = response.data || [];

      console.log("API Response - Subjects:", response);
      console.log("Subjects for Grade:", fetchedSubjects);

      console.log(response.data)

      setSubjectInGrade(response.data)
    } catch (error) {
      console.error("Error fetching subjects for grade:", error);
    }
  };
 
  const handleAddSubjectClick = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSave = () => {
    // const selectedSubjects = selectedGradeSubjects.filter(
    //   (subject) => subject.selected
    // );

    // setSubjects((prevSubjects) => [...prevSubjects, ...selectedSubjects]);

    setDialogOpen(false);
  };

  const handleCheckboxToggle = async (subject) => {
    console.log('add subject', subject)
    const updatedSubject = subjects.find(
      (sub) => sub.id === subject.id
    );
    if (updatedSubject) {
      try {
        const response = await deleteSubjectsFromGroup(selectedSyllabus.id, selectedGroup.id, subject);
        console.log("API Response - Delete Subject from Group:", response);
        setSubjects((prevSubjects) =>
          prevSubjects.filter((s) => s.id !== subject.id)
        );
      } catch (error) {
        console.error("Error updating subject in group:", error);

      }
    } else {
      try {
        const response = await addSubjectsToGroup(
          selectedSyllabus.id,
          selectedGroup.id,
          subject
        );
        console.log("API Response - Add Subject to Group:", response);
        setSubjects((prevSubjects) => [...prevSubjects, subject]);
      } catch (error) {
        console.error("Error updating subject in group:", error);
      }
    }

  };

  const renderGradeName = (grade) => {
    const gradeNameTags = grade.nameTags.map((tag) => tag.name).join(" - ");
    return gradeNameTags || "";
  };

  return (
    <Box
      border="1px solid #ccc"
      padding="16px"
      borderRadius="8px"
      maxWidth="400px"
      margin="auto"
    >
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: "16px" }}
        onClick={handleAddSubjectClick}
      >
        Add Subject
      </Button>

      <Dialog
  open={dialogOpen}
  onClose={handleDialogClose}
  maxWidth="sm"
  fullWidth
  sx={{
    '& .MuiDialog-container': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .MuiPaper-root': {
      margin: 0,
    },
  }}
>
  <DialogTitle>Select Grade</DialogTitle>
  <DialogContent>
    <FormControl fullWidth margin="normal">
      <InputLabel>Select Grade</InputLabel>
      <Select
        id="grade"
        value={Grade}
        onChange={handleGradeChange}
        renderValue={(selected) =>
          renderGradeName(grades.find((grade) => grade.id === selected))
        }
      >
        {grades.map((grade) => (
          <MenuItem key={grade.id} value={grade.id}>
            {renderGradeName(grade)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>

    <List>
      {subjectInGrade.map((subject) => {
        const selected = subjects.find((s) => s.id === subject.id);
        return (
          <ListItem key={subject.id}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={!!selected}
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
                onChange={() => handleCheckboxToggle(subject)}
              />
            </ListItemIcon>
            <ListItemText primary={subject.name} />
          </ListItem>
        );
      })}
    </List>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleDialogClose} color="primary">
      Close
    </Button>
  </DialogActions>
</Dialog>


      <Box>
        <Typography variant="h6">Subjects:</Typography>
        <ul>
          {subjects.map((subject) => (
            <li key={subject.id}>
              {subject.name} - {subject.subjectGrade ? subject.subjectGrade.id : 'N/A'}
            </li>
          ))}
        </ul>
      </Box>
    </Box>
  );
}

export default AddSubjectBox;
