import React from 'react'
import { Box, Button, Modal, Typography } from '@mui/material';

function YesNoDialog({ open, handleYes, handleNo, titile, message }) {
    return (

        <Modal
            open={open}
            onClose={handleNo}
        >

            <Box sx={{
                // display: 'flex',
                // flexDirection: 'row',
                // justifyContent: 'center',
                backgroundColor: 'white',
                position: 'absolute',
                top: '50%',
                left: '50%',
                minWidth : '350px',
                minHeight : '200px',
                
            }}>
                <Box sx={{ justifyContent : 'center', alignContent : 'center', alignItems : 'center' , flexDirection : 'row'}}>
                    <Typography variant="h3" component="h2">
                        {titile}
                    </Typography>
                </Box>

                <Box sx={{ justifyContent : 'center', alignContent : 'center', alignItems : 'center' , flexDirection : 'row' , padding : 1}}>
                    <Typography variant="h6" component="h2">
                        {message}
                    </Typography>
                </Box>

                <Box sx = {{margin : 10, display : 'flex', alignItems : 'flex-end'}}>
                <Button onClick={handleYes} variant='contained'>Yes</Button>
                <Button onClick={handleNo} variant='contained'>No</Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default YesNoDialog