import api from ".."
import Env from '../../config/environments';
import { ApiEndpoint, Method } from "../../constants"

const params = {};
const body = {};

export const getSubjectsForASyllabus = (syllabusId,gradeId ) => {
    let params = {syllabusId };
    if(gradeId){
        params.gradeId = gradeId
    }

    return api(ApiEndpoint.SUBJECT, Method.GET, params, Env.BASE_URL, body)

}

export const getSubjectsForAGroup = (groupId) => {

    return api(`${ApiEndpoint.SUBJECT}/subject-groups/${groupId}`, Method.GET, params, Env.BASE_URL, body)

}

export const createSubject = (SyllabusId,body) => {

    return api(`${ApiEndpoint.SUBJECT}/syllabus/${SyllabusId}`, Method.POST, params, Env.BASE_URL, body)

}

export const updateSubject = (subjectId,body) => {

    return api(`${ApiEndpoint.SUBJECT}/${subjectId}`, Method.PUT, params, Env.BASE_URL, body)

}

export const deleteSubject = (SyllabusId,subjectId) => {

    return api(`${ApiEndpoint.SUBJECT}/${subjectId}`, Method.DELETE, params, Env.BASE_URL, body)

}
