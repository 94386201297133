import React from 'react';
import { Chip, Grid, Stack } from '@mui/material';

function SelectedSubtopicsView({ items, handleRemoveItem, allTopicesById }) {
    const handleDelete = () => {};
    // console.log(items)
    // console.log(allTopicesById)

    return (
        <Grid>
            <Stack direction="row" spacing={1}>
                {items.map((item, i) => {
                    const topic = allTopicesById[item.id]
                    if(!topic) return null
                    return (
                    <Chip
                        label={topic.name}
                        onDelete={() => handleRemoveItem(item)}
                        key={i}
                        style={{ backgroundColor: 'lightblue' , marginLeft: '125px', marginRight:'-110px'}} // Set your desired background color
                    />
                )})}
            </Stack>
        </Grid>
    );
}

export default SelectedSubtopicsView;
