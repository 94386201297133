import { DELETE_USER, SET_USER, UPDATE_USER } from "../actions/userActions";

const initialState = {
    isAuthenticated: false,
    user: {},
}

export default function userReducer(state = initialState, action) {
    switch (action.type) {
        // Add cases for different user actions here
        case SET_USER:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload
            };
        case UPDATE_USER: {
            return {
                ...state,
                user: action.payload
            };
        }
        case DELETE_USER:  {
            return {
                ...state,
                isAuthenticated: false,
                user: {}
            };
        }
        default:
            return state;
    }
}
