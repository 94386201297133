import React, { useEffect, useState } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';

// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// Create new plugin instance
// const defaultLayoutPluginInstance = defaultLayoutPlugin();

const PDFViewer = ({file, url}) => {
  const [pdfFile, setPdfFile] = useState(null);

  const handleFileChange = (file) => {
    if (file && file.type === 'application/pdf') {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPdfFile(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert('Please select a PDF file');
    }
  };

  useEffect(() => {
    if(file){
      handleFileChange(file)
    }
  }, [file])
  

  return (
    <div>
      {pdfFile && (
        <div style={{ height: '500px', backgroundColor : 'red' }}>
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js">
                      <Viewer 
                        fileUrl={pdfFile}    
                      />
                  </Worker> 
        </div>
      )}
      {url && (
        <div style={{ height: '500px', backgroundColor : 'red' }}>
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js">
                      <Viewer 
                        fileUrl={url}    
                      />
                  </Worker> 
        </div>
      )}
    </div>
  );
};

export default PDFViewer;