// import { Platform, Linking } from 'react-native';
import Moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import uniqBy from 'lodash.uniqby';
// import { PixelRatio } from 'react-native';
//import Snackbar from 'react-native-snackbar';
// import {
//     request,
//     requestMultiple,
//     PERMISSIONS,
//     RESULTS
// } from 'react-native-permissions';
import i18n from 'i18next';
// import { AppConstants } from '../constants';
// import { getApplicationName } from 'react-native-device-info';
import { isDecimal } from '../helper/validations';
// import { handleLocationPermissions } from '../components/feature/permissions/PermissionsLocation';

const DAY_FORMAT_PATTERN = 'dddd';
const DATE_FORMAT_PATTERN = 'DD MMM YYYY';
const TIME_FORMAT_PATTERN = 'h:mm a';
const DATE_TIME_FORMAT_PATTERN = 'DD-MM-YYYY h:mm:ss a';
const SEVER_DATE_PATTERN = 'YYYY-MM-DD';
const SEVER_DATE_TIME_PATTERN = 'YYYY-MM-DD h:mm:ss';
const SEVER_TIME_PATTERN = 'HH:mm';
const TIME_FORMAT_PATTERN_WITHOUT_SPACE = 'h:mma';
const DATE_NAME_PATTERN = 'ddd - MMM DD';
const DATE_FORMAT_AVAILABILITY_PATTERN = 'MMMM DD YYYY';

const APPOINTMENT_PATTERN = {
    DATE: 'dddd DD/MMM/YYYY',
    TIME: 'h:mm a'
};

export function getLKMobileNumber(value) {
    return `+${value}`;
}

export function addParamsToURL(url, params) {
    if (
        params &&
        Object.entries(params).length !== 0 &&
        params.constructor === Object
    ) {
        let temp = url;
        let count = 0;
        for (const [key, value] of Object.entries(params)) {
            temp += `${count === 0 ? '?' : '&'}${key}=${value}`;
            count++;
        }
        return temp;
    }
    return url;
}

export function isJSONString(string) {
    try {
        JSON.parse(string);
    } catch (e) {
        return false;
    }
    return true;
}

// Get First Latter capital of each word
export const getCapitalize = (word) => {
    const preSetter = word || '';
    return preSetter.toLowerCase().replace(/\b\w/g, (v) => v.toUpperCase());
};

export const getLowerCase = (word) => {
    const preSetter = word || '';
    return preSetter.toLowerCase().replace(/\b\w/g, (v) => v.toLowerCase());
};

/**
 *
 * @param {Date} value
 *
 */
export function appointmentDateToDateTimeObject(value) {
    const datetime = {
        date: '',
        time: ''
    };

    if (value) {
        datetime.date = Moment(value).format(APPOINTMENT_PATTERN.DATE);
        datetime.time = Moment(value).format(APPOINTMENT_PATTERN.TIME);
    }

    return datetime;
}

export function dayInText(value) {
    if (value) {
        return Moment(value).format(DAY_FORMAT_PATTERN).toLowerCase();
    }
    return '';
}

export function serverDateToString(value) {
    if (value) {
        return Moment(value).format(SEVER_DATE_PATTERN);
    }
    return '';
}

export function serverTimeToString(value) {
    if (value) {
        return Moment(value).format(SEVER_TIME_PATTERN);
    }
    return '';
}

export function utcDateTimeToString(value) {
    if (value) {
        return Moment.utc(Moment(value)).toISOString();
    }
}

export function serverDatetimeToString(value) {
    if (value) {
        return Moment(value).format(DATE_TIME_FORMAT_PATTERN);
    }
    return '';
}

export function dateToString(value) {
    if (value) {
        return Moment(value).format(DATE_FORMAT_PATTERN);
    }
    return '';
}

export function timeToString(value) {
    if (value) {
        return Moment(value).format(TIME_FORMAT_PATTERN);
    }
    return '';
}

export function timeToStringWithoutSpace(value) {
    if (value) {
        return Moment(value).format(TIME_FORMAT_PATTERN_WITHOUT_SPACE);
    }
    return '';
}

export function availabilityCalenderDateToString(value) {
    if (value) {
        return Moment(value)
            .startOf('week')
            .format(DATE_FORMAT_AVAILABILITY_PATTERN);
    }
    return '';
}

export function getCurrentDate() {
    return Moment().format(SEVER_DATE_PATTERN);
}

export function formatDateToDoStringWithDateText(date) {
    const dateString = Moment(date).format('dddd');
    const monthString = Moment(date).format('MMM').toUpperCase();
    const dateDoString = Moment(date).format('Do');

    return `${dateString}, ${monthString} ${dateDoString}`;
}

export function formatDateToDoString(date) {
    const monthString = Moment(date).format('MMM').toUpperCase();
    const dateDoString = Moment(date).format('Do');

    return `${monthString} ${dateDoString}`;
}

export function checkDateIsToday(date) {
    return Moment(serverDateToString(date)).isSame(
        serverDateToString(new Date())
    );
}

/**
 * Assume week start from monday according to ISO 8601, 12:00 am
 * @param {*} date
 */
export function checkDateInThisWeek(date) {
    const thisWeekStartDate = Moment(serverDateToString(new Date())).startOf(
        'isoWeek'
    );
    const checkDate = Moment(serverDateToString(date)).startOf('isoWeek');
    return checkDate.isSame(thisWeekStartDate);
}

/**
 * If oldDate < futureDate, return true
 * @param {String} oldDate
 * @param {String} futureDate
 */
export function checkDateIsPastFromDay(oldDate, futureDate = new Date()) {
    const _oldDate = Moment(oldDate);
    const _futureDate = Moment(futureDate);

    const diff = _oldDate
        .startOf('day')
        .diff(_futureDate.startOf('day'), 'days');

    return diff < 0;
}

export function getDateDiffFromDay(oldDate, futureDate = new Date()) {
    const _oldDate = Moment(oldDate);
    const _futureDate = Moment(futureDate);

    const diff = _futureDate
        .startOf('day')
        .diff(_oldDate.startOf('day'), 'days');

    return diff;
}

export function getTimeDiffValueFromHours(oldDate, futureDate = new Date()) {
    const _oldDate = Moment(oldDate);
    const _futureDate = Moment(futureDate);

    const hours = Moment.duration(_oldDate.diff(_futureDate)).asHours();

    return Math.round(hours);
}

export function getTimeDiffValueFromMinutes(oldDate, futureDate = new Date()) {
    const _oldDate = Moment(oldDate);
    const _futureDate = Moment(futureDate);

    const minutes = Moment.duration(_oldDate.diff(_futureDate)).asMinutes();

    return Math.round(minutes);
}

export function getCurrentDateTime() {
    return Moment().utc().format(SEVER_DATE_TIME_PATTERN);
}

export function getDateTime(value) {
    return Moment(value).utc().format(SEVER_DATE_TIME_PATTERN);
}

/**
 * return boolean of either true/false with diff exceed or not
 * @param {time} value
 * @param {slap value} slap
 */
export function checkTimeExceed(value, slap = 15) {
    let dateTime = Moment(value);
    let now = Moment(new Date());

    var timeDifferenceInMinutes = dateTime.diff(now, 'minutes');
    var timeDifferenceInHours = dateTime.diff(now, 'hours');
    var timeDifferenceInDay = dateTime.diff(now, 'days');

    if (timeDifferenceInDay === 0 && timeDifferenceInHours === 0) {
        if (timeDifferenceInMinutes <= slap) {
            return true;
        }
    }

    return false;
}

export function dateToNameDate(value) {
    if (value) {
        return Moment(value).format(DATE_NAME_PATTERN);
    }
    return '';
}

export function isObjectEmpty(obj) {
    if (typeof obj === 'object' && obj !== null) {
        return Object.entries(obj).length === 0 && obj.constructor === Object;
    }
    return true;
}

export function clone(obj) {
    return cloneDeep(obj);
}

export function base64ImageObject(base64) {
    return { uri: `data:image/jpg;base64,${base64}` };
}

export function removeDuplicates(array, objectName) {
    return uniqBy(array, objectName);
}

export function isDiffer(firstObject, secondObject) {
    if (firstObject && secondObject) {
        return JSON.stringify(firstObject) !== JSON.stringify(secondObject);
    }
    return false;
}

// convert hex to RGB or RGBA
export function hexToRgb(hexValue, alpha) {
    const hex = hexValue.replace('#', '');
    const r = parseInt(
        hex.length === 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2),
        16
    );
    const g = parseInt(
        hex.length === 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4),
        16
    );
    const b = parseInt(
        hex.length === 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6),
        16
    );
    if (alpha) {
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    return `rgb(${r}, ${g}, ${b})`;
}

// export function getImageResolutionOnDeviceDensity() {
//     if (PixelRatio.get() < 2) {
//         return '1x';
//     }
//     if (PixelRatio.get() < 3) {
//         return '2x';
//     }
//     return '3x';
// }

// export function showSnackBar(
//     message,
//     backgroundColor = Colors.Shades.COLOR_ERROR
// ) {
//     Snackbar.show({
//         backgroundColor,
//         text: message,
//         duration: Snackbar.LENGTH_LONG
//     });
// }

/**
 * google recommended API
 * https://cloud.google.com/blog/products/maps-platform/how-calculate-distances-map-maps-javascript-api
 * To use miles; set R = 3958.8
 * To use kilometers; set R = 6371.0710
 * */
export function haversineDistance(mk1, mk2) {
    var R = 6371.071; // Radius of the Earth in kilometers

    // Convert degrees to radians
    var rlat1 = mk1.lat * (Math.PI / 180);
    var rlat2 = mk2.lat * (Math.PI / 180);

    var difflat = rlat2 - rlat1; // Radian difference (latitudes)
    var difflon = (mk2.lon - mk1.lon) * (Math.PI / 180); // Radian difference (longitudes)

    var d =
        2 *
        R *
        Math.asin(
            Math.sqrt(
                Math.sin(difflat / 2) * Math.sin(difflat / 2) +
                Math.cos(rlat1) *
                Math.cos(rlat2) *
                Math.sin(difflon / 2) *
                Math.sin(difflon / 2)
            )
        );

    return d;
}

// Request location permissions
// export async function requestLocationPermissions() {
//     try {
//         if (Platform.OS === 'ios') {
//             const statuses = await requestMultiple([
//                 PERMISSIONS.IOS.LOCATION_ALWAYS,
//                 PERMISSIONS.IOS.LOCATION_WHEN_IN_USE
//             ]);

//             return (
//                 statuses[PERMISSIONS.IOS.LOCATION_ALWAYS] === RESULTS.GRANTED ||
//                 statuses[PERMISSIONS.IOS.LOCATION_WHEN_IN_USE] ===
//                     RESULTS.GRANTED
//             );
//         } else {
//             const statuses = await requestMultiple([
//                 PERMISSIONS.ANDROID.ACCESS_COARSE_LOCATION,
//                 PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION
//             ]);
//             return (
//                 statuses[PERMISSIONS.ANDROID.ACCESS_COARSE_LOCATION] ===
//                     RESULTS.GRANTED &&
//                 statuses[PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION] ===
//                     RESULTS.GRANTED
//             );
//         }
//     } catch (err) {
//         console.warn(err);

//         return false;
//     }
// }

// // Request calendar permissions
// export async function requestCalendarPermission() {
//     try {
//         if (Platform.OS === 'ios') {
//             const statuses = await request(PERMISSIONS.IOS.CALENDARS);
//             return statuses === RESULTS.GRANTED;
//         } else {
//             const statuses = await requestMultiple([
//                 PERMISSIONS.ANDROID.READ_CALENDAR,
//                 PERMISSIONS.ANDROID.WRITE_CALENDAR
//             ]);
//             return (
//                 statuses[PERMISSIONS.ANDROID.READ_CALENDAR] ===
//                     RESULTS.GRANTED &&
//                 statuses[PERMISSIONS.ANDROID.WRITE_CALENDAR] === RESULTS.GRANTED
//             );
//         }
//     } catch (err) {
//         console.warn(err);

//         return false;
//     }
// }

export function phoneNumberMask(number) {
    return number.replace(/^(\+?[\d]{0})\d+(\d{2})$/g, '$1*********$2');
}

export function emailMask(email) {
    const splitted = String(email).split('@');
    const firstSection = splitted[0];

    if (firstSection.length >= 3) {
        const maskedMiddle = firstSection.slice(1, -1).replace(/./g, '*');

        return (
            firstSection.charAt(0) +
            maskedMiddle +
            firstSection.charAt(firstSection.length - 1) +
            '@' +
            splitted[1]
        );
    } else if (firstSection.length === 2) {
        return (
            '*' +
            firstSection.charAt(firstSection.length - 1) +
            '@' +
            splitted[1]
        );
    } else if (firstSection.length === 1) {
        return '*@' + splitted[1];
    } else {
        return email;
    }
}

// export function showDirectionInDeviceMap(
//     location,
//     label = getApplicationName()
// ) {
//     const scheme = Platform.select({
//         ios: 'maps:0,0?q=',
//         android: 'geo:0,0?q='
//     });
//     const latLng = `${location.lat},${location.lon}`;
//     const url = Platform.select({
//         ios: `${scheme}${label}@${latLng}`,
//         android: `${scheme}${latLng}(${label})`
//     });

//     Linking.openURL(url);
// }

export function getTwoDecimal(amount) {
    let formatValue = '';
    if (!isDecimal(amount)) {
        return '';
    }

    if (amount) {
        formatValue = parseFloat(Math.round(amount * 100) / 100).toFixed(2);
    } else {
        formatValue = '0.00';
    }

    return formatValue;
}

export function getTwoDecimalPrice(amount) {
    let formatValue = '';
    if (!isDecimal(amount)) {
        return '';
    }

    if (amount) {
        formatValue = parseFloat(Math.round(amount * 100) / 100).toFixed(2);
    } else {
        formatValue = '0.00';
    }

    return i18n.t('price', {
        amount: formatValue
    });
}

export function generateNumberStringArrayFromZero(length, padMaxLength = 2) {
    let array = Array.from(Array(length).keys());
    return array.map((i) => String(i).padStart(padMaxLength, '0'));
}

export function configDataSerializer(configDataDataArray) {
    const dataArray = Array.isArray(configDataDataArray)
        ? configDataDataArray
        : [];

    let newArray = [];
    newArray = dataArray.map((item, index) => {
        let data = {
            id: index,
            label: item
        };

        return data;
    });

    return newArray;
}

// export function makePhoneCall(number) {
//     let phoneNumber = '';
//     if (Platform.OS === 'android') {
//         phoneNumber = `tel:${number}`;
//     } else {
//         phoneNumber = `telprompt:${number}`;
//     }
//     Linking.openURL(phoneNumber);
// }

// export async function setYourLocationAndHandleSettings() {
//     await handleLocationPermissions();
//     // SET LOCATION POINT
// }
