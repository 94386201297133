import { Box, Button, Modal, Typography } from '@mui/material';
import React,{useEffect, useState} from 'react'
import Chip from '@mui/material/Chip';

const dialogStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    hight :'auto', 
    minWidth: '400px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '100vh',
    overflow: 'auto',
  };
 
function QuestionViewPopup({questionView, setQuestionView,handleApproveClick,handleRejectClick,viewButton}) {
  // console.log("questionView:", questionView);
  // console.log("questionView:", questionView?.question?.question.status);
  const [status, setStatus] = useState(questionView?.question?.question.status  );
  useEffect(()=>{
    setStatus (questionView?.question?.question.status)
  },[questionView])

 
  // console.log("Initial status:", status);

  const handleApproveButtonClick =()=>{
    handleApproveClick();
    setStatus('APPROVED')
  }
  const handleRejectButtonClick =()=>{
    handleRejectClick();
    setStatus('REJECTED')
  }

  return (
    <Modal
        open={questionView.open}
        onClose={() => setQuestionView({ open: false })}
      >
        <Box sx={dialogStyle}>
            
          <Typography variant="h6" component="h2">
            {`${questionView?.question?.question.name ?? "Can not find name"} - `}
            <Chip label={status ?? "Status not available"} />
          </Typography>
            <Typography variant="h6" component="h3"> Question </Typography>
            <div dangerouslySetInnerHTML={{ __html: questionView?.question?  questionView?.question?.question.questionText : "" }} />

            <Typography variant="h6" component="h3"> Answers </Typography>

            {questionView?.question?.multiChoiceOptions ? questionView.question.multiChoiceOptions.map((option, i) => (
              <Box key={i} sx={[{border : 'solid', borderWidth : 1, marginTop : 2, padding : 2}, {backgroundColor : option.mark == 1 ? '#ADD8E6' : 'white'}]}>
              <div dangerouslySetInnerHTML={{ __html:  option.answerText}} /> 
              </Box>
            )) : ""} 
              { viewButton && status === 'PENDING' && (
                      <>
                        <Button onClick={handleApproveButtonClick} variant="contained">
                          Aprove Question
                        </Button>
                        <Button onClick={handleRejectButtonClick} variant="contained">
                          Reject Question
                        </Button>  
                      </>
              )}
               {viewButton && status === 'APPROVED' && (
                      <>
                        
                        <Button onClick={handleRejectButtonClick} variant="contained">
                          Reject Question
                        </Button>  
                      </>
              )}
              {viewButton && status === 'REJECTED' && (
                      <>
                        <Button onClick={handleApproveButtonClick} variant="contained">
                          Aprove Question
                        </Button>
                      </>
              )}
             
        </Box>
      </Modal>
  )
}

export default QuestionViewPopup