const EnvType = {
    DEV: 0,
    QA: 1,
    STG: 2,
    PROD: 3,
    DEV_LOCAL: 4,
};

/**
 * Change here only for what ever you need env
 */
const EnvConstants = {
    ENV: EnvType.DEV
};

const getEnvPrefix = (envtype) => {
    switch (envtype) {
        case EnvType.DEV:
            return ' d';
        case EnvType.QA:
            return ' q';
        case EnvType.STG:
            return ' s';
        case EnvType.PROD:
            return '';
        default:
            return ' d';
    }

};
export { EnvType, EnvConstants, getEnvPrefix };