import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Icon, IconButton, Modal, TextField } from '@mui/material'
import React from 'react'
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import PDFViewer from './PDFViewer';

function PdfQuestionPaper({ open, handleClose, paper, onChange, onSave, onClose }) {
    const { name = "", description = "", sortingOrder = 0, isActive = true , file, url} = paper ?? {}
    const {name : fileName} = file ??  {}


    const handleChangeOrder = (e) => {
        const value = e.target.value;
        // Allow only digits, no negative numbers or decimal points
        if (/^\d*$/.test(value)) {
            // console.log("gteeeeeeeeeee")
            onChange(e, 'sortingOrder');
        }else{
            // console.log(",,,,,,,,,,,,,,,,,,,,,")
            // const target = {
            //     value : order,
            // }
            // onChange({target}, 'order');
        }
    }

    return (
        <Dialog 
            open={open}
            onClose={handleClose}
        >
            <DialogTitle style={{backgroundColor : '#87BCDE', color : 'white'}}>Add Paper</DialogTitle>
            <DialogContent style={{padding : 16}}>
                <Grid container rowGap={1}>
                    <Grid item xs={12}>
                        <TextField id="outlined-basic" label="Name" variant="outlined" fullWidth size='small' value={name} onChange={e => onChange(e, 'name')}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="outlined-basic" label="Description" variant="outlined" fullWidth size='small' 
                        value={description}
                        onChange={e => onChange(e, 'description')}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="outlined-basic" label="Order" variant="outlined" fullWidth size='small'
                            value={sortingOrder}
                            onChange={handleChangeOrder}
                            // type='number' 
                            />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel control={<Checkbox defaultChecked checked = {isActive}
                            onChange={e => onChange(e, 'isActive')}
                        />} label="Active" />
                    </Grid>
                    <Grid container item xs={6} columnGap={1}>
                        <IconButton color="primary" aria-label="upload picture" component="span"><PictureAsPdf /></IconButton>
                        <FormControlLabel control={(
                            <input
                                type="file"
                                accept="pdf/*"
                                style={{ display: 'none' }}
                                onChange={e => onChange(e, 'file')}
                            // id={`upload-profile-${subject.id}`}
                            />
                        )} label={fileName ?? url ? "Change file" : "Select file"} />
                    </Grid>

                    {file && (<Grid item xs={12}> 
                            <PDFViewer file={file}/>
                        </Grid>
                        )}
                    {url && (<Grid item xs={12}> 
                            <PDFViewer file={file} url = {url}/>
                        </Grid>
                        )}
                </Grid>
                </DialogContent>
                <DialogActions>
                    <IconButton onClick={onClose} color="primary">
                        <Icon>close</Icon>
                    </IconButton>
                    <IconButton onClick={onSave} color="primary">
                        <Icon>save</Icon>
                    </IconButton>
                </DialogActions>
        </Dialog >
    )
}

export default PdfQuestionPaper