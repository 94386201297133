import { EnvConstants, EnvType } from '../../constants';

import EnvDEV from './env.dev';
import EnvDEV_LOCAL from './env.devlocal';
// import EnvQA from './env.qa';
//import EnvSTG from './env.stg';
// import EnvPROD from './env.prod';

let Env;

if (EnvConstants.ENV === EnvType.PROD) {
    // Env = EnvPROD;
} else if (EnvConstants.ENV === EnvType.STG) {
    // Env = EnvSTG;
} else if (EnvConstants.ENV === EnvType.QA) {
    // Env = EnvQA;
} else if (EnvConstants.ENV === EnvType.DEV_LOCAL) {
    Env = EnvDEV_LOCAL;
} else {
    Env = EnvDEV;
}

export default Env;