import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { createSyllabus } from '../../services/subjects/syllabus';
import { useNavigate, useLocation } from 'react-router-dom';
import { getLanguages } from '../../services/subjects/languages';
import { Dialog, DialogTitle, DialogContent,  DialogActions } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const inputContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  margin: '10px',
  padding: '5px',
};

const subButton = {
  backgroundColor: '#203C7E',
  color: '#fff',
};

const closeButtonStyle = {
  backgroundColor: '#FF0000',
  color: '#fff',
   
};

function SyllabusPage({ open, onClose,loadSyllabuses}) {
  const [syllabusName, setSyllabusName] = useState('');
  const [syllabusDescription, setSyllabusDescription] = useState('');
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [order, setOrder] = useState('');
  const [languageNames, setLanguageNames] = useState({});
  const [availableLanguages, setAvailableLanguages] = useState([]);
 
  

  const handleLanguageChange = (event) => {
    setSelectedLanguages(event.target.value);
    setLanguageNames({});
  };

  const handleLanguageNameChange = (language, event) => {
    setLanguageNames((prevNames) => ({
      ...prevNames,
      [language]: event.target.value,
    }));
  };

  const addSyllabus = async () => {
    try {
      const newSyllabus = {
        name: syllabusName,
        description: syllabusDescription,
        nameTags: selectedLanguages.map((language) => {
          const languageObject = availableLanguages.find((lang) => lang.name === language);
          return {
            name: languageNames[language] || language,
            language: { id: languageObject.id },
          };
        }),
        sortingOrder:order,
      };
      const response = await createSyllabus(newSyllabus);
      console.log(response)
       setSyllabusName('')
       setSelectedLanguages([])
       setSyllabusDescription('')
       setOrder('')
      onClose() 
      loadSyllabuses();
    } catch (error) {
      console.error('Error adding syllabus:', error);
    }
  };
   

  useEffect(() => {
    const loadLanguages = async () => {
      try {
        const response = await getLanguages();
        const { data: languages } = response;
        setAvailableLanguages(languages);
      } catch (error) {
        console.error('Error loading languages:', error);
      }
    };

    loadLanguages();
  }, []);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New Grade</DialogTitle>
      <DialogContent>
      
        <TextField
              style={{margin:'5px'}}
              label="Syllabus Name"
              fullWidth
              value={syllabusName}
              onChange={(e) => setSyllabusName(e.target.value)}
        />    
       
     
       <FormControl fullWidth style={{margin:'5px'}}>
          <InputLabel id="language-select-label">Languages</InputLabel>
            <Select
                labelId="language-select-label"
                id="language-select"
                multiple
                value={selectedLanguages}
                onChange={handleLanguageChange}
                renderValue={(selected) => selected.join(', ')}
            >
              {availableLanguages.map((language) => (
              <MenuItem key={language.id} value={language.name}>
                {language.name}
              </MenuItem>
                ))}
            </Select>
          </FormControl>
      
        {selectedLanguages.map((language) => (
         
        <TextField
          style={{margin:'5px'}} 
          label={`${language} Name`}
          fullWidth
          value={languageNames[language] || ''}
          onChange={(e) => handleLanguageNameChange(language, e)}
        />  
        
        ))}

                     <TextField
              style={{margin:'5px'}}
              label="Syllabus Description"
              multiline
              rows={4}
              fullWidth
              value={syllabusDescription}
              onChange={(e) => setSyllabusDescription(e.target.value)}
          />   
           <TextField
              style={{margin:'5px'}}
              label="Order"
              fullWidth
              value={order}
              onChange={(e) => setOrder(e.target.value)}
        />   
           
      </DialogContent>
      <DialogActions> 
          <Button onClick={()=>onClose()}  >
              Close
          </Button>  
          <Button onClick={addSyllabus}  >
              Save
          </Button>
             
      </DialogActions>     
      </Dialog>
  );
}

export default SyllabusPage;
