 
    export const SET_LANGUAGES = 'SET_LANGUAGES';
    export const UPDATE_LANGUAGES = 'UPDATE_LANGUAGES';
    export const DELETE_LANGUAGES = 'DELETE_LANGUAGES';
    export const GET_LANGUAGES_DETAILS_SUCCESS = 'GET_LANGUAGES_DETAILS_SUCCESS';

    export const setLanguages = (languages)=> {
        return {
            type: SET_LANGUAGES,
            payload: languages
        };
    }

    export const updateLanguages=(updatedLanguages)=> {
        return {
            type: UPDATE_LANGUAGES,
            payload: updatedLanguages
        };
    }

    export const deleteLanguages=() =>{
        return {
            type: DELETE_LANGUAGES
        };
    }

    export const getLanguageDetailsSuccess=(languageDetails) =>{
        return {
            type: GET_LANGUAGES_DETAILS_SUCCESS,
            payload: languageDetails
        };
    }
 
