import React, { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { useParams, useLocation } from 'react-router-dom';
import { getQuestions } from '../../services/subjects/questions';
import { getMCQPastPaperQuestion, createMCQPastPaperQuestion, deleteMCQPastPaperQuestion } from '../../services/subjects/pastPaperQuestion';
import ReusableTable from '../reusableComponents/ReusableTable';
import SearchIcon from '@mui/icons-material/Search';
import { Box, TextField } from '@mui/material';
import Button from '../../components/lexicalEditor/lexical-playground/src/ui/Button';

function AddQuestionsToPaper() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [pastPaperQuestions, setPastPaperQuestions] = useState([]);
  const [checkedRows, setCheckedRows] = useState({});
  const { paperId } = useParams();
  const location = useLocation();
  const { subjectId } = useParams();
  const syllabusId = location.state?.syllabusId || null;
  const subjectIdRequired = true;
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [questionName, setQuestionName] = useState('');
 

  const columns = [
    { id: 'id', label: 'Question Number' },
    { id: 'name', label: 'Question Name' },
    { id: 'questionText', label: 'Question' , render : (row) => <span dangerouslySetInnerHTML={{ __html: row.questionText}} />},
    { id: 'checkbox', label: 'Add to past paper', render: (row) => <Checkbox checked={checkedRows[row.id]} onChange={(event) => handleCheckboxClick(event, row)} /> }
  ];
  const loadItemsAndQuestions = async ( ) => {
    const encodedQuestionName = encodeURIComponent(questionName);
    try { 
      const questionResponse = await getQuestions(page, rowsPerPage, subjectIdRequired, encodedQuestionName, "APPROVED", subjectId);
      const pastPaperResponse = await getMCQPastPaperQuestion(paperId);
      if (questionResponse.status === 200 && pastPaperResponse.status === 200) {
        const questionTableData = questionResponse.data;
        const questionsData = pastPaperResponse.data.map(question => question.question.id);
        const checkedRowsData = {};
        
        questionTableData.forEach(item => {
          checkedRowsData[item.question.id] = questionsData.includes(item.question.id);
        });
        
        setPastPaperQuestions(pastPaperResponse.data);
        setCheckedRows(checkedRowsData);
        setData(questionTableData);
      } else {
        console.log("Failed to load items or questions. Status:", questionResponse.status, pastPaperResponse.status);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => { 
    loadItemsAndQuestions()
  }, [page, rowsPerPage, subjectIdRequired, subjectId, paperId]);
  
  

  const handleCheckboxClick = async (event, row) => {
    console.log("pastPaperQuestions",pastPaperQuestions)
    const checked = event.target.checked;
    setCheckedRows(prevState => ({
      ...prevState,
      [row.id]: checked
    }));
    try {
      if (checked) {
        const paper = {
          index: row.id,
          question: {
            id: row.id
          }
        };
        await createMCQPastPaperQuestion(paperId, paper);
      } else {
       const matchedQuestion = pastPaperQuestions.find(question => question.question.id === row.id);
        if (matchedQuestion) {
          await deleteMCQPastPaperQuestion(paperId, matchedQuestion.id);
        }

      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const totalRows = data.length;

  useEffect(() => { 
    const dataRows = data.map(question => ({
      id: question.id,
      name: question.question.name,
      questionText:  question.question.questionText
    }));
    
    setTableData(dataRows);
  }, [data]);

  const handleSearchButtonClick = () => {
    loadItemsAndQuestions();
  };
 
 const handleNameSearchChange = (event) => {
    const trimmedValue = event.target.value.trim();
    setQuestionName(trimmedValue);
    setPage(0);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearchButtonClick();
    }
  };

  return (
    <>
      <div>
        <div>Questions For Past Paper</div>
        <Box display="flex" alignItems="center" mb={2}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={questionName}
          onChange={handleNameSearchChange}
          onKeyPress={handleKeyPress}
          style={{ marginRight: '10px' }}
        />
        <Button variant="contained" color="primary" onClick={handleSearchButtonClick}>
          <SearchIcon />
        </Button>
      </Box>
        <ReusableTable
          columns={columns}
          data={tableData}
          checkedRows={checkedRows}
          handleCheckboxClick={handleCheckboxClick}
          page={page}
          rowsPerPage={rowsPerPage}
          count={totalRows}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        
      </div>
    </>
  );
}

export default AddQuestionsToPaper;
