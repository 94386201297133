import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReusableTable from '../reusableComponents/ReusableTable';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditReviewForm from './EditReviewForm';
import { deleteReview, getQuestionReviews, updateReview } from '../../services/subjects/unlimitedQuestionReview';
import { Button, FormControl, InputLabel, Select, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

function QuestionReviews() {
  const { subjectId, questionId } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [status,setStatus] = useState(false);
  const [teacherName, setTeacherName] = useState('');
  const [questionStatus, setquestionStatus] = useState('');
  const columns = [
    { id: 'index', label: 'Index' },
    { id: 'teacher', label: 'Teacher' },
    { id: 'review', label: 'Review' },
    { id: 'activeStatus', label: 'Status' },
    {
      id: 'actions',
      label: 'Actions',
      render: (row) => (
        <IconButton aria-label="more" size="small" onClick={(e) => handleMenuOpen(e, row)}>
          <MoreHorizIcon />
        </IconButton>
      ),
    },
  ];

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
   // setSelectedRow(null);
  };

  const handleDelete = async() => {
    console.log('Delete', selectedRow);
    await deleteReview(selectedRow.id)
    loadQuestionReviews()
    handleMenuClose();
  };

  const handleEdit = () => {
    console.log('editFormOpen')
    setEditDialogOpen(true);
    handleMenuClose();
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
  };

 

  const handleApproveReview = async(id) => {
    setStatus(true)
    await updateReview(id,{questionReviewStatus:'APPROVED'})
    console.log('Approve', id);
    loadQuestionReviews();
  };

  const handleRejectReview =async (id) => {
    setStatus(false)
    await updateReview(id,{questionReviewStatus:'REJECTED'})
    console.log('Reject', id);
    loadQuestionReviews();
  };

  const loadQuestionReviews = async () => {
    try {
      console.log(questionId);
      const response = await getQuestionReviews(page,rowsPerPage,questionId,status,true,);
      console.log(response); 
      if (response.status === 200) {
        const reviews = response.data;
        const formattedData = reviews.map((review, index) => {
          
          const firstName = review.appUser.firstName || '';
          const lastName = review.appUser.lastName || '';
          const fullName = `${firstName} ${lastName}`.trim();  
          const reviewText = <div dangerouslySetInnerHTML={{ __html:review.questionReview}} /> 
          return {
            index: index + 1,
            teacher: fullName,   
            review: reviewText ,
            activeStatus: review.questionReviewStatus,
            ...review
          };
        });
        console.log(formattedData); 
        setTableData(formattedData);
        setTotalRows(response.total);   
      } else {
        console.log("Failed to load reviews. Status:", response.status);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    loadQuestionReviews();
  }, [page, rowsPerPage, questionId]); 

  const handleTeacherNameSearchChange = (event) => {
    const trimmedValue = event.target.value.trim();
    setTeacherName(trimmedValue);
  };

  const handleStatusChange = (event) => {
   // const selectedValue = event.target.value === "ALL" ? "" : event.target.value;
    setquestionStatus(event.target.value);
  };
   
  const handleSearchButtonClick = () => {
    const status = questionStatus === "ALL" ? "" : questionStatus;
    loadQuestionReviews();
    setPage(0)
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearchButtonClick();
    }
  };

  return (
    <div>
      <h1>Review Table</h1>
      <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={teacherName}
            onChange={handleTeacherNameSearchChange}
            onKeyPress={handleKeyPress} 
            style={{ marginBottom: '10px', marginRight: '10px' }}
          />
          <FormControl   size="small" style={{ marginRight: '10px', minWidth: 120 }}> 
          <InputLabel id="Status">Status</InputLabel>
            <Select
              labelId="Status"
              label="Status"
              value={questionStatus}
              onChange={handleStatusChange}
              style={{ marginRight: '10px' }}
              //renderValue={(selected) => selected === "" ? "All" : selected}
            >
              <MenuItem value="ALL">All</MenuItem>
              <MenuItem value="PENDING">Pending</MenuItem>
              <MenuItem value="APPROVED">Approved</MenuItem>
              <MenuItem value="REJECTED">Rejected</MenuItem>
            </Select>
          </FormControl>
          <Button onClick={handleSearchButtonClick} variant='contained'>
            <Typography>Search</Typography>
            <IconButton>
              <SearchIcon />
            </IconButton>
          </Button>
        </div>
      <ReusableTable
        columns={columns}
        data={tableData}
        page={page}
        rowsPerPage={rowsPerPage}
        count={totalRows}
        onPageChange={(event, newPage) => setPage(newPage)}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
      {editDialogOpen && selectedRow && (
        <EditReviewForm
          open={editDialogOpen}
          onClose={handleEditDialogClose}
          review={selectedRow}
          loadQuestionReviews={loadQuestionReviews}
        />
      )}
    </div>
  );
}

export default QuestionReviews;
