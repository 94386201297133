import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const customBreakpoints = {
	values: {
		xs: 0,
		sm: 600,
		md: 1200,
		lg: 1600,
		xl: 1920,
	},
};
// Create a theme instance.
let theme = createTheme({
	palette: {
		primary: {
			main: '#87BCDE',
			contrastText: 'white',
		},
		secondary: {
			main: '#130A97',
			contrastText: '#FFD54F',
		},
		error: {
			main: '#E54343',
		},
		success: {
			main: '#19BD65',
		},
		blueRange: {
			blue1: '#121318',
			blue2: '#1B1D27',
			blue3: '#212634',
			blue4: '#38B6FF',
			blue5: '#2B3243',
			blue6: '#1A1C26',
			contrastText: '#9CA3AF',
			contrastText2: '#6B7280',
			contrastText3: '#1F2937',
		},
	},

	typography: {
		h1: {
			fontSize: 30,
			fontWeight: 600,
		},
		h2: {
			fontSize: 24,
			fontWeight: 700,
		},
		h3: {
			fontSize: 20,
			fontWeight: 700,
		},
		h4: {
			fontSize: 16,
		},
		h5: {
			fontSize: 14,
		},
		h6: {
			fontSize: 12,
		},
	},
    components: {
        MuiButton: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					...(ownerState.variant === 'contained' && {
						height: '44px',
						padding: '12px 24px',
						borderRadius: '4px',
						// border: '2px solid #EAF7FF',
						background:
							'linear-gradient(199deg, #62C5FF 13.58%, #008BDC 88.22%)',
						boxShadow: '0px 10px 25px 0px rgba(56, 182, 255, 0.15)',
					}),
				}),
			},
		},
        
    },
    breakpoints: customBreakpoints,
});

theme = responsiveFontSizes(theme);

export default theme;