import api from ".."
import Env from '../../config/environments';
import { ApiEndpoint, Method } from "../../constants"

const params = {};
const body = {};

export const getQuestions = (offset = 0, pageSize = 20, subjectId = false, questionName = "", questionStatus = "",subjectIdLong=0) => {
    
    const params = {
      offset,
      pageSize,
 
    };
  
   
  if (subjectId) {
    params.subjectId = subjectId;
  }

   
  if (questionName) {
    params.questionName = questionName;
  }

  
  if (questionStatus) {
    params.questionStatus = questionStatus;
  }

  if (subjectIdLong) {
    params.subjectIdLong = subjectIdLong;
  }

  return api(`${ApiEndpoint.QUESTIONS}`, Method.GET, params, Env.BASE_URL, body);
};


  


export const getQuestion = (id) => {

    return api(`${ApiEndpoint.QUESTIONS}/${id}`, Method.GET, params, Env.BASE_URL, body)

}

  